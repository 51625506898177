import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.riskEarlyWarning,
    component: () => import('../../pages/riskEarlyWarning/riskEarlyWarning'),
    meta: {
      title: '风险预警',
      code: [EAuthCode.RISKEARLYWARNING_LIST]
    }
  }
] as TRoutes[]
