import { IRequestConfig } from "~/modules/@wmeimob/request/src/types/fetch-type";
import {
  AccountApplyBindingDto,
  AccountApplyUploadImgDto,
  ActivityOpenDto,
  AdminAdminAftermarketFindRefundResultGetParams,
  AdminAdminAftermarketGetRefundResultGetParams,
  AdminAdminArbExportGetParams,
  AdminAdminArbInfoGetParams,
  AdminAdminArbListGetParams,
  AdminAdminGoodsInitGetParams,
  AdminAdminHelpCenterArticleDeleteDeleteParams,
  AdminAdminHelpCenterArticleListPageGetParams,
  AdminAdminHelpCenterArticleQueryAllByCategoryIdGetParams,
  AdminAdminMallBrandApplyBrandApplyDetailStoreNoGetParams,
  AdminAdminMallBrandApplyQueryGetParams,
  AdminAdminMallBrandCategoryRelGetListByBrandGetParams,
  AdminAdminMallBrandDetailGetParams,
  AdminAdminMallBrandGetOneByBrandNoGetParams,
  AdminAdminMallBrandGetOneGetParams,
  AdminAdminMallBrandListPageGetParams,
  AdminAdminMallBrandUpdateNoCheckedPutParams,
  AdminAdminMallGoodsCategoryDelDeleteParams,
  AdminAdminMallGoodsCategoryGetOneGetParams,
  AdminAdminMallGoodsCategoryGetPageGetParams,
  AdminAdminMallGoodsCategoryShowPutParams,
  AdminAdminMallGoodsCategorySortPutParams,
  AdminAdminMallGoodsCommentStoreQueryGoodsCommentExcelPostParams,
  AdminAdminMallGoodsCommentStoreQueryGoodsCommentGetParams,
  AdminAdminMallGoodsCommentStoreUpdateShowStatusPostParams,
  AdminAdminMallGoodsGetAuditedGoodsExcelPostParams,
  AdminAdminMallGoodsGetAuditedGoodsListPostParams,
  AdminAdminMallGoodsGetMallGoodsDetailGetParams,
  AdminAdminMallGoodsGetPendingReviewGoodsExcelPostParams,
  AdminAdminMallGoodsGetPendingReviewGoodsListPostParams,
  AdminAdminMallGoodsGetRejectedReviewGoodsExcelPostParams,
  AdminAdminMallGoodsGetRejectedReviewGoodsListPostParams,
  AdminAdminMallGoodsGetRemarkGetParams,
  AdminAdminMallGoodsGoodsAdminOffShelfPostParams,
  AdminAdminMallGoodsGroupGetListPostParams,
  AdminAdminMallGoodsGroupGetOneGetParams,
  AdminAdminMallGoodsGroupRemoveByIdDeleteParams,
  AdminAdminMallGoodsGroupSortChildPutParams,
  AdminAdminMallGoodsGroupSortPutParams,
  AdminAdminMallGoodsGroupUpdateStatusPostParams,
  AdminAdminMallGoodsLabelGetOneGetParams,
  AdminAdminMallGoodsLabelQueryGetParams,
  AdminAdminMallGoodsLabelRemoveByIdDeleteParams,
  AdminAdminMallGoodsLabelRemoveByIdGetParams,
  AdminAdminMallStoreBrandRelGetPageGetParams,
  AdminAdminMarketingActivityApplyDelApplyDeleteParams,
  AdminAdminMarketingActivityApplyDelByIdDeleteParams,
  AdminAdminMarketingActivityApplyDiscountSaleAuditExportGetParams,
  AdminAdminMarketingActivityApplyGetActivityGoodsByApplyNoGetParams,
  AdminAdminMarketingActivityApplyGetDetailByStoreExportGetParams,
  AdminAdminMarketingActivityApplyGetDetailByStoreGetParams,
  AdminAdminMarketingActivityApplyGetFullDiscountDetailGetParams,
  AdminAdminMarketingActivityApplyGetGoodsPageGetParams,
  AdminAdminMarketingActivityApplyGetPanicBuyDetailGetParams,
  AdminAdminMarketingActivityApplyGetSkuPageGetParams,
  AdminAdminMarketingActivityApplyGetTitleVoGetParams,
  AdminAdminMarketingActivityApplyQueryFullActivityDetailsExcelsGetParams,
  AdminAdminMarketingActivityApplyQueryFullDiscountExcelPostParams,
  AdminAdminMarketingActivityApplyQueryFullDiscountPageGetParams,
  AdminAdminMarketingActivityApplyQueryFullDiscountPendingPageGetParams,
  AdminAdminMarketingActivityApplyQueryFullDiscountStorePageGetParams,
  AdminAdminMarketingActivityApplyQueryPanicBuyingExcelPostParams,
  AdminAdminMarketingActivityApplyQueryPanicBuyingPageGetParams,
  AdminAdminMarketingActivityApplyQueryTimeBuyPageGetParams,
  AdminAdminMarketingActivityApplyQueryTimeBuyPendingDetailsExcelsGetParams,
  AdminAdminMarketingActivityApplyQueryTimeBuyPendingPageGetParams,
  AdminAdminMarketingActivityApplyTimeBuyAuditExportGetParams,
  AdminAdminOrderExportGetParams,
  AdminAdminOrderInfoGetParams,
  AdminAdminOrderListGetParams,
  AdminAdminOrderSettlementGetParams,
  AdminAdminOrderVerifyExportGetParams,
  AdminAdminOrderVerifyListGetParams,
  AdminAdminRefundExportGetParams,
  AdminAdminRefundInfoGetParams,
  AdminAdminRefundListGetParams,
  AdminAdminSettlementOrdersExportGetParams,
  AdminAdminSettlementOrdersGetParams,
  AdminAdminSettlementOrdersStatGetParams,
  AdminAdminSettlementOrderTransferPutParams,
  AdminAdminSettlementRefundExportGetParams,
  AdminAdminSettlementRefundGetParams,
  AdminAdminSettlementRefundMarginPutParams,
  AdminAdminSettlementRefundReturnPutParams,
  AdminAdminSettlementRefundStatGetParams,
  AdminAdminSettlementSubsidyAndCommissionExportGetParams,
  AdminAdminSettlementSubsidyAndCommissionGetParams,
  AdminAdminSettlementSubsidyAndCommissionStatGetParams,
  AdminAdminSettlementSubsidyExportGetParams,
  AdminAdminSettlementSubsidyFreezePutParams,
  AdminAdminSettlementSubsidyGetParams,
  AdminAdminSettlementSubsidyStatGetParams,
  AdminAdminSettlementYsordersExportGetParams,
  AdminAdminSettlementYsordersGetParams,
  AdminAdminSettlementYsordersStatGetParams,
  AdminAdminShippingListGetParams,
  AdminAdminSuggestionListPageGetParams,
  AdminAdminSuggestionQueryExportGetParams,
  AdminAdminSysdatacubeDetailGetParams,
  AdminAdminSysOauthClientAuthorizeGetParams,
  AdminAdminSysOauthClientDeleteDeleteParams,
  AdminAdminSysOauthClientQueryListGetParams,
  AdminApiAdminLogQueryGetParams,
  AdminApiCsGetSiteidByStoreGetParams,
  AdminApiCsOpenXiaoNengStoreGetParams,
  AdminApiCsOpenXiaoNengUserGetParams,
  AdminApiLogQueryGetParams,
  AdminApiMallConfPageDeleteDeleteParams,
  AdminApiMallConfPageDetailGetParams,
  AdminApiStoreLogQueryGetParams,
  AdminApiSysdeptDeleteDeleteParams,
  AdminApiSysdeptQueryGetParams,
  AdminApiSysRoleDeleteDeleteParams,
  AdminApiSysRoleQueryAllGetParams,
  AdminApiSysRoleQueryGetParams,
  AdminApiSysStoreUserGroupDeleteDeleteParams,
  AdminApiSysStoreUserGroupDetailGetParams,
  AdminApiSysStoreUserGroupQueryGetParams,
  AdminApiSysUserDeleteDeleteParams,
  AdminApiSysUserDeleteXiaonengDeleteParams,
  AdminApiSysUserQueryGetParams,
  AdminApiSysUserResetDefaultDeleteParams,
  AdminApiYihuitongOpenYihuitongStoreGetParams,
  AdminApiYihuitongOpenYihuitongUserGetParams,
  AdminCommonMallComboboxGetCategoryComboboxGetParams,
  AdminCommonMallComboboxGetGoodsCategoryComboboxGetParams,
  AdminCommonMallComboboxGetGoodsSkuComboboxGetParams,
  AdminCommonMallComboboxGetStoreCategoryComboboxByStoreGetParams,
  AdminCommonMallComboboxGetStoreComboboxGetParams,
  AdminCsAuthAddCsStoreGetParams,
  AdminCsAuthAddCsuserGetParams,
  AdminCsAuthTokenGetParams,
  AdminCsResourceArbiListGetParams,
  AdminCsResourceGetGoodsPagePostParams,
  AdminCsResourceGoodsInfoGetParams,
  AdminCsResourceMemberOrderListGetParams,
  AdminCsResourceOrderInfoGetParams,
  AdminCsResourceStoreListGetParams,
  AdminExtractedPostParams,
  AdminJobSysdatacubeDetailGetParams,
  AdminMallCompanyAuditRenewalPageGetParams,
  AdminMallConfAddressDeleteDeleteParams,
  AdminMallConfAddressDetailGetParams,
  AdminMallConfAddressQueryListAllGetParams,
  AdminMallConfAdvertisingDeleteDeleteParams,
  AdminMallConfAdvertisingDetailGetParams,
  AdminMallConfAdvertisingQueryGetParams,
  AdminMallConfBannerDeleteDeleteParams,
  AdminMallConfBannerListPageGetParams,
  AdminMallConfChannelDeleteDeleteParams,
  AdminMallConfChannelQueryGetParams,
  AdminMallConfExpressTemplateDeleteDeleteParams,
  AdminMallConfExpressTemplateDetailGetParams,
  AdminMallConfHelpCategoryDeleteDeleteParams,
  AdminMallConfHelpCategoryListPageGetParams,
  AdminMallConfHotKeyDeleteDeleteParams,
  AdminMallConfHotKeyQueryGetParams,
  AdminMallConfigControllerStoreBillGetParams,
  AdminMallConfIndexPopupDeleteByIdDeleteParams,
  AdminMallConfIndexPopupIndexpopupQueryRexportGetParams,
  AdminMallConfIndexPopupListPageGetParams,
  AdminMallConfLabelListPageGetParams,
  AdminMallConfLogisticsCompanyDeleteDeleteParams,
  AdminMallConfLogisticsCompanyDetailGetParams,
  AdminMallConfMaterialDeleteDeleteParams,
  AdminMallConfMaterialGroupDeleteDeleteParams,
  AdminMallConfMaterialGroupQueryAllGetParams,
  AdminMallConfMaterialQueryGetParams,
  AdminMallConfNavigationDeleteDeleteParams,
  AdminMallConfRefundReasonDeleteDeleteParams,
  AdminMallconfthrowlinkDeleteParams,
  AdminMallconfthrowlinkListPageGetParams,
  AdminMallCouponTemplateDeleteDeleteParams,
  AdminMallCouponTemplateDetailGetParams,
  AdminMallCouponTemplateGetBestvCardListGetParams,
  AdminMallCouponTemplateQueryListGetParams,
  AdminMallMallStoreEarlyWarningDirectExportGetParams,
  AdminMallMallStoreEarlyWarningPageGetParams,
  AdminMallRegionTreeListGetParams,
  AdminMallstoreAuditBrandApplyDetailStoreNoGetParams,
  AdminMallstoreAuditDetailStoreNoGetParams,
  AdminMallstoreAuditEnterPageGetParams,
  AdminMallstoreAuditRenewalDetailStoreNoGetParams,
  AdminMallstoreBatchGetParams,
  AdminMallstoreBindPrivilegePutParams,
  AdminMallstorecloseApplyPassBatchPostParams,
  AdminMallstorecloseApplyRefuseBatchPostParams,
  AdminMallstorecloseApplyRefuseCloseApplyIdPostParams,
  AdminMallstorecloseDetailStoreNoGetParams,
  AdminMallstoreclosePageGetParams,
  AdminMallstoreDetailStoreNoGetParams,
  AdminMallstoreExportGetParams,
  AdminMallstoreListMallStoreSpecialGetParams,
  AdminMallStoreNotifyDeleteDeleteParams,
  AdminMallStoreNotifyQueryListGetParams,
  AdminMallStoreNotifyReadGetParams,
  AdminMallstorePageGetParams,
  AdminMallstorePrivilegePageGetParams,
  AdminMallstorePrivilegeQueryAllGetParams,
  AdminMallstoreQueryAllGetParams,
  AdminMallstoretypePageGetParams,
  AdminMallstoretypeQueryAllGetParams,
  AdminMarginMerchantStoreExportGetParams,
  AdminMarginMerchantStoreGetParams,
  AdminMarketingCloseDrawActivityGetParams,
  AdminMarketingDelDrawActivityGetParams,
  AdminMarketingDrawActivityPlatformGetParams,
  AdminMarketingDrawActivityStatusGetParams,
  AdminMarketingExportDrawActivityGetParams,
  AdminMarketingQueryAllDrawActivityGetParams,
  AdminMarketingQueryJoinDrawActivityGetParams,
  AdminMarketingQueryRunningAndSelfActivityGetParams,
  AdminMarketingQueryStepFourParamGetParams,
  AdminMarketingQueryStepOneParamGetParams,
  AdminMarketingQueryStepThreeParamGetParams,
  AdminMarketingQueryStepTwoParamGetParams,
  AdminMaterialTempFormPutParams,
  AdminMaterialTempUrlPutParams,
  AdminMembaseinfoAccountPageGetParams,
  AdminMembaseinfoAccountQueryRexportGetParams,
  AdminMembaseinfoAccountStatusMemberNoPutParams,
  AdminMembaseinfoPageGetParams,
  AdminMembaseinfoQueryAllEsayExportGetParams,
  AdminMembaseinfoQueryAllExportGetParams,
  AdminMembaseinfoQueryRexportGetParams,
  AdminMemCouponPageGetParams,
  AdminMemCouponQueryRexportGetParams,
  AdminMemDragonCardExportGetParams,
  AdminMemDragonCardPageGetParams,
  AdminMemDragonCardRefundReviewDetailPostParams,
  AdminMemDragonCardRefundReviewPageGetParams,
  AdminMemlabelCancelBindDeleteParams,
  AdminMemlabelMemberBaseInfoPageGetParams,
  AdminMemlabelNotLabelIdGetParams,
  AdminMemlabelPageGetParams,
  AdminMemlabelQueryAllGetParams,
  AdminMemlevelinterestsPageGetParams,
  AdminMemlevelinterestsQueryAllGetParams,
  AdminMemLevelPageGetParams,
  AdminMemLevelQueryAllGetParams,
  AdminMemLevelStatusLevelIdPutParams,
  AdminMemsignruleUsingIdPutParams,
  AdminPayAccountInfoGetAccountCapitalGetParams,
  AdminPayAccountInfoQueryAccountPowerGetParams,
  AdminPayBalanceQueryPayAccountBalanceGetParams,
  AdminPayBalanceSyncYesterdayBalanceGetParams,
  AdminPayChannelQueryApplymentByNoGetParams,
  AdminPayChannelQueryRealnameByNoGetParams,
  AdminPayOrderGetPayOrderGetParams,
  AdminPayPlatfromLedgerExportMarginGetParams,
  AdminPayPlatfromLedgerExportMasterGetParams,
  AdminPayPlatfromLedgerExportSubsidyGetParams,
  AdminPayPlatfromLedgerQueryMarginLedgersGetParams,
  AdminPayPlatfromLedgerQueryMasterLedgersGetParams,
  AdminPayPlatfromLedgerQuerySubsidyLedgersGetParams,
  AdminPayStoreLedgerExportMarginPlatformGetParams,
  AdminPayStoreLedgerExportMarginStoreGetParams,
  AdminPayStoreLedgerExportMasterPlatformGetParams,
  AdminPayStoreLedgerExportMasterStoreGetParams,
  AdminPayStoreLedgerExportSubsidyPlatformGetParams,
  AdminPayStoreLedgerExportSubsidyStoreGetParams,
  AdminPayStoreLedgerQueryMarginLedgersGetParams,
  AdminPayStoreLedgerQueryMasterLedgersGetParams,
  AdminPayStoreLedgerQuerySubsidyLedgersGetParams,
  AdminSmsVerifyCodeGetParams,
  AdminStoreAdvanceInfoGetParams,
  AdminStoreAdvanceListExcelPostParams,
  AdminStoreAdvanceListGetParams,
  AdminStoreAdvanceOrderExportGetParams,
  AdminStoreAdvanceOrderListGetParams,
  AdminStoreArbExportGetParams,
  AdminStoreArbInfoGetParams,
  AdminStoreArbListGetParams,
  AdminStoreFlashOrderExportGetParams,
  AdminStoreFlashOrderListGetParams,
  AdminStoreInvoiceExcelGetParams,
  AdminStoreInvoiceInfoGetParams,
  AdminStoreInvoiceListGetParams,
  AdminStoreMallBrandApplyGetOneByBrandNoGetParams,
  AdminStoreMallBrandApplyGetOneGetParams,
  AdminStoreMallBrandApplyQueryGetParams,
  AdminStoreMallBrandApplyRemoveByIdDeleteParams,
  AdminStoreMallGoodsCategoryGetOneGetParams,
  AdminStoreMallGoodsCommentStoreListGetParams,
  AdminStoreMallGoodsCommentStoreQueryGoodsCommentExcelPostParams,
  AdminStoreMallGoodsCommentStoreSaveStoreAppendCommentPutParams,
  AdminStoreMallGoodsCommentStoreSaveStoreCommentPutParams,
  AdminStoreMallGoodsDelGoodsDeleteParams,
  AdminStoreMallGoodsGetGoodsNameImgGetParams,
  AdminStoreMallGoodsGetGoodsPageExcelPostParams,
  AdminStoreMallGoodsGetGoodsPagePostParams,
  AdminStoreMallGoodsGetMallGoodsDetailGetParams,
  AdminStoreMallGoodsGetRemarkGetParams,
  AdminStoreMallGoodsGoodsOffShelfPutParams,
  AdminStoreMallGoodsGoodsPutShelfPutParams,
  AdminStoreMallGoodsSKuGetMallGoodsVoGetParams,
  AdminStoreMallGoodsSKuGetSkuInventoryExcelGetParams,
  AdminStoreMallGoodsSKuGetSkuInventoryPageGetParams,
  AdminStoreMallGoodsSKuUpdateSkuInventoryPostParams,
  AdminStoreMallGoodsUpdateGoodsStatusPutParams,
  AdminStoreMallStoreCategoryGetMallStoreCategoryListVosGetParams,
  AdminStoreMallVerifyStoreCancelPutParams,
  AdminStoreMallVerifyStoreDelPutParams,
  AdminStoreMallVerifyStoreImportGenerateWriteCodePostParams,
  AdminStoreMallVerifyStoreQueryPostParams,
  AdminStoreMallVerifyStoreSaveCarMyPostParams,
  AdminStoreMarketingActivityApplyActivityNoGetParams,
  AdminStoreMarketingActivityApplyGetDetailByStoreGetParams,
  AdminStoreMarketingActivityApplyGetFullDiscountDetailGetParams,
  AdminStoreMarketingActivityApplyGetPanicBuyDetailGetParams,
  AdminStoreMarketingActivityApplyQueryFullDiscountExcelPostParams,
  AdminStoreMarketingActivityApplyQueryFullDiscountPageGetParams,
  AdminStoreMarketingActivityDisCountListGetParams,
  AdminStoreMarketingActivityGroupActivityGroupExportGetParams,
  AdminStoreMarketingActivityGroupDetailGetParams,
  AdminStoreMarketingActivityGroupPageGetParams,
  AdminStoreMarketingActivityInfoGetParams,
  AdminStoreMarketingActivityListGetParams,
  AdminStoreMarketingActivityTimeBuyQueryListGetParams,
  AdminStoreMarketingActivityTimeBuyQueryPanicBuyingExcelPostParams,
  AdminStoreMarketingWatermarkDelDeleteParams,
  AdminStoreMarketingWatermarkEndMarketPostParams,
  AdminStoreMarketingWatermarkGetOneGetParams,
  AdminStoreMarketingWatermarkQueryListGetParams,
  AdminStoreMarketingWatermarkStartMarketPostParams,
  AdminStoreOrderExportGetParams,
  AdminStoreOrderInfoGetParams,
  AdminStoreOrderListGetParams,
  AdminStoreOrderVerifyExportGetParams,
  AdminStoreOrderVerifyListGetParams,
  AdminStoreRefundExportGetParams,
  AdminStoreRefundInfoGetParams,
  AdminStoreRefundListGetParams,
  AdminStoreRefundOfflinePutParams,
  AdminStoreRefundReRefundPutParams,
  AdminStoreSettlementOrdersExportGetParams,
  AdminStoreSettlementOrdersGetParams,
  AdminStoreSettlementOrdersStatGetParams,
  AdminStoreSettlementRefundExportGetParams,
  AdminStoreSettlementRefundGetParams,
  AdminStoreSettlementRefundMarginPutParams,
  AdminStoreSettlementRefundReturnPutParams,
  AdminStoreSettlementRefundStatGetParams,
  AdminStoreSettlementSubsidyExportGetParams,
  AdminStoreSettlementSubsidyGetParams,
  AdminStoreSettlementSubsidyStatGetParams,
  AdminStoreSettlementYsordersExportGetParams,
  AdminStoreSettlementYsordersGetParams,
  AdminStoreSettlementYsordersStatGetParams,
  AdminStoreShippingExpressInfoGetParams,
  AdminStoreShippingListGetParams,
  AdminStoreShippingShippingInfoGetParams,
  AdminTransactionAuditResultPostParams,
  AdminTransactionBrandAuditApplyPostParams,
  AdminTransactionBrandAuditAuditDetailPostParams,
  AdminTransactionCategoryAuditQueryByAuditIdPostParams,
  AdminTransactionCouponGetGetParams,
  AdminTransactionCouponGetListGetParams,
  AdminTransactionCouponGetPromotersListGetParams,
  AdminTransactionShopSpuGetSpuGetParams,
  AdminTransactionShopSpuQuerySpuListPostParams,
  AdminTransactionShopSpuUpdateWithoutAuditPutParams,
  AdminTransactionUploadImgPostParams,
  AdminVerifyEmployeeDelDeleteParams,
  AdminVerifyEmployeeListGetParams,
  AdminVerifyEmployeeSavePostParams,
  AdminVerifyEmployeeSendCodePostParams,
  AdminVerifyGroupGoodsGetParams,
  AdminVerifyGroupListGetParams,
  AdvanceActivityDto,
  ApplyOpenDto,
  ArbitrationOrderParam,
  BatchGoodsStatusUpdateDto,
  BatchShipInfoParam,
  BindingCardAndSignDto,
  BindMemberUsingPOSTMembernos,
  ChannelApplymentDto,
  CheckAccountPlatformDto,
  CheckAccountStoreDto,
  ComplementCertificateDto,
  CouponAddOrUpdateReq,
  CouponAddOrUpdateUserCouponReq,
  CouponGetUserCouponListReq,
  CouponUpdateStatusReq,
  CouponUpdateStockReq,
  CouponUpdateUserCouponStatusReq,
  DeleteBatchUsingPOSTIds,
  EnterAuditPassAddParam,
  GoodsAdminOffShelfListUsingPOSTGoodsnolist,
  GoodsReviewParam,
  GoodsStatusUpdateDto,
  JsonResult,
  JsonResultAccountAmountVo,
  JsonResultApplyAuditCategoryDetailVo,
  JsonResultArbitrationOrderDto,
  JsonResultAuditResultVo,
  JsonResultBaseVo,
  JsonResultBindCardVo,
  JsonResultBoolean,
  JsonResultBrandApplyAuditDetailVo,
  JsonResultCouponGetListRes,
  JsonResultCouponGetRes,
  JsonResultCouponGetUserCouponListRes,
  JsonResultDistributeCouponVo,
  JsonResultGetSpuRes,
  JsonResultInsertTenantAndWaiterRes,
  JsonResultInt,
  JsonResultLedgerResultVo,
  JsonResultListHashMapStringString,
  JsonResultListMallBrandCategoryRelVo,
  JsonResultListMallCategoryTree,
  JsonResultListMallComboboxData,
  JsonResultListMallConfAddressVo,
  JsonResultListMallConfBannerVo,
  JsonResultListMallConfChannelDto,
  JsonResultListMallConfConfigDto,
  JsonResultListMallConfExpressTemplateVo,
  JsonResultListMallConfHelpCategoryVo,
  JsonResultListMallConfHelpCenterArticleVo,
  JsonResultListMallConfLogisticsCompanyVo,
  JsonResultListMallConfMaterialGroupVo,
  JsonResultListMallConfNavigationVo,
  JsonResultListMallConfPageVo,
  JsonResultListMallConfRefundReasonDto,
  JsonResultListMallConfThrowLinkVo,
  JsonResultListMallExpressCompanyVo,
  JsonResultListMallGoodsCategoryPropVo,
  JsonResultListMallGoodsCategoryVo,
  JsonResultListMallGoodsGroupVo,
  JsonResultListMallGoodsSkuVo,
  JsonResultListMallNotifyVo,
  JsonResultListMallRegionVo,
  JsonResultListMallStoreCategoryCombobox,
  JsonResultListMallStoreCategoryListVo,
  JsonResultListMallStoreDto,
  JsonResultListMallStorePageVo,
  JsonResultListMallStorePrivilegeDto,
  JsonResultListMallStoreSpecialVo,
  JsonResultListMallStoreTypeDto,
  JsonResultListMallStoreUploadPoiDto,
  JsonResultListMallThrowLabelVo,
  JsonResultListMap,
  JsonResultListMarketingActivitySkuParam,
  JsonResultListMarketingLiveStreamRoomDto,
  JsonResultListMarketingLiveStreamUserDto,
  JsonResultListMarketingPrizePoolVo,
  JsonResultListMarketingTurnTableVo,
  JsonResultListMarketingWatermarkDto,
  JsonResultListMemAccountPageVo,
  JsonResultListMemberInfoPageVo,
  JsonResultListMemGrowthRuleDto,
  JsonResultListMemLabelDto,
  JsonResultListMemLevelInterestsDto,
  JsonResultListMemLevelPageDto,
  JsonResultListOrderDto,
  JsonResultListOrderShippingUploadPoiDto,
  JsonResultListPayAccountBalanceVo,
  JsonResultListPayAccountVo,
  JsonResultListPayBanksVo,
  JsonResultListPayDictionaryVo,
  JsonResultListString,
  JsonResultListSysDataCubeDto,
  JsonResultListSysDeptHaveUserVo,
  JsonResultListSysDeptVo,
  JsonResultListSysMenuVo,
  JsonResultListSysRoleDto,
  JsonResultLong,
  JsonResultMallBrandApplyDetailVo,
  JsonResultMallBrandDetailVo,
  JsonResultMallBrandVo,
  JsonResultMallCategoryPropVo,
  JsonResultMallCategoryVo,
  JsonResultMallConfAddressVo,
  JsonResultMallConfAdvertisingVo,
  JsonResultMallConfExpressTemplateVo,
  JsonResultMallConfLogisticsCompanyVo,
  JsonResultMallConfPageVo,
  JsonResultMallCouponTemplateVo,
  JsonResultMallGoodsCategoryVo,
  JsonResultMallGoodsDetailVo,
  JsonResultMallGoodsGroupDetailVo,
  JsonResultMallGoodsLabelDetailVo,
  JsonResultMallStoreApplyNotPassVo,
  JsonResultMallStoreCategoryVo,
  JsonResultMallStoreCloseAuditDto,
  JsonResultMallStoreCompanyInfoDto,
  JsonResultMallStoreCompanyRenewalApplyDto,
  JsonResultMallStoreDto,
  JsonResultMallStoreEnterAuditScheduleVo,
  JsonResultMallStorePayVo,
  JsonResultMallVerifyGroupVo,
  JsonResultMallXiaoNengStoreVo,
  JsonResultMapStringObject,
  JsonResultMarginAccountVo,
  JsonResultMarketingActivityApplyDto,
  JsonResultMarketingActivityApplyTitleVo,
  JsonResultMarketingActivityDto,
  JsonResultMarketingActivityFullDiscountApplyDetail,
  JsonResultMarketingActivityGoodsPageVo,
  JsonResultMarketingActivityPanicBuyApplyDetail,
  JsonResultMarketingActivityVo,
  JsonResultMarketingLiveStreamingScheduleDetailVo,
  JsonResultMarketingWatermarkPram,
  JsonResultMemAccountDetailVo,
  JsonResultMemBaseInfoDto,
  JsonResultMemberDetailAdminVo,
  JsonResultMemSignRuleDto,
  JsonResultObject,
  JsonResultOrderDto,
  JsonResultOrderInvoiceDto,
  JsonResultOrderLedgerResultDto,
  JsonResultOrderSettlementDto,
  JsonResultPageInfoAdvanceOrderPoiDto,
  JsonResultPageInfoArbitrationOrderDto,
  JsonResultPageInfoCloseAuditPageVo,
  JsonResultPageInfoDrawActivityResultVo,
  JsonResultPageInfoEnterAuditPageVo,
  JsonResultPageInfoFlashOrderPoiDto,
  JsonResultPageInfoGroupItemsVo,
  JsonResultPageInfoMallBrandApplyVo,
  JsonResultPageInfoMallBrandVo,
  JsonResultPageInfoMallConfAdvertisingVo,
  JsonResultPageInfoMallConfBannerVo,
  JsonResultPageInfoMallConfChannelDto,
  JsonResultPageInfoMallConfHelpCategoryVo,
  JsonResultPageInfoMallConfHelpCenterArticleVo,
  JsonResultPageInfoMallConfHotKeyDto,
  JsonResultPageInfoMallConfMaterialVo,
  JsonResultPageInfoMallConfPageVo,
  JsonResultPageInfoMallConfSuggestionVo,
  JsonResultPageInfoMallConfThrowLinkVo,
  JsonResultPageInfoMallCouponTemplateVo,
  JsonResultPageInfoMallGoodsCommentVo,
  JsonResultPageInfoMallGoodsDto,
  JsonResultPageInfoMallGoodsLabelVo,
  JsonResultPageInfoMallGoodsPageVo,
  JsonResultPageInfoMallGoodsSkuInventoryVo,
  JsonResultPageInfoMallIndexPopupVo,
  JsonResultPageInfoMallNotifyMsgVo,
  JsonResultPageInfoMallNotifyVo,
  JsonResultPageInfoMallStoreBrandRelVo,
  JsonResultPageInfoMallStoreEarlyWarningDto,
  JsonResultPageInfoMallStorePageVo,
  JsonResultPageInfoMallStoreTypeDto,
  JsonResultPageInfoMallThrowLabelVo,
  JsonResultPageInfoMallVerifyEmployeeVo,
  JsonResultPageInfoMallVerifyGroupVo,
  JsonResultPageInfoMallVerifyVo,
  JsonResultPageInfoMarketingActivityApplyStoreVo,
  JsonResultPageInfoMarketingActivityApplyVo,
  JsonResultPageInfoMarketingActivityGoodsAdminVo,
  JsonResultPageInfoMarketingActivityGoodsParam,
  JsonResultPageInfoMarketingActivityGroupPageVo,
  JsonResultPageInfoMarketingActivityGroupRecordsVo,
  JsonResultPageInfoMarketingActivityPageVo,
  JsonResultPageInfoMarketingActivityVo,
  JsonResultPageInfoMarketingLiveStreamRoomDto,
  JsonResultPageInfoMarketingLiveStreamScheduleGroupDto,
  JsonResultPageInfoMarketingLiveStreamUserDto,
  JsonResultPageInfoMarketingStoreActivityVo,
  JsonResultPageInfoMemAccountPageVo,
  JsonResultPageInfoMemberInfoPageVo,
  JsonResultPageInfoMemCouponVo,
  JsonResultPageInfoMemDragonCardRecordVo,
  JsonResultPageInfoMemLabelDto,
  JsonResultPageInfoMemLevelInterestsDto,
  JsonResultPageInfoMemLevelPageDto,
  JsonResultPageInfoOperationLog,
  JsonResultPageInfoOrderDto,
  JsonResultPageInfoOrderInvoiceDto,
  JsonResultPageInfoRefundMasterDto,
  JsonResultPageInfoRefundReviewVo,
  JsonResultPageInfoRenewalAuditPageVo,
  JsonResultPageInfoSysDataCubeDto,
  JsonResultPageInfoSysDeptVo,
  JsonResultPageInfoSysOauthClientVo,
  JsonResultPageInfoSysRoleDto,
  JsonResultPageInfoSysStoreUserGroupVo,
  JsonResultPageInfoSysUserVo,
  JsonResultPageInfoTransactionComponenskuWechatRelDto,
  JsonResultPageInfoTransactionComponentBrandAuditDto,
  JsonResultPageInfoTransactionComponentCategoryDto,
  JsonResultPageInfoTransactionComponentCategoryVo,
  JsonResultPayAccountApplyVo,
  JsonResultPayAccountVo,
  JsonResultPayChannelApplymentVo,
  JsonResultPayOrderVo,
  JsonResultPlatformBondAdminVo,
  JsonResultPromotersGetListRes,
  JsonResultRefundLedgerResultDto,
  JsonResultRefundMasterDto,
  JsonResultRefundReviewVo,
  JsonResultRefundStatisticsDto,
  JsonResultResultDTO,
  JsonResultShopSpuRes,
  JsonResultShopSpuUpdateRes,
  JsonResultSignInNetVo,
  JsonResultStatisticsDto,
  JsonResultStorePayStatusDetailVo,
  JsonResultString,
  JsonResultSubsidyAndCommissionLedgerResultDto,
  JsonResultSubsidyAndCommissionStatisticsDto,
  JsonResultSubsidyItemLedgerResultDto,
  JsonResultSysAuthVo,
  JsonResultSysDataCubeDto,
  JsonResultSysStoreUserGroupVo,
  JsonResultTransactionComponentBrandAuditDto,
  JsonResultTransactionComponentCategoryVo,
  JsonResultVoid,
  JsonResultWxMediaUploadResult,
  LoginDto,
  MallBrandApplyAddParam,
  MallBrandApplyAuditParam,
  MallBrandCategoryParam,
  MallBrandParam,
  MallCategoryParam,
  MallConfAddressAddParam,
  MallConfAdvertisingAddParam,
  MallConfBannerParam,
  MallConfChannelAddParam,
  MallConfExpressTemplateAddParam,
  MallConfHelpCategoryParam,
  MallConfHelpCenterArticleParam,
  MallConfHotKeyAddParam,
  MallConfLogisticsCompanyAddParam,
  MallConfMaterialAddParam,
  MallConfMaterialGroupAddParam,
  MallConfMaterialMoveParam,
  MallConfNavigationAddParam,
  MallConfPageAddParam,
  MallConfPageQuery,
  MallConfRefundReasonAddParam,
  MallConfSuggestionParam,
  MallConfThrowLinkParam,
  MallCouponTemplateAddParam,
  MallCouponTemplateSynVideoNum,
  MallGoodsCategoryParam,
  MallGoodsGroupChildParam,
  MallGoodsGroupParentParam,
  MallGoodsLabelParam,
  MallGoodsLabelRelParam,
  MallGoodsParam,
  MallGoodsSalesAttributeParam,
  MallIndexPopupParam,
  MallNotifyMsgAddParam,
  MallNotifyMsgQueryParam,
  MallNotifyVo,
  MallStoreApplyParam,
  MallStoreBusinessCutParam,
  MallStoreCompanyInfoAddParam,
  MallStoreDto,
  MallStoreForbidParam,
  MallStoreFreeAuditParam,
  MallStorePrivilegeDto,
  MallStoreTypeDto,
  MallThrowLabelSaveParam,
  MallVerifyGroupVoReq,
  MallVerifyWriteCodeParam,
  MallVirtualGoodsParam,
  MarketingActivityFullDiscountApplyParam,
  MarketingActivityGoodsInfoQuery,
  MarketingActivityGroupRecordsQuery,
  MarketingActivityGroupSaveParam,
  MarketingActivityPanicBuyApplyParam,
  MarketingActivityReviewDto,
  MarketingActivityStoreApplyParam,
  MarketingActivityTimeBuyParam,
  MarketingDrawStep1Param,
  MarketingDrawStep2Param,
  MarketingLiveStreamRoomQuery,
  MarketingLiveStreamRoomSaveDto,
  MarketingLiveStreamScheduleGroupDto,
  MarketingLiveStreamScheduleSaveParam,
  MarketingLiveStreamUserDto,
  MarketingLiveStreamUserQuery,
  MarketingPrizePoolParam,
  MarketingTurnTableParam,
  MarketingWatermarkGoodsRelParam,
  MarketingWatermarkPram,
  MemBaseInfoDto,
  MemberAccountPageQueryParam,
  MemberInfoPageQueryParam,
  MemDragonCardRecordDto,
  MemGrowthBatchSaveParam,
  MemGrowthEditParam,
  MemLabelDto,
  MemLabelUpdateBindParam,
  MemLevelDto,
  MemLevelInterestsDto,
  MemPointEditParam,
  MemSignRuleDto,
  OpenChildAccountDto,
  OperationLog,
  OrderAddressDto,
  OrderConfirmInvoiceDto,
  OrderTagParam,
  PayAccountApplyDto,
  PayAccountApplyPersonDto,
  PayAccountMarginDto,
  PaySubmitBindingCardDto,
  QueryGoodsParam,
  RealNameDto,
  RechargeDto,
  RefundAgreeParam,
  RefundCheckParam,
  RefundDepositParam,
  RefundRefuseParam,
  RefundReviewDto,
  RefundShippingParam,
  RefundUserDto,
  RegisterDto,
  RenewalApplyPassDto,
  ResetPwdParam,
  SaveGoodsDto,
  SensorsReportParam,
  ShipInfoParam,
  SignInNetDto,
  SpuInfoParam,
  StoreCloseParam,
  SysDataCubeDto,
  SysDataCubeQueryParam,
  SysDeptAddParam,
  SysOauthClientAddParam,
  SysRoleAddParam,
  SysStoreUserGroupAddParam,
  SysUserAddParam,
  TransactionBrandUploadParam,
  TransactionCategoryUploadPlatformParam,
  TransactionComponencategoryAuditDto,
  TransactionComponentBrandAuditDto,
  TransactionComponentCategoryDto,
  TransferDto,
  UpdateStoreStatusDto,
  WithdrawDto,
  XiaoNengResponseString,
  XiaoNengResponseSysXiaonengUser,
  XiaoNengResponseXiaoNengCsUserVo
} from "./data-contracts";
import requestInstance from "./instance";

type RequestConfig = Omit<IRequestConfig, "url" | "method">;

export const API = {
  /**
   * No description
   * @name POST /admin/admin/MallBrandApply/auditBrandList
   * @summary 品牌申请表审核 批量版本
   * @tags A平台-品牌审核
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandApply/auditBrandList_POST": (data: MallBrandApplyAuditParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallBrandApply/auditBrandList`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallBrandApply/auditBrandOne
   * @summary 品牌申请表审核
   * @tags A平台-品牌审核
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandApply/auditBrandOne_POST": (data: MallBrandApplyAuditParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallBrandApply/auditBrandOne`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/MallBrandApply/brandApply/detail/{storeNo}
   * @summary A平台-店铺品牌审核详情
   * @tags A平台-品牌审核
   * @response `200` `JsonResultBrandApplyAuditDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandApply/brandApply/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminAdminMallBrandApplyBrandApplyDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallBrandApply/brandApply/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBrandApplyAuditDetailVo>,
  /**
   * No description
   * @name GET /admin/admin/MallBrandApply/query
   * @summary 品牌审核分页查询
   * @tags A平台-品牌审核
   * @response `200` `JsonResultPageInfoMallBrandApplyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandApply/query_GET": (
    query: AdminAdminMallBrandApplyQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallBrandApply/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallBrandApplyVo>,
  /**
   * No description
   * @name GET /admin/admin/MallBrandCategoryRel/getListByBrand
   * @summary 根据品牌获取分类
   * @tags A平台-品牌归属类目
   * @response `200` `JsonResultListMallBrandCategoryRelVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandCategoryRel/getListByBrand_GET": (
    query: AdminAdminMallBrandCategoryRelGetListByBrandGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallBrandCategoryRel/getListByBrand`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallBrandCategoryRelVo>,
  /**
   * No description
   * @name POST /admin/admin/MallBrandCategoryRel/getListByBrands
   * @summary 根据品牌获取分类集合版本
   * @tags A平台-品牌归属类目
   * @response `200` `JsonResultListMallBrandCategoryRelVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandCategoryRel/getListByBrands_POST": (
    data: BindMemberUsingPOSTMembernos,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallBrandCategoryRel/getListByBrands`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMallBrandCategoryRelVo>,
  /**
   * No description
   * @name POST /admin/admin/MallBrandCategoryRel/saveOrUpdate
   * @summary 新增保存分类信息
   * @tags A平台-品牌归属类目
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallBrandCategoryRel/saveOrUpdate_POST": (data: MallBrandCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallBrandCategoryRel/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getAuditedGoodsExcel
   * @summary 已审核-excel
   * @tags E-平台-商品模块
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getAuditedGoodsExcel_POST": (
    query: AdminAdminMallGoodsGetAuditedGoodsExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getAuditedGoodsExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getAuditedGoodsList
   * @summary 平台端查询-已审核
   * @tags E-平台-商品模块
   * @response `200` `JsonResultPageInfoMallGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getAuditedGoodsList_POST": (
    query: AdminAdminMallGoodsGetAuditedGoodsListPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getAuditedGoodsList`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoods/getMallGoodsDetail
   * @summary 商品详情
   * @tags E-平台-商品模块
   * @response `200` `JsonResultMallGoodsDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getMallGoodsDetail_GET": (
    query: AdminAdminMallGoodsGetMallGoodsDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getMallGoodsDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsDetailVo>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getPendingReviewGoodsExcel
   * @summary 待审核-excel
   * @tags E-平台-商品模块
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getPendingReviewGoodsExcel_POST": (
    query: AdminAdminMallGoodsGetPendingReviewGoodsExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getPendingReviewGoodsExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getPendingReviewGoodsList
   * @summary 平台端查询-待审核
   * @tags E-平台-商品模块
   * @response `200` `JsonResultPageInfoMallGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getPendingReviewGoodsList_POST": (
    query: AdminAdminMallGoodsGetPendingReviewGoodsListPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getPendingReviewGoodsList`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsPageVo>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getRejectedReviewGoodsExcel
   * @summary 未通过-excel
   * @tags E-平台-商品模块
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getRejectedReviewGoodsExcel_POST": (
    query: AdminAdminMallGoodsGetRejectedReviewGoodsExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getRejectedReviewGoodsExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/getRejectedReviewGoodsList
   * @summary 平台端查询-审核未通过
   * @tags E-平台-商品模块
   * @response `200` `JsonResultPageInfoMallGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getRejectedReviewGoodsList_POST": (
    query: AdminAdminMallGoodsGetRejectedReviewGoodsListPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getRejectedReviewGoodsList`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoods/getRemark
   * @summary 查询最新的拒绝审核数据
   * @tags E-平台-商品模块
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/getRemark_GET": (query: AdminAdminMallGoodsGetRemarkGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoods/getRemark`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/goodsAdminOffShelf
   * @summary 平台下架商品
   * @tags E-平台-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/goodsAdminOffShelf_POST": (
    query: AdminAdminMallGoodsGoodsAdminOffShelfPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/goodsAdminOffShelf`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/goodsAdminOffShelfList
   * @summary 批量平台下架商品
   * @tags E-平台-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/goodsAdminOffShelfList_POST": (
    data: GoodsAdminOffShelfListUsingPOSTGoodsnolist,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/goodsAdminOffShelfList`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/reviewGoods
   * @summary 商品审核
   * @tags E-平台-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/reviewGoods_POST": (data: GoodsReviewParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoods/reviewGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/reviewGoodsListNo
   * @summary 批量拒绝-商品审核
   * @tags E-平台-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/reviewGoodsListNo_POST": (data: GoodsReviewParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoods/reviewGoodsListNo`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoods/reviewGoodsListOk
   * @summary 批量通过-商品审核
   * @tags E-平台-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/reviewGoodsListOk_POST": (
    data: GoodsAdminOffShelfListUsingPOSTGoodsnolist,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoods/reviewGoodsListOk`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsCommentStore/queryGoodsComment
   * @summary 商品评论表分页查询
   * @tags E-平台-商品评论表
   * @response `200` `JsonResultPageInfoMallGoodsCommentVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsCommentStore/queryGoodsComment_GET": (
    query: AdminAdminMallGoodsCommentStoreQueryGoodsCommentGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsCommentStore/queryGoodsComment`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsCommentVo>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsCommentStore/queryGoodsCommentExcel
   * @summary 商品评论表分页查询-excel
   * @tags E-平台-商品评论表
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsCommentStore/queryGoodsCommentExcel_POST": (
    query: AdminAdminMallGoodsCommentStoreQueryGoodsCommentExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsCommentStore/queryGoodsCommentExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsCommentStore/updateShowStatus
   * @summary 显示状态修改 showStatus 0：否，1：是
   * @tags E-平台-商品评论表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsCommentStore/updateShowStatus_POST": (
    query: AdminAdminMallGoodsCommentStoreUpdateShowStatusPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsCommentStore/updateShowStatus`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsGroup/getList
   * @summary 商品组表查询
   * @tags B-商品组表
   * @response `200` `JsonResultListMallGoodsGroupVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/getList_POST": (
    query: AdminAdminMallGoodsGroupGetListPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/getList`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsGroupVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsGroup/getOne
   * @summary 获取单个
   * @tags B-商品组表
   * @response `200` `JsonResultMallGoodsGroupDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/getOne_GET": (
    query: AdminAdminMallGoodsGroupGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsGroupDetailVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsGroup/queryChildList
   * @summary 商品子组表列表
   * @tags B-商品组表
   * @response `200` `JsonResultListMallGoodsGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/queryChildList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/queryChildList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsGroupVo>,
  /**
   * No description
   * @name DELETE /admin/admin/MallGoodsGroup/removeById
   * @summary 删除
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/MallGoodsGroup/removeById_DELETE": (
    query: AdminAdminMallGoodsGroupRemoveByIdDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/removeById`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsGroup/saveOrUpdate
   * @summary 新增商品组
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/saveOrUpdate_POST": (data: MallGoodsGroupParentParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsGroup/saveOrUpdateChild
   * @summary 新增商品子组数据
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/saveOrUpdateChild_POST": (data: MallGoodsGroupChildParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/saveOrUpdateChild`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/admin/MallGoodsGroup/sort
   * @summary 排序
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/sort_PUT": (query: AdminAdminMallGoodsGroupSortPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/sort`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/admin/MallGoodsGroup/sortChild
   * @summary 商品子排序
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/sortChild_PUT": (
    query: AdminAdminMallGoodsGroupSortChildPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/sortChild`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * @description showStatus 0 不显示 1 显示
   * @name POST /admin/admin/MallGoodsGroup/updateStatus
   * @summary 修改商品组显示状态
   * @tags B-商品组表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsGroup/updateStatus_POST": (
    query: AdminAdminMallGoodsGroupUpdateStatusPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsGroup/updateStatus`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsLabel/getOne
   * @summary 获取单个详情
   * @tags D-商品标签
   * @response `200` `JsonResultMallGoodsLabelDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsLabel/getOne_GET": (
    query: AdminAdminMallGoodsLabelGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsLabelDetailVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsLabel/query
   * @summary 商品标签表分页查询
   * @tags D-商品标签
   * @response `200` `JsonResultPageInfoMallGoodsLabelVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsLabel/query_GET": (
    query: AdminAdminMallGoodsLabelQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsLabelVo>,
  /**
   * No description
   * @name GET /admin/admin/MallGoodsLabel/removeById
   * @summary 删除标签
   * @tags D-商品标签
   * @response `200` `JsonResultObject` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsLabel/removeById_GET": (
    query: AdminAdminMallGoodsLabelRemoveByIdGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/removeById`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name DELETE /admin/admin/MallGoodsLabel/removeById
   * @summary 删除标签
   * @tags D-商品标签
   * @response `200` `JsonResultObject` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/MallGoodsLabel/removeById_DELETE": (
    query: AdminAdminMallGoodsLabelRemoveByIdDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/removeById`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsLabel/saveGoodsLabel
   * @summary 新增商品标签
   * @tags D-商品标签
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsLabel/saveGoodsLabel_POST": (data: MallGoodsLabelParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/saveGoodsLabel`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/MallGoodsLabel/saveOrUpdateGoods
   * @summary 标签增加商品
   * @tags D-商品标签
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoodsLabel/saveOrUpdateGoods_POST": (data: MallGoodsLabelRelParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoodsLabel/saveOrUpdateGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/MallStoreBrandRel/getPage
   * @summary 店铺品牌关联表分页查询
   * @tags A平台-店铺品牌管理
   * @response `200` `JsonResultPageInfoMallStoreBrandRelVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallStoreBrandRel/getPage_GET": (
    query: AdminAdminMallStoreBrandRelGetPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MallStoreBrandRel/getPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallStoreBrandRelVo>,
  /**
   * @description 0：平台 1：店铺
   * @name POST /admin/admin/MarketingActivity/getActivity
   * @summary 通用获取当前活动信息 + 商品分页
   * @tags 平台端活动管理
   * @response `200` `JsonResultMarketingActivityGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivity/getActivity_POST": (
    data: MarketingActivityGoodsInfoQuery,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivity/getActivity`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityGoodsPageVo>,
  /**
   * No description
   * @name DELETE /admin/admin/MarketingActivityApply/delApply
   * @summary 删除平台活动
   * @tags 平台配置活动
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/MarketingActivityApply/delApply_DELETE": (
    query: AdminAdminMarketingActivityApplyDelApplyDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/delApply`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/admin/MarketingActivityApply/delById
   * @summary 删除活动商品
   * @tags 平台配置活动
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/MarketingActivityApply/delById_DELETE": (
    query: AdminAdminMarketingActivityApplyDelByIdDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/delById`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 获取参加平台活动的商品
   * @name GET /admin/admin/MarketingActivityApply/getActivityGoodsByApplyNo
   * @summary 获取参加平台活动的商品
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityGoodsAdminVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getActivityGoodsByApplyNo_GET": (
    query: AdminAdminMarketingActivityApplyGetActivityGoodsByApplyNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getActivityGoodsByApplyNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityGoodsAdminVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getDetailByStore
   * @summary 获取审核店铺详情信息
   * @tags 平台配置活动
   * @response `200` `JsonResultMarketingActivityApplyDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getDetailByStore_GET": (
    query: AdminAdminMarketingActivityApplyGetDetailByStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getDetailByStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityApplyDto>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getFullDiscountDetail
   * @summary 查询满减满折详情
   * @tags 平台配置活动
   * @response `200` `JsonResultMarketingActivityFullDiscountApplyDetail` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getFullDiscountDetail_GET": (
    query: AdminAdminMarketingActivityApplyGetFullDiscountDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getFullDiscountDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityFullDiscountApplyDetail>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getGoodsPage
   * @summary 查询店铺报名参加的商品
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityGoodsParam` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getGoodsPage_GET": (
    query: AdminAdminMarketingActivityApplyGetGoodsPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getGoodsPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityGoodsParam>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getPanicBuyDetail
   * @summary 查询限时购买详情
   * @tags 平台配置活动
   * @response `200` `JsonResultMarketingActivityPanicBuyApplyDetail` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getPanicBuyDetail_GET": (
    query: AdminAdminMarketingActivityApplyGetPanicBuyDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getPanicBuyDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityPanicBuyApplyDetail>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getSkuPage
   * @summary 查询店铺报名参加的商品中的SKU
   * @tags 平台配置活动
   * @response `200` `JsonResultListMarketingActivitySkuParam` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getSkuPage_GET": (
    query: AdminAdminMarketingActivityApplyGetSkuPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getSkuPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMarketingActivitySkuParam>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getTitleVo
   * @summary 查询活动标题
   * @tags 平台配置活动
   * @response `200` `JsonResultMarketingActivityApplyTitleVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getTitleVo_GET": (
    query: AdminAdminMarketingActivityApplyGetTitleVoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getTitleVo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityApplyTitleVo>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/openOrClose
   * @summary 开启关闭活动
   * @tags 平台配置活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/openOrClose_POST": (data: ApplyOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/openOrClose`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/queryFullDiscountExcel
   * @summary 查询满减满折分页-excel
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryFullDiscountExcel_POST": (
    query: AdminAdminMarketingActivityApplyQueryFullDiscountExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryFullDiscountExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryFullDiscountPage
   * @summary 查询满减满折分页
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityApplyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryFullDiscountPage_GET": (
    query: AdminAdminMarketingActivityApplyQueryFullDiscountPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryFullDiscountPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityApplyVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryFullDiscountPendingPage
   * @summary 查询满减满折分页 参加的未审核店铺
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryFullDiscountPendingPage_GET": (
    query: AdminAdminMarketingActivityApplyQueryFullDiscountPendingPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryFullDiscountPendingPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryFullDiscountStorePage
   * @summary 查询满减满折分页 参加的已审核店铺
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryFullDiscountStorePage_GET": (
    query: AdminAdminMarketingActivityApplyQueryFullDiscountStorePageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryFullDiscountStorePage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/queryPanicBuyingExcel
   * @summary 查询限时抢购分页-excel
   * @tags 平台配置活动
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryPanicBuyingExcel_POST": (
    query: AdminAdminMarketingActivityApplyQueryPanicBuyingExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryPanicBuyingExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryPanicBuyingPage
   * @summary 查询限时抢购分页
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityApplyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryPanicBuyingPage_GET": (
    query: AdminAdminMarketingActivityApplyQueryPanicBuyingPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryPanicBuyingPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityApplyVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryTimeBuyPage
   * @summary 查询限时抢购分页 参加的未审核店铺
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryTimeBuyPage_GET": (
    query: AdminAdminMarketingActivityApplyQueryTimeBuyPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryTimeBuyPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryTimeBuyPendingPage
   * @summary 查询限时抢购分页 参加的已审核店铺
   * @tags 平台配置活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryTimeBuyPendingPage_GET": (
    query: AdminAdminMarketingActivityApplyQueryTimeBuyPendingPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryTimeBuyPendingPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/review
   * @summary 审核店铺
   * @tags 平台配置活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/review_POST": (data: MarketingActivityReviewDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/review`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/saveFullDiscount
   * @summary 新增满减满折
   * @tags 平台配置活动
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/saveFullDiscount_POST": (
    data: MarketingActivityFullDiscountApplyParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/saveFullDiscount`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/admin/MarketingActivityApply/savePanicBuy
   * @summary 新增限时抢购
   * @tags 平台配置活动
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/savePanicBuy_POST": (
    data: MarketingActivityPanicBuyApplyParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/savePanicBuy`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 店铺 -活动启用和停用
   * @name PUT /admin/admin/MarketingActivityApply/updateStatus
   * @summary 店铺 -活动启用和停用
   * @tags 平台配置活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/updateStatus_PUT": (data: ActivityOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/updateStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/admin/aftermarket/getRefundResult
   * @summary 退款查询
   * @tags P-支付-售后
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/aftermarket/getRefundResult_GET": (
    query: AdminAdminAftermarketGetRefundResultGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/aftermarket/getRefundResult`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 仲裁扣保证金开启状态
   * @name GET /admin/admin/arb/deductStart
   * @summary 仲裁扣保证金开启状态
   * @tags 平台仲裁管理
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/deductStart_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/deductStart`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/admin/arb/export
   * @summary 平台仲裁单导出,导出全部数据需要指定pageSize=0
   * @tags 平台仲裁管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/export_GET": (query: AdminAdminArbExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 仲裁单信息
   * @name GET /admin/admin/arb/info
   * @summary 仲裁单信息
   * @tags 平台仲裁管理
   * @response `200` `JsonResultArbitrationOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/info_GET": (query: AdminAdminArbInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultArbitrationOrderDto>,
  /**
   * @description 平台待仲裁单分页查询
   * @name GET /admin/admin/arb/list
   * @summary 平台待仲裁单分页查询
   * @tags 平台仲裁管理
   * @response `200` `JsonResultPageInfoArbitrationOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/list_GET": (query: AdminAdminArbListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoArbitrationOrderDto>,
  /**
   * @description 店铺责任
   * @name PUT /admin/admin/arb/store
   * @summary 店铺责任
   * @tags 平台仲裁管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/store_PUT": (data: ArbitrationOrderParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/store`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 买家责任
   * @name PUT /admin/admin/arb/user
   * @summary 买家责任
   * @tags 平台仲裁管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/arb/user_PUT": (data: ArbitrationOrderParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/arb/user`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/admin/goods/createInitCode
   * @summary 创建初始化数据凭证(有效时间2分钟)
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/createInitCode_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/createInitCode`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/admin/goods/init
   * @summary 商品索引数据初始化导入
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/init_GET": (query: AdminAdminGoodsInitGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/init`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/goods/onlineGoods
   * @summary 上架商品
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/onlineGoods_POST": (data: BatchGoodsStatusUpdateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/onlineGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/goods/updateBatchGoodsStatus
   * @summary 下架商品
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/updateBatchGoodsStatus_POST": (data: BatchGoodsStatusUpdateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/updateBatchGoodsStatus`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/goods/updateGoods
   * @summary 修改商品
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/updateGoods_POST": (data: SaveGoodsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/updateGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/goods/updateGoodsStatus
   * @summary 修改商品状态
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/updateGoodsStatus_POST": (data: GoodsStatusUpdateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/updateGoodsStatus`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/goods/updateStoreStatus
   * @summary 批量更新店铺状态
   * @tags S-商品搜索-商品数据检索
   * @response `200` `JsonResultInt` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/goods/updateStoreStatus_POST": (data: UpdateStoreStatusDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/goods/updateStoreStatus`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/admin/helpCenterArticle/add
   * @summary 新增帮助中心文章
   * @tags 帮助中心文章维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/helpCenterArticle/add_POST": (data: MallConfHelpCenterArticleParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/helpCenterArticle/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/admin/helpCenterArticle/delete
   * @summary 根据id删除
   * @tags 帮助中心文章维护
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/helpCenterArticle/delete_DELETE": (
    query: AdminAdminHelpCenterArticleDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/helpCenterArticle/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/helpCenterArticle/listPage
   * @summary 带参数分页展示
   * @tags 帮助中心文章维护
   * @response `200` `JsonResultPageInfoMallConfHelpCenterArticleVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/helpCenterArticle/listPage_GET": (
    query: AdminAdminHelpCenterArticleListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/helpCenterArticle/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfHelpCenterArticleVo>,
  /**
   * No description
   * @name GET /admin/admin/helpCenterArticle/queryAllByCategoryId
   * @summary 根据帮助中心分类的id查询帮助中心下所有的文章
   * @tags 帮助中心文章维护
   * @response `200` `JsonResultListMallConfHelpCenterArticleVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/helpCenterArticle/queryAllByCategoryId_GET": (
    query: AdminAdminHelpCenterArticleQueryAllByCategoryIdGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/helpCenterArticle/queryAllByCategoryId`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfHelpCenterArticleVo>,
  /**
   * No description
   * @name PUT /admin/admin/helpCenterArticle/update
   * @summary 更新
   * @tags 帮助中心文章维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/helpCenterArticle/update_PUT": (data: MallConfHelpCenterArticleParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/helpCenterArticle/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/admin/mallBrand/del
   * @summary 批量删除
   * @tags A平台-品牌库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/del_POST": (data: BindMemberUsingPOSTMembernos, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallBrand/del`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/mallBrand/detail
   * @summary 品牌详情
   * @tags A平台-品牌库
   * @response `200` `JsonResultMallBrandDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/detail_GET": (query: AdminAdminMallBrandDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallBrand/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallBrandDetailVo>,
  /**
   * No description
   * @name GET /admin/admin/mallBrand/getOne
   * @summary 查询一个
   * @tags A平台-品牌库
   * @response `200` `JsonResultMallBrandVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/getOne_GET": (query: AdminAdminMallBrandGetOneGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallBrand/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallBrandVo>,
  /**
   * No description
   * @name GET /admin/admin/mallBrand/getOneByBrandNo
   * @summary 查询一个 根据编号
   * @tags A平台-品牌库
   * @response `200` `JsonResultMallBrandVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/getOneByBrandNo_GET": (
    query: AdminAdminMallBrandGetOneByBrandNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallBrand/getOneByBrandNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallBrandVo>,
  /**
   * No description
   * @name GET /admin/admin/mallBrand/listPage
   * @summary 品牌表分页查询
   * @tags A平台-品牌库
   * @response `200` `JsonResultPageInfoMallBrandVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/listPage_GET": (query: AdminAdminMallBrandListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallBrand/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallBrandVo>,
  /**
   * No description
   * @name POST /admin/admin/mallBrand/saveOrUpdate
   * @summary 新增或者修改品牌
   * @tags A平台-品牌库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/saveOrUpdate_POST": (data: MallBrandParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallBrand/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/admin/mallBrand/updateNoChecked
   * @summary 修改是否审核状态
   * @tags A平台-品牌库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallBrand/updateNoChecked_PUT": (
    query: AdminAdminMallBrandUpdateNoCheckedPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallBrand/updateNoChecked`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/admin/mallGoodsCategory/del
   * @summary 类目删除 级联删除
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/mallGoodsCategory/del_DELETE": (
    query: AdminAdminMallGoodsCategoryDelDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/del`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/admin/mallGoodsCategory/getCategoryTree
   * @summary 根据 三级类目编号 获取 对应的树
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultListMallCategoryTree` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/getCategoryTree_POST": (
    data: BindMemberUsingPOSTMembernos,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/getCategoryTree`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMallCategoryTree>,
  /**
   * No description
   * @name GET /admin/admin/mallGoodsCategory/getOne
   * @summary 获取内容 三级类目 得到规格和属性
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultMallGoodsCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/getOne_GET": (
    query: AdminAdminMallGoodsCategoryGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsCategoryVo>,
  /**
   * @description 商品类目表查询
   * @name GET /admin/admin/mallGoodsCategory/getPage
   * @summary 商品类目表查询
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultListMallGoodsCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/getPage_GET": (
    query: AdminAdminMallGoodsCategoryGetPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/getPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsCategoryVo>,
  /**
   * No description
   * @name POST /admin/admin/mallGoodsCategory/saveOrUpdate
   * @summary 新增修改
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/saveOrUpdate_POST": (data: MallGoodsCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/admin/mallGoodsCategory/show
   * @summary 是否展示
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/show_PUT": (
    query: AdminAdminMallGoodsCategoryShowPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/show`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/admin/mallGoodsCategory/sort
   * @summary 修改展示顺序 越大 越靠前
   * @tags A平台-类目库-类目管理
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/mallGoodsCategory/sort_PUT": (
    query: AdminAdminMallGoodsCategorySortPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/mallGoodsCategory/sort`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/admin/order/export
   * @summary 平台订单导出
   * @tags 平台订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/export_GET": (query: AdminAdminOrderExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/admin/order/info
   * @summary 订单信息
   * @tags 平台订单管理
   * @response `200` `JsonResultOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/info_GET": (query: AdminAdminOrderInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderDto>,
  /**
   * @description 平台订单分页查询
   * @name GET /admin/admin/order/list
   * @summary 平台订单分页查询
   * @tags 平台订单管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/list_GET": (query: AdminAdminOrderListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * @description 查询订单结算信息
   * @name GET /admin/admin/order/settlement
   * @summary 查询订单结算信息
   * @tags 平台订单管理
   * @response `200` `JsonResultOrderSettlementDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/settlement_GET": (query: AdminAdminOrderSettlementGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/settlement`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderSettlementDto>,
  /**
   * No description
   * @name GET /admin/admin/order/{memberNo}
   * @summary 根据用户查询订单信息 分页
   * @tags 平台订单管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/{memberNo}_GET": (memberNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/${memberNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name GET /admin/admin/refund/export
   * @summary 平台售后订单导出,导出全部数据需要指定pageSize=0
   * @tags 平台售后管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/refund/export_GET": (query: AdminAdminRefundExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/refund/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 售后单信息
   * @name GET /admin/admin/refund/info
   * @summary 售后单信息
   * @tags 平台售后管理
   * @response `200` `JsonResultRefundMasterDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/refund/info_GET": (query: AdminAdminRefundInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/refund/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundMasterDto>,
  /**
   * @description 平台售后订单分页查询
   * @name GET /admin/admin/refund/list
   * @summary 平台售后订单分页查询
   * @tags 平台售后管理
   * @response `200` `JsonResultPageInfoRefundMasterDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/refund/list_GET": (query: AdminAdminRefundListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/refund/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRefundMasterDto>,
  /**
   * No description
   * @name POST /admin/admin/sensors/report
   * @summary 数据报表
   * @tags S-神策-数据报表
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sensors/report_POST": (data: SensorsReportParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sensors/report`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 手工处理未完成分账的订单，处理状态DeductionPoint、UnfreezeOrder、UnfreezeSubsidy
   * @name PUT /admin/admin/settlement/order/transfer
   * @summary 手工分账
   * @tags 平台结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/order/transfer_PUT": (
    query: AdminAdminSettlementOrderTransferPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/order/transfer`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 平台实收订单分页查询
   * @name GET /admin/admin/settlement/orders
   * @summary 平台实收订单分页查询
   * @tags 平台结算管理
   * @response `200` `JsonResultOrderLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/orders_GET": (query: AdminAdminSettlementOrdersGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/settlement/orders`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/admin/settlement/orders/export
   * @summary 平台实收订单导出,导出全部数据需要指定pageSize=0
   * @tags 平台结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/orders/export_GET": (
    query: AdminAdminSettlementOrdersExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/orders/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 平台实收订单统计
   * @name GET /admin/admin/settlement/orders/stat
   * @summary 平台实收订单统计
   * @tags 平台结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/orders/stat_GET": (
    query: AdminAdminSettlementOrdersStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/orders/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 平台退款单分页查询
   * @name GET /admin/admin/settlement/refund
   * @summary 平台退款单分页查询
   * @tags 平台结算管理
   * @response `200` `JsonResultRefundLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/refund_GET": (query: AdminAdminSettlementRefundGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/settlement/refund`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/admin/settlement/refund/export
   * @summary 平台退款单导出,导出全部数据需要指定pageSize=0
   * @tags 平台结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/refund/export_GET": (
    query: AdminAdminSettlementRefundExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/refund/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 手工处理未完成扣款的保证金，处理状态FineMargin
   * @name PUT /admin/admin/settlement/refund/margin
   * @summary 手工罚保证金
   * @tags 平台结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/refund/margin_PUT": (
    query: AdminAdminSettlementRefundMarginPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/refund/margin`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 手工处理未完成退款的退款单，处理状态ReturnDeductionPoint、RefundAmount
   * @name PUT /admin/admin/settlement/refund/return
   * @summary 手工退款
   * @tags 平台结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/refund/return_PUT": (
    query: AdminAdminSettlementRefundReturnPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/refund/return`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 平台退款单统计
   * @name GET /admin/admin/settlement/refund/stat
   * @summary 平台退款单统计
   * @tags 平台结算管理
   * @response `200` `JsonResultRefundStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/refund/stat_GET": (
    query: AdminAdminSettlementRefundStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/refund/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundStatisticsDto>,
  /**
   * @description 平台补贴单分页查询
   * @name GET /admin/admin/settlement/subsidy
   * @summary 平台补贴单分页查询
   * @tags 平台结算管理
   * @response `200` `JsonResultSubsidyItemLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidy_GET": (query: AdminAdminSettlementSubsidyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidy`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSubsidyItemLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/admin/settlement/subsidy/export
   * @summary 平台补贴单导出,导出全部数据需要指定pageSize=0
   * @tags 平台结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidy/export_GET": (
    query: AdminAdminSettlementSubsidyExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidy/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 手工处理未冻结的订单，处理状态FreezeSubsidy
   * @name PUT /admin/admin/settlement/subsidy/freeze
   * @summary 冻结补贴
   * @tags 平台结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidy/freeze_PUT": (
    query: AdminAdminSettlementSubsidyFreezePutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidy/freeze`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 平台补贴单统计
   * @name GET /admin/admin/settlement/subsidy/stat
   * @summary 平台补贴单统计
   * @tags 平台结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidy/stat_GET": (
    query: AdminAdminSettlementSubsidyStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidy/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 平台应收订单分页查询
   * @name GET /admin/admin/settlement/ysorders
   * @summary 平台应收订单分页查询
   * @tags 平台结算管理
   * @response `200` `JsonResultOrderLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/ysorders_GET": (query: AdminAdminSettlementYsordersGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/settlement/ysorders`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/admin/settlement/ysorders/export
   * @summary 平台应收订单导出,导出全部数据需要指定pageSize=0
   * @tags 平台结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/ysorders/export_GET": (
    query: AdminAdminSettlementYsordersExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/ysorders/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 平台应收订单统计
   * @name GET /admin/admin/settlement/ysorders/stat
   * @summary 平台应收订单统计
   * @tags 平台结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/ysorders/stat_GET": (
    query: AdminAdminSettlementYsordersStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/ysorders/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 平台待发货订单分页查询
   * @name GET /admin/admin/shipping/list
   * @summary 平台待发货订单分页查询
   * @tags 平台发货管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/shipping/list_GET": (query: AdminAdminShippingListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/shipping/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name POST /admin/admin/suggestion/addSuggestion
   * @summary 添加意见反馈
   * @tags 展示用户意见反馈表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/suggestion/addSuggestion_POST": (data: MallConfSuggestionParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/suggestion/addSuggestion`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/suggestion/listPage
   * @summary 分页展示用户意见反馈
   * @tags 展示用户意见反馈表
   * @response `200` `JsonResultPageInfoMallConfSuggestionVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/suggestion/listPage_GET": (query: AdminAdminSuggestionListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/suggestion/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfSuggestionVo>,
  /**
   * No description
   * @name GET /admin/admin/suggestion/query/export
   * @summary 筛选导出意见反馈
   * @tags 展示用户意见反馈表
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/suggestion/query/export_GET": (
    query: AdminAdminSuggestionQueryExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/suggestion/query/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/sysOauthClient/add
   * @summary 新增
   * @tags 店铺授权应用
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysOauthClient/add_POST": (data: SysOauthClientAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysOauthClient/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/sysOauthClient/authorize
   * @summary 根据clientId发送授权code
   * @tags 店铺授权应用
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysOauthClient/authorize_GET": (
    query: AdminAdminSysOauthClientAuthorizeGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/sysOauthClient/authorize`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/admin/sysOauthClient/delete
   * @summary 删除
   * @tags 店铺授权应用
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/admin/sysOauthClient/delete_DELETE": (
    query: AdminAdminSysOauthClientDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/sysOauthClient/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/sysOauthClient/queryList
   * @summary 分页查询全部
   * @tags 店铺授权应用
   * @response `200` `JsonResultPageInfoSysOauthClientVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysOauthClient/queryList_GET": (
    query: AdminAdminSysOauthClientQueryListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/sysOauthClient/queryList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysOauthClientVo>,
  /**
   * No description
   * @name PUT /admin/admin/sysOauthClient/update
   * @summary 修改
   * @tags 店铺授权应用
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysOauthClient/update_PUT": (data: SysOauthClientAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysOauthClient/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/cs/getSiteid
   * @summary 获取当前店铺的siteid
   * @tags C-小能客服
   * @response `200` `JsonResultMallXiaoNengStoreVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/cs/getSiteid_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cs/getSiteid`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallXiaoNengStoreVo>,
  /**
   * No description
   * @name GET /admin/api/cs/getSiteidByStore
   * @summary 查询siteid
   * @tags C-小能客服
   * @response `200` `JsonResultMallXiaoNengStoreVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/cs/getSiteidByStore_GET": (query: AdminApiCsGetSiteidByStoreGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cs/getSiteidByStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallXiaoNengStoreVo>,
  /**
   * No description
   * @name GET /admin/api/cs/openXiaoNengStore
   * @summary 开通小能客服商铺
   * @tags C-小能客服
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/cs/openXiaoNengStore_GET": (query: AdminApiCsOpenXiaoNengStoreGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cs/openXiaoNengStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/cs/openXiaoNengUser
   * @summary 开通小能客服账号
   * @tags C-小能客服
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/cs/openXiaoNengUser_GET": (query: AdminApiCsOpenXiaoNengUserGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cs/openXiaoNengUser`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/sysRole/add
   * @summary 新增角色(返回ID)
   * @tags 系统-角色表
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysRole/add_POST": (data: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/sysRole/allMenu
   * @summary 所有菜单模块
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysMenuVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysRole/allMenu_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/allMenu`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysMenuVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysRole/delete
   * @summary 角色删除
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysRole/delete_DELETE": (query: AdminApiSysRoleDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/query
   * @summary 角色表分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultPageInfoSysRoleDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysRole/query_GET": (query: AdminApiSysRoleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDto>,
  /**
   * No description
   * @name GET /admin/api/sysRole/queryAll
   * @summary 角色表不分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysRoleDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysRole/queryAll_GET": (query: AdminApiSysRoleQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSysRoleDto>,
  /**
   * No description
   * @name PUT /admin/api/sysRole/update
   * @summary 修改角色
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysRole/update_PUT": (data: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysStoreUserGroup/add
   * @summary 资源分组新增(返回ID)
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysStoreUserGroup/add_POST": (data: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysStoreUserGroup/delete
   * @summary 资源分组删除
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysStoreUserGroup/delete_DELETE": (
    query: AdminApiSysStoreUserGroupDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/detail
   * @summary 资源分组详情
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultSysStoreUserGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysStoreUserGroup/detail_GET": (
    query: AdminApiSysStoreUserGroupDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysStoreUserGroupVo>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/query
   * @summary 资源分组分页查询
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultPageInfoSysStoreUserGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysStoreUserGroup/query_GET": (
    query: AdminApiSysStoreUserGroupQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysStoreUserGroupVo>,
  /**
   * No description
   * @name PUT /admin/api/sysStoreUserGroup/update
   * @summary 资源分组修改
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysStoreUserGroup/update_PUT": (data: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysUser/add
   * @summary 员工新增(返回ID)
   * @tags 系统-员工管理
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysUser/add_POST": (data: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/delete
   * @summary 员工删除
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysUser/delete_DELETE": (query: AdminApiSysUserDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysUser/query
   * @summary 员工表分页查询
   * @tags 系统-员工管理
   * @response `200` `JsonResultPageInfoSysUserVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysUser/query_GET": (query: AdminApiSysUserQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUserVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/resetDefault
   * @summary 重置密码
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysUser/resetDefault_DELETE": (
    query: AdminApiSysUserResetDefaultDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysUser/resetDefault`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/update
   * @summary 员工修改
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysUser/update_PUT": (data: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysdept/add
   * @summary 部门新增(返回ID)
   * @tags 部门表
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysdept/add_POST": (data: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysdept/delete
   * @summary 部门删除
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysdept/delete_DELETE": (query: AdminApiSysdeptDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysdept/query
   * @summary 部门表分页查询
   * @tags 部门表
   * @response `200` `JsonResultPageInfoSysDeptVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysdept/query_GET": (query: AdminApiSysdeptQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAll
   * @summary 部门表不分页查询
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysdept/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAllHaveUser
   * @summary 部门表不分页查询（包含员工信息）
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptHaveUserVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysdept/queryAllHaveUser_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAllHaveUser`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptHaveUserVo>,
  /**
   * No description
   * @name PUT /admin/api/sysdept/update
   * @summary 部门修改
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/sysdept/update_PUT": (data: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/auth/forgot
   * @summary 忘记密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/auth/forgot_POST": (data: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/forgot`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/auth/login
   * @summary 登录 默认账号:19999999999 密码ocj123456 密码需要aes加密，获取token后放在header中{Authorization:Bearer 你的token}
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/auth/login_POST": (data: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/login`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/auth/query
   * @summary 获取当前用户权限
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultSysAuthVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/auth/query_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/query`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultSysAuthVo>,
  /**
   * No description
   * @name POST /admin/auth/register
   * @summary 注册（返回token）
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/auth/register_POST": (data: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/register`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/auth/resetPwd
   * @summary 修改密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/auth/resetPwd_PUT": (data: ResetPwdParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/resetPwd`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getBrandCombobox
   * @summary 获取全部品牌下拉框 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getBrandCombobox_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getBrandCombobox`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getBrandComboboxByStore
   * @summary 品牌下拉框-店铺权限 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getBrandComboboxByStore_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getBrandComboboxByStore`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getCategoryCombobox
   * @summary 获取全部类目下拉框 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListMallStoreCategoryCombobox` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getCategoryCombobox_GET": (
    query: AdminCommonMallComboboxGetCategoryComboboxGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getCategoryCombobox`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreCategoryCombobox>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getGoodsCategoryCombobox
   * @summary 获取类目列表 平台 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getGoodsCategoryCombobox_GET": (
    query: AdminCommonMallComboboxGetGoodsCategoryComboboxGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getGoodsCategoryCombobox`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getGoodsCombobox
   * @summary 商品下拉框 店铺权限控制 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getGoodsCombobox_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getGoodsCombobox`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getGoodsLabelCombobox
   * @summary 商品标签下拉框 店铺权限 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListMallComboboxData` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getGoodsLabelCombobox_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getGoodsLabelCombobox`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallComboboxData>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getGoodsSkuCombobox
   * @summary 商品SKU下拉框 店铺权限控制 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getGoodsSkuCombobox_GET": (
    query: AdminCommonMallComboboxGetGoodsSkuComboboxGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getGoodsSkuCombobox`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getStoreCategoryComboboxByStore
   * @summary 店铺全部商品分类 (店铺筛选) k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getStoreCategoryComboboxByStore_GET": (
    query: AdminCommonMallComboboxGetStoreCategoryComboboxByStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getStoreCategoryComboboxByStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name GET /admin/common/MallCombobox/getStoreCombobox
   * @summary 查询所有店铺 k-v 格式(key:value)
   * @tags A++MALL 模块下拉框
   * @response `200` `JsonResultListHashMapStringString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/MallCombobox/getStoreCombobox_GET": (
    query: AdminCommonMallComboboxGetStoreComboboxGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/common/MallCombobox/getStoreCombobox`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListHashMapStringString>,
  /**
   * No description
   * @name POST /admin/common/excel/getData
   * @summary getData
   * @tags A++MALL Excel解析
   * @response `200` `JsonResultListMap` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/common/excel/getData_POST": (data: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/common/excel/getData`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMap>,
  /**
   * No description
   * @name GET /admin/cs/auth/addCsStore
   * @summary addCsStore
   * @tags C-小能联合登录
   * @response `200` `XiaoNengResponseString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/auth/addCsStore_GET": (query: AdminCsAuthAddCsStoreGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/auth/addCsStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<XiaoNengResponseString>,
  /**
   * No description
   * @name GET /admin/cs/auth/addCsuser
   * @summary addCsuser
   * @tags C-小能联合登录
   * @response `200` `XiaoNengResponseSysXiaonengUser` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/auth/addCsuser_GET": (query: AdminCsAuthAddCsuserGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/auth/addCsuser`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<XiaoNengResponseSysXiaonengUser>,
  /**
   * No description
   * @name GET /admin/cs/auth/createCsToken
   * @summary 为当前登录人生成小能客服token
   * @tags C-小能联合登录
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/auth/createCsToken_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/auth/createCsToken`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/cs/auth/token
   * @summary token
   * @tags C-小能联合登录
   * @response `200` `XiaoNengResponseXiaoNengCsUserVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/auth/token_GET": (query: AdminCsAuthTokenGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/auth/token`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<XiaoNengResponseXiaoNengCsUserVo>,
  /**
   * @description 平台待仲裁单分页查询
   * @name GET /admin/cs/resource/arbiList
   * @summary 平台待仲裁单分页查询
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultPageInfoArbitrationOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/arbiList_GET": (query: AdminCsResourceArbiListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/arbiList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoArbitrationOrderDto>,
  /**
   * No description
   * @name POST /admin/cs/resource/getGoodsPage
   * @summary 商品列表分页查询
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultPageInfoMallGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/getGoodsPage_POST": (query: AdminCsResourceGetGoodsPagePostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/getGoodsPage`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsPageVo>,
  /**
   * No description
   * @name GET /admin/cs/resource/goodsInfo
   * @summary 商品详情
   * @tags C-小能客服-数据查询
   * @response `200` `Record<string, Record<string, string>>` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/goodsInfo_GET": (query: AdminCsResourceGoodsInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/goodsInfo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<Record<string, Record<string, string>>>,
  /**
   * No description
   * @name GET /admin/cs/resource/member/{memberNo}
   * @summary 会员信息
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultMemBaseInfoDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/member/{memberNo}_GET": (memberNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/member/${memberNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMemBaseInfoDto>,
  /**
   * @description 会员订单分页查询
   * @name GET /admin/cs/resource/memberOrderList
   * @summary 会员订单分页查询
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/memberOrderList_GET": (
    query: AdminCsResourceMemberOrderListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/cs/resource/memberOrderList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name GET /admin/cs/resource/orderInfo
   * @summary 订单信息
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/orderInfo_GET": (query: AdminCsResourceOrderInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/orderInfo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderDto>,
  /**
   * @description 店铺责任
   * @name PUT /admin/cs/resource/store
   * @summary 店铺责任
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/store_PUT": (data: ArbitrationOrderParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/store`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺会员订单分页查询
   * @name GET /admin/cs/resource/storeList
   * @summary 店铺会员订单分页查询
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/storeList_GET": (query: AdminCsResourceStoreListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/storeList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name POST /admin/cs/resource/updateAddress
   * @summary 修改订单地址
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/updateAddress_POST": (data: OrderAddressDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/updateAddress`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 买家责任
   * @name PUT /admin/cs/resource/user
   * @summary 买家责任
   * @tags C-小能客服-数据查询
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/cs/resource/user_PUT": (data: ArbitrationOrderParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/cs/resource/user`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/getGoodsCategoryConfig
   * @summary 获取商品类目配置信息
   * @tags 三级类目配置信息获取
   * @response `200` `JsonResultListMallGoodsCategoryPropVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/getGoodsCategoryConfig_GET": (data: QueryGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/getGoodsCategoryConfig`,
      method: "GET",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsCategoryPropVo>,
  /**
   * No description
   * @name GET /admin/growth/rule/queryAll
   * @summary 获取所有成长规则
   * @tags 成长值规则管理
   * @response `200` `JsonResultListMemGrowthRuleDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/growth/rule/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/growth/rule/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMemGrowthRuleDto>,
  /**
   * No description
   * @name PUT /admin/growth/rule/update
   * @summary 批量修改成长规则
   * @tags 成长值规则管理
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/growth/rule/update_PUT": (data: MemGrowthBatchSaveParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/growth/rule/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/job/sysdatacube
   * @summary 修改文件记录
   * @tags 数据中心表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/job/sysdatacube_PUT": (data: SysDataCubeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/job/sysdatacube`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/job/sysdatacube/myStore
   * @summary 本店铺的数据任务下载列表
   * @tags 数据中心表
   * @response `200` `JsonResultListSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/job/sysdatacube/myStore_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/job/sysdatacube/myStore`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/job/sysdatacube/query
   * @summary 数据中心表分页查询
   * @tags 数据中心表
   * @response `200` `JsonResultPageInfoSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/job/sysdatacube/query_POST": (data: SysDataCubeQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/job/sysdatacube/query`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/job/sysdatacube/queryAll
   * @summary 数据列表查询
   * @tags 数据中心表
   * @response `200` `JsonResultListSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/job/sysdatacube/queryAll_POST": (data: SysDataCubeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/job/sysdatacube/queryAll`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/liveStreaming/anchor/selected
   * @summary 主播下拉选择
   * @tags 小程序直播成员管理
   * @response `200` `JsonResultListMarketingLiveStreamUserDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/anchor/selected_POST": (data: MarketingLiveStreamUserQuery, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/anchor/selected`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMarketingLiveStreamUserDto>,
  /**
   * No description
   * @name POST /admin/liveStreaming/user/complete
   * @summary 完善资料
   * @tags 小程序直播成员管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/user/complete_POST": (data: MarketingLiveStreamUserDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/user/complete`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/liveStreaming/user/page
   * @summary 成员分页, 包含主播
   * @tags 小程序直播成员管理
   * @response `200` `JsonResultPageInfoMarketingLiveStreamUserDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/user/page_POST": (data: MarketingLiveStreamUserQuery, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/user/page`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingLiveStreamUserDto>,
  /**
   * No description
   * @name POST /admin/liveStreaming/user/syn
   * @summary 同步成员到本地 前端请无视
   * @tags 小程序直播成员管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/user/syn_POST": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/user/syn`,
      method: "POST",
      params,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/liveStreaming/room/add
   * @summary 不通过计划直接创建直播间
   * @tags 小程序直播间管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/room/add_POST": (data: MarketingLiveStreamRoomSaveDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/room/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/liveStreaming/room/query
   * @summary 获取直播间列表
   * @tags 小程序直播间管理
   * @response `200` `JsonResultPageInfoMarketingLiveStreamRoomDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/room/query_POST": (data: MarketingLiveStreamRoomQuery, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/room/query`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingLiveStreamRoomDto>,
  /**
   * No description
   * @name POST /admin/liveStreaming/room/selected
   * @summary 获取直播间下拉
   * @tags 小程序直播间管理
   * @response `200` `JsonResultListMarketingLiveStreamRoomDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/room/selected_POST": (data: MarketingLiveStreamRoomQuery, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/room/selected`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMarketingLiveStreamRoomDto>,
  /**
   * No description
   * @name POST /admin/liveStreaming/synLiveStreamingRoom
   * @summary 同步直播间
   * @tags 小程序直播间管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/synLiveStreamingRoom_POST": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/synLiveStreamingRoom`,
      method: "POST",
      params,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/liveStreaming/scheduleGroup/addSchedule
   * @summary 保存直播计划组
   * @tags 直播计划组管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/scheduleGroup/addSchedule_POST": (
    data: MarketingLiveStreamScheduleSaveParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/liveStreaming/scheduleGroup/addSchedule`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 同时删除小程序控制台直播间
   * @name DELETE /admin/liveStreaming/scheduleGroup/deleteSchedule/{groupNo}
   * @summary 删除直播计划组
   * @tags 直播计划组管理
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/liveStreaming/scheduleGroup/deleteSchedule/{groupNo}_DELETE": (
    groupNo: string,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/liveStreaming/scheduleGroup/deleteSchedule/${groupNo}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/liveStreaming/scheduleGroup/detail/{groupNo}
   * @summary 直播计划详情
   * @tags 直播计划组管理
   * @response `200` `JsonResultMarketingLiveStreamingScheduleDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/scheduleGroup/detail/{groupNo}_GET": (groupNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/liveStreaming/scheduleGroup/detail/${groupNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMarketingLiveStreamingScheduleDetailVo>,
  /**
   * @description 同时清空该组下的直播间，主播需要重新扫码
   * @name POST /admin/liveStreaming/scheduleGroup/editSchedule
   * @summary 修改直播计划
   * @tags 直播计划组管理
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/scheduleGroup/editSchedule_POST": (
    data: MarketingLiveStreamScheduleSaveParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/liveStreaming/scheduleGroup/editSchedule`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/liveStreaming/scheduleGroup/page
   * @summary 直播计划组分页查询
   * @tags 直播计划组管理
   * @response `200` `JsonResultPageInfoMarketingLiveStreamScheduleGroupDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/liveStreaming/scheduleGroup/page_POST": (
    data: MarketingLiveStreamScheduleGroupDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/liveStreaming/scheduleGroup/page`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingLiveStreamScheduleGroupDto>,
  /**
   * No description
   * @name POST /admin/mall/company/audit/apply
   * @summary 企业再次审核 申请
   * @tags 企业再次审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/company/audit/apply_POST": (data: MallStoreCompanyInfoAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mall/company/audit/apply`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mall/company/audit/notPass/{storeNo}
   * @summary 企业再次审核 驳回
   * @tags 企业再次审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/company/audit/notPass/{storeNo}_POST": (
    storeNo: string,
    data: RenewalApplyPassDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/company/audit/notPass/${storeNo}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mall/company/audit/pass/{storeNo}
   * @summary 企业再次审核 通过
   * @tags 企业再次审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/company/audit/pass/{storeNo}_POST": (
    storeNo: string,
    data: RenewalApplyPassDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/company/audit/pass/${storeNo}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mall/company/audit/renewal/page
   * @summary A平台-再次审核列表(续约)
   * @tags 企业再次审核相关
   * @response `200` `JsonResultPageInfoRenewalAuditPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/company/audit/renewal/page_GET": (
    query: AdminMallCompanyAuditRenewalPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/company/audit/renewal/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRenewalAuditPageVo>,
  /**
   * No description
   * @name GET /admin/mall/company/audit/renewal/record
   * @summary 再次申请(续约)的当前审核记录  包含状态
   * @tags 企业再次审核相关
   * @response `200` `JsonResultMallStoreCompanyRenewalApplyDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/company/audit/renewal/record_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mall/company/audit/renewal/record`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreCompanyRenewalApplyDto>,
  /**
   * @description 检查当前店铺是否需要预警
   * @name GET /admin/mall/mallStoreEarlyWarning/checkRiskWarning/{storeNo}
   * @summary 检查当前店铺是否需要预警
   * @tags C-店铺风险预警
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/mallStoreEarlyWarning/checkRiskWarning/{storeNo}_GET": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mall/mallStoreEarlyWarning/checkRiskWarning/${storeNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mall/mallStoreEarlyWarning/direct/export
   * @summary 预警导出(直接返回下载地址)
   * @tags C-店铺风险预警
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/mallStoreEarlyWarning/direct/export_GET": (
    query: AdminMallMallStoreEarlyWarningDirectExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/mallStoreEarlyWarning/direct/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 预警分页
   * @name GET /admin/mall/mallStoreEarlyWarning/page
   * @summary 预警分页
   * @tags C-店铺风险预警
   * @response `200` `JsonResultPageInfoMallStoreEarlyWarningDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/mallStoreEarlyWarning/page_GET": (
    query: AdminMallMallStoreEarlyWarningPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/mallStoreEarlyWarning/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallStoreEarlyWarningDto>,
  /**
   * @description 切换已读状态
   * @name PUT /admin/mall/mallStoreEarlyWarning/unread/{earlyWarningId}
   * @summary 切换已读状态
   * @tags C-店铺风险预警
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mall/mallStoreEarlyWarning/unread/{earlyWarningId}_PUT": (
    earlyWarningId: number,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mall/mallStoreEarlyWarning/unread/${earlyWarningId}`,
      method: "PUT",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfAddress/add
   * @summary 新增
   * @tags 地址库
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAddress/add_POST": (data: MallConfAddressAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAddress/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/mallConfAddress/delete
   * @summary 删除
   * @tags 地址库
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfAddress/delete_DELETE": (
    query: AdminMallConfAddressDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfAddress/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfAddress/detail
   * @summary 详情
   * @tags 地址库
   * @response `200` `JsonResultMallConfAddressVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAddress/detail_GET": (query: AdminMallConfAddressDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAddress/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfAddressVo>,
  /**
   * No description
   * @name GET /admin/mallConfAddress/queryListAll
   * @summary 不分页查询全部
   * @tags 地址库
   * @response `200` `JsonResultListMallConfAddressVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAddress/queryListAll_GET": (
    query: AdminMallConfAddressQueryListAllGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfAddress/queryListAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfAddressVo>,
  /**
   * No description
   * @name PUT /admin/mallConfAddress/update
   * @summary 修改
   * @tags 地址库
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAddress/update_PUT": (data: MallConfAddressAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAddress/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfAdvertising/add
   * @summary 广告位新增
   * @tags 广告位
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAdvertising/add_POST": (data: MallConfAdvertisingAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfAdvertising/delete
   * @summary 广告位删除
   * @tags 广告位
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfAdvertising/delete_DELETE": (
    query: AdminMallConfAdvertisingDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfAdvertising/detail
   * @summary 查询详情
   * @tags 广告位
   * @response `200` `JsonResultMallConfAdvertisingVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAdvertising/detail_GET": (
    query: AdminMallConfAdvertisingDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfAdvertisingVo>,
  /**
   * No description
   * @name GET /admin/mallConfAdvertising/query
   * @summary 广告位分页查询
   * @tags 广告位
   * @response `200` `JsonResultPageInfoMallConfAdvertisingVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAdvertising/query_GET": (
    query: AdminMallConfAdvertisingQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfAdvertisingVo>,
  /**
   * No description
   * @name PUT /admin/mallConfAdvertising/update
   * @summary 广告位修改
   * @tags 广告位
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfAdvertising/update_PUT": (data: MallConfAdvertisingAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfBanner/addBanner
   * @summary 添加轮播
   * @tags 店铺轮播设置
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfBanner/addBanner_POST": (data: MallConfBannerParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfBanner/addBanner`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfBanner/delete
   * @summary 根据id删除
   * @tags 店铺轮播设置
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfBanner/delete_DELETE": (query: AdminMallConfBannerDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfBanner/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfBanner/listAll
   * @summary 查询全部
   * @tags 店铺轮播设置
   * @response `200` `JsonResultListMallConfBannerVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfBanner/listAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfBanner/listAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfBannerVo>,
  /**
   * No description
   * @name GET /admin/mallConfBanner/listPage
   * @summary 分页展示轮播图
   * @tags 店铺轮播设置
   * @response `200` `JsonResultPageInfoMallConfBannerVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfBanner/listPage_GET": (query: AdminMallConfBannerListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfBanner/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfBannerVo>,
  /**
   * No description
   * @name PUT /admin/mallConfBanner/updateBanner
   * @summary 更新轮播
   * @tags 店铺轮播设置
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfBanner/updateBanner_PUT": (data: MallConfBannerParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfBanner/updateBanner`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfChannel/add
   * @summary 渠道新增
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfChannel/add_POST": (data: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfChannel/delete
   * @summary 渠道删除
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfChannel/delete_DELETE": (
    query: AdminMallConfChannelDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfChannel/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfChannel/query
   * @summary 渠道分页查询
   * @tags 渠道
   * @response `200` `JsonResultPageInfoMallConfChannelDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfChannel/query_GET": (query: AdminMallConfChannelQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfChannelDto>,
  /**
   * No description
   * @name GET /admin/mallConfChannel/queryAll
   * @summary 不分页查询所有渠道
   * @tags 渠道
   * @response `200` `JsonResultListMallConfChannelDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfChannel/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfChannelDto>,
  /**
   * No description
   * @name PUT /admin/mallConfChannel/update
   * @summary 渠道修改
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfChannel/update_PUT": (data: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfExpressTemplate/add
   * @summary 新增
   * @tags 运费模板
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfExpressTemplate/add_POST": (data: MallConfExpressTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/mallConfExpressTemplate/delete
   * @summary 删除
   * @tags 运费模板
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfExpressTemplate/delete_DELETE": (
    query: AdminMallConfExpressTemplateDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfExpressTemplate/detail
   * @summary 运费模板内容详情，不传获取当前店铺默认模板
   * @tags 运费模板
   * @response `200` `JsonResultMallConfExpressTemplateVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfExpressTemplate/detail_GET": (
    query: AdminMallConfExpressTemplateDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfExpressTemplateVo>,
  /**
   * No description
   * @name GET /admin/mallConfExpressTemplate/queryListAll
   * @summary 不分页查询全部
   * @tags 运费模板
   * @response `200` `JsonResultListMallConfExpressTemplateVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfExpressTemplate/queryListAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/queryListAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfExpressTemplateVo>,
  /**
   * No description
   * @name PUT /admin/mallConfExpressTemplate/update
   * @summary 修改
   * @tags 运费模板
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfExpressTemplate/update_PUT": (data: MallConfExpressTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/mallConfExpressTemplate/updateDefault
   * @summary 设置默认
   * @tags 运费模板
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfExpressTemplate/updateDefault_PUT": (
    data: MallConfExpressTemplateAddParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfExpressTemplate/updateDefault`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfHelpCategory/add
   * @summary 添加帮助分类
   * @tags 帮助中心分类维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHelpCategory/add_POST": (data: MallConfHelpCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfHelpCategory/delete
   * @summary 根据id删除帮助分类
   * @tags 帮助中心分类维护
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfHelpCategory/delete_DELETE": (
    query: AdminMallConfHelpCategoryDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfHelpCategory/listAll
   * @summary 全部展示帮助中心分类
   * @tags 帮助中心分类维护
   * @response `200` `JsonResultListMallConfHelpCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHelpCategory/listAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/listAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfHelpCategoryVo>,
  /**
   * No description
   * @name GET /admin/mallConfHelpCategory/listAllShowTrue
   * @summary 全部展示帮助中心分类,只展示显示的分类
   * @tags 帮助中心分类维护
   * @response `200` `JsonResultListMallConfHelpCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHelpCategory/listAllShowTrue_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/listAllShowTrue`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfHelpCategoryVo>,
  /**
 * No description
 * @name GET /admin/mallConfHelpCategory/listPage
 * @summary 分页展示帮助中心分类
入参说明：showStatus 是否显示，1显示，0不显示
 * @tags 帮助中心分类维护
 * @response `200` `JsonResultPageInfoMallConfHelpCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
 */
  "/admin/mallConfHelpCategory/listPage_GET": (
    query: AdminMallConfHelpCategoryListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfHelpCategoryVo>,
  /**
   * No description
   * @name PUT /admin/mallConfHelpCategory/update
   * @summary 更新帮助分类
   * @tags 帮助中心分类维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHelpCategory/update_PUT": (data: MallConfHelpCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHelpCategory/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfHotKey/add
   * @summary 热门搜索词新增
   * @tags 搜索词（热门、默认）
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHotKey/add_POST": (data: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfHotKey/delete
   * @summary 搜索词删除
   * @tags 搜索词（热门、默认）
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfHotKey/delete_DELETE": (query: AdminMallConfHotKeyDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfHotKey/query
   * @summary 搜索词分页查询
   * @tags 搜索词（热门、默认）
   * @response `200` `JsonResultPageInfoMallConfHotKeyDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHotKey/query_GET": (query: AdminMallConfHotKeyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfHotKeyDto>,
  /**
   * No description
   * @name PUT /admin/mallConfHotKey/update
   * @summary 搜索词修改
   * @tags 搜索词（热门、默认）
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfHotKey/update_PUT": (data: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfIndexPopup/deleteById
   * @summary 删除首页弹窗广告
   * @tags 首页弹窗
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfIndexPopup/deleteById_DELETE": (
    query: AdminMallConfIndexPopupDeleteByIdDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfIndexPopup/deleteById`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfIndexPopup/editIndexPopup
   * @summary 编辑首页弹窗广告
   * @tags 首页弹窗
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfIndexPopup/editIndexPopup_POST": (data: MallIndexPopupParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfIndexPopup/editIndexPopup`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfIndexPopup/indexpopup/query/rexport
   * @summary 条件筛选导出首页弹窗信息(直接返回下载地址)
   * @tags 首页弹窗
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfIndexPopup/indexpopup/query/rexport_GET": (
    query: AdminMallConfIndexPopupIndexpopupQueryRexportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfIndexPopup/indexpopup/query/rexport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/mallConfIndexPopup/listPage
   * @summary 分页展示弹窗广告
   * @tags 首页弹窗
   * @response `200` `JsonResultPageInfoMallIndexPopupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfIndexPopup/listPage_GET": (
    query: AdminMallConfIndexPopupListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfIndexPopup/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallIndexPopupVo>,
  /**
   * No description
   * @name POST /admin/mallConfIndexPopup/saveIndexPopup
   * @summary 新增首页弹窗广告
   * @tags 首页弹窗
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfIndexPopup/saveIndexPopup_POST": (data: MallIndexPopupParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfIndexPopup/saveIndexPopup`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfLabel/listPage
   * @summary 分页展示投放标签
   * @tags 投放标签
   * @response `200` `JsonResultPageInfoMallThrowLabelVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLabel/listPage_GET": (query: AdminMallConfLabelListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLabel/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallThrowLabelVo>,
  /**
   * No description
   * @name GET /admin/mallConfLabel/queryAll
   * @summary 查询所有投放标签
   * @tags 投放标签
   * @response `200` `JsonResultListMallThrowLabelVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLabel/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLabel/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallThrowLabelVo>,
  /**
   * @description 编辑时 id必传， 新增时id不传
   * @name POST /admin/mallConfLabel/saveOrUpdate
   * @summary 投放标签新增或编辑
   * @tags 投放标签
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLabel/saveOrUpdate_POST": (data: MallThrowLabelSaveParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLabel/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfLogisticsCompany/add
   * @summary 新增
   * @tags 物流公司
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLogisticsCompany/add_POST": (data: MallConfLogisticsCompanyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/mallConfLogisticsCompany/delete
   * @summary 删除
   * @tags 物流公司
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfLogisticsCompany/delete_DELETE": (
    query: AdminMallConfLogisticsCompanyDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfLogisticsCompany/detail
   * @summary 详情
   * @tags 物流公司
   * @response `200` `JsonResultMallConfLogisticsCompanyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLogisticsCompany/detail_GET": (
    query: AdminMallConfLogisticsCompanyDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfLogisticsCompanyVo>,
  /**
   * No description
   * @name GET /admin/mallConfLogisticsCompany/mallExpressCompanyAll
   * @summary 查询全部快递公司与编号
   * @tags 物流公司
   * @response `200` `JsonResultListMallExpressCompanyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLogisticsCompany/mallExpressCompanyAll_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/mallExpressCompanyAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallExpressCompanyVo>,
  /**
   * No description
   * @name GET /admin/mallConfLogisticsCompany/queryListAll
   * @summary 不分页查询全部
   * @tags 物流公司
   * @response `200` `JsonResultListMallConfLogisticsCompanyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLogisticsCompany/queryListAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/queryListAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfLogisticsCompanyVo>,
  /**
   * No description
   * @name PUT /admin/mallConfLogisticsCompany/update
   * @summary 修改
   * @tags 物流公司
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfLogisticsCompany/update_PUT": (data: MallConfLogisticsCompanyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfLogisticsCompany/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfMaterial/add
   * @summary 素材库新增
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterial/add_POST": (data: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfMaterial/delete
   * @summary 素材库删除
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfMaterial/delete_DELETE": (
    query: AdminMallConfMaterialDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfMaterial/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterial/move
   * @summary 移动素材到分组
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterial/move_PUT": (data: MallConfMaterialMoveParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/move`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfMaterial/query
   * @summary 素材库分页查询
   * @tags 素材库
   * @response `200` `JsonResultPageInfoMallConfMaterialVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterial/query_GET": (query: AdminMallConfMaterialQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfMaterialVo>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterial/update
   * @summary 素材库修改
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterial/update_PUT": (data: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfMaterialGroup/add
   * @summary 素材库分组新增
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterialGroup/add_POST": (data: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfMaterialGroup/delete
   * @summary 素材库分组删除
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfMaterialGroup/delete_DELETE": (
    query: AdminMallConfMaterialGroupDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfMaterialGroup/queryAll
   * @summary 素材库分组不分页查询
   * @tags 素材库分组
   * @response `200` `JsonResultListMallConfMaterialGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterialGroup/queryAll_GET": (
    query: AdminMallConfMaterialGroupQueryAllGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfMaterialGroupVo>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterialGroup/update
   * @summary 素材库分组修改
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfMaterialGroup/update_PUT": (data: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfNavigation/add
   * @summary 店铺导航新增
   * @tags 店铺导航
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfNavigation/add_POST": (data: MallConfNavigationAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfNavigation/delete
   * @summary 店铺导航删除
   * @tags 店铺导航
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfNavigation/delete_DELETE": (
    query: AdminMallConfNavigationDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfNavigation/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfNavigation/queryAll
   * @summary 店铺导航不分页查询
   * @tags 店铺导航
   * @response `200` `JsonResultListMallConfNavigationVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfNavigation/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfNavigationVo>,
  /**
   * No description
   * @name PUT /admin/mallConfNavigation/update
   * @summary 店铺导航修改
   * @tags 店铺导航
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfNavigation/update_PUT": (data: MallConfNavigationAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfRefundReason/add
   * @summary 售后原因新增
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfRefundReason/add_POST": (data: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfRefundReason/delete
   * @summary 售后原因删除
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallConfRefundReason/delete_DELETE": (
    query: AdminMallConfRefundReasonDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfRefundReason/queryAll
   * @summary 售后原因不分页查询
   * @tags 售后原因
   * @response `200` `JsonResultListMallConfRefundReasonDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfRefundReason/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfRefundReasonDto>,
  /**
   * No description
   * @name PUT /admin/mallConfRefundReason/update
   * @summary 售后原因修改
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfRefundReason/update_PUT": (data: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 返回参数：mall_logo 商城logo service_phone_number  客服电话 email 邮箱 address 地址
   * @name GET /admin/mallConfigController/aboutUs
   * @summary  店铺-基本设置-关于我们
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/aboutUs_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/aboutUs`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * @description 返回参数：mall_logo 商城logo service_phone_number  客服电话 email 邮箱 address 地址
   * @name GET /admin/mallConfigController/aboutUsToShop
   * @summary  店铺-基本设置-关于我们(给店铺用)
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/aboutUsToShop_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/aboutUsToShop`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/activityNavigation
   * @summary 装修设置-活动导航 （通用设置接口可修改）             返回参数：is_open,  是否启动 true / falseimg_url ： 导航图片url_link ： 跳转连接
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/activityNavigation_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/base/activityNavigation`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * @description 入驻协议 enter_agreement 入驻协议
   * @name GET /admin/mallConfigController/base/enterAgreement/config
   * @summary 平台端-入驻协议
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/enterAgreement/config_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/base/enterAgreement/config`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * @description 平台端-会员管理-会员卡等级设置-基本信息配置 card_number_strategy 卡号设置 0: 系統生成 1：手机号作为卡号 open_condition       开卡条件 0: 成为客户 open_card_way        开卡方式 0: 自动发卡
   * @name GET /admin/mallConfigController/base/memberCard/config
   * @summary 平台端-会员管理-会员卡等级设置-基本信息配置
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/memberCard/config_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/base/memberCard/config`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * @description 平台端-会员管理-会员卡等级-说明配置
   * @name GET /admin/mallConfigController/base/memberCard/remark/config
   * @summary 平台端-会员管理-会员卡等级-说明配置
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/memberCard/remark/config_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/base/memberCard/remark/config`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/order
   * @summary 基础设置-订单设置、售后流程、评论设置查询              返回参数：order_payment_waiting_time_hours, X小时未支付自动关闭订单            返回参数：order_receiving_waiting_time_day,发货后自动收货时间            返回参数：order_receiving_close,已收货自动关闭时间            返回参数：refund_only_money_auto_agree_day, *买家发起仅退款申请：
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/order_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/base/order`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/themeConfig
   * @summary 基础设置-平台主题 （通用设置接口可修改）             返回参数：theme_config, 默认主题肤色   1 / 2 / 3 / 4
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/base/themeConfig_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/base/themeConfig`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/getStoreQRCode
   * @summary 获取店铺二维码
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/getStoreQRCode_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/getStoreQRCode`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 返回参数：goods_img_url 图片地址 goods_original_price 原始价格 goods_selling_price 售价 goods_goods_title 商品标题
   * @name GET /admin/mallConfigController/goods/bill
   * @summary 店铺设置-商品管理-商品海报设置
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/goods/bill_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/goods/bill`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name POST /admin/mallConfigController/insertAndUpdate
   * @summary 通用设置保存与修改 {key : value} 形式
   * @tags 商城通用配置
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/insertAndUpdate_POST": (data: Record<string, string>, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/insertAndUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/clauseContentToShop
   * @summary 服务条款
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/clauseContentToShop_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/clauseContentToShop`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/goodsPublishRule
   * @summary 商品发布规定
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/goodsPublishRule_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/goodsPublishRule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/integralRule
   * @summary 积分规则
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/integralRule_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/integralRule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/mallConsumerExcel
   * @summary 平台自费一览表
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/mallConsumerExcel_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/mallConsumerExcel`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/mallServiceProtocol
   * @summary 平台服务协议
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/mallServiceProtocol_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/mallServiceProtocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/mallStatementRule
   * @summary 电商结算规则
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/mallStatementRule_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/mallStatementRule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/marginManageRule
   * @summary 保证金管理规则
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/marginManageRule_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/marginManageRule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/onLineServiceProtocol
   * @summary 在线服务协议
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/onLineServiceProtocol_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/onLineServiceProtocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
 * No description
 * @name GET /admin/mallConfigController/mall/protocol
 * @summary 协议管理 （通用设置接口可修改） 返回参数：clause_content,  服务条款 
user_protocol ： 用户协议内容
integral_rule ： 积分规则
security_protocol  保密协议内容 
mall_statement_rule 电商结算规则
seven_no_reason_return 商家七天无理由退换货规范
goods_publish_rule 商品发布规定
margin_manage_rule 保证金管理规则
mall_service_protocol 平
 * @tags 商城通用配置
 * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
 */
  "/admin/mallConfigController/mall/protocol_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/protocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/securityProtocol
   * @summary 保密协议内容
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/securityProtocol_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/securityProtocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/settledCategoryExcel
   * @summary 入驻各类目资料需求表
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/settledCategoryExcel_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/settledCategoryExcel`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/settledProtocol
   * @summary 入驻协议
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/settledProtocol_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/settledProtocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/sevenNoReasonReturn
   * @summary 商家七天无理由退换货
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/sevenNoReasonReturn_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/sevenNoReasonReturn`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/storeIllegalIntegrationRule
   * @summary B+商城平台服务协议
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/storeIllegalIntegrationRule_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/storeIllegalIntegrationRule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/mall/userProtocol
   * @summary 用户协议内容
   * @tags 商城通用配置
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/mall/userProtocol_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/mall/userProtocol`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 返回参数：img_url 图片地址 store_level 店铺星级 store_name 店铺名称 store_logo 店铺logo
   * @name GET /admin/mallConfigController/store/bill
   * @summary 店铺设置-商品管理-店铺海报设置
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/store/bill_GET": (
    query: AdminMallConfigControllerStoreBillGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/store/bill`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/store/orderConfig
   * @summary 店铺设置-订单设置 （通用设置接口可修改） 返回参数：inventory_deductions_type,  库存扣减方式 1 下单扣减 / 2 支付扣减 under_stock_type ： 库存不足处理方式 1 允许扣为负数 2 订单自动关闭并退款  3 缺货商品自动售后并退款
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/store/orderConfig_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/store/orderConfig`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * @description 返回参数：store_shipping_date,  自定义配送时间 0 关闭 1开启 store_freight_strategy ： 运费组合策略 0 叠加 1 最低 2最高 3组合
   * @name GET /admin/mallConfigController/store/storeShipping
   * @summary  店铺-配送设置 （通用设置接口可修改）
   * @tags 商城通用配置
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallConfigController/store/storeShipping_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallConfigController/store/storeShipping`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name POST /admin/mallCouponTemplate/add
   * @summary 优惠劵新增
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/add_POST": (data: MallCouponTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallCouponTemplate/delete
   * @summary 优惠劵作废
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallCouponTemplate/delete_DELETE": (
    query: AdminMallCouponTemplateDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallCouponTemplate/detail
   * @summary 详情
   * @tags 优惠劵
   * @response `200` `JsonResultMallCouponTemplateVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/detail_GET": (
    query: AdminMallCouponTemplateDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallCouponTemplateVo>,
  /**
   * No description
   * @name GET /admin/mallCouponTemplate/queryList
   * @summary 分页查询全部
   * @tags 优惠劵
   * @response `200` `JsonResultPageInfoMallCouponTemplateVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/queryList_GET": (
    query: AdminMallCouponTemplateQueryListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/queryList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallCouponTemplateVo>,
  /**
   * No description
   * @name PUT /admin/mallCouponTemplate/update
   * @summary 优惠劵修改
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/update_PUT": (data: MallCouponTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallRegion/treeList
   * @summary 省市区树结构查询
   * @tags 省市区查询
   * @response `200` `JsonResultListMallRegionVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallRegion/treeList_GET": (query: AdminMallRegionTreeListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallRegion/treeList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallRegionVo>,
  /**
   * No description
   * @name POST /admin/mallStore/notify/add
   * @summary 通知新增
   * @tags 店铺通知
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/add_POST": (data: MallNotifyMsgAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallStore/notify/allMsg
   * @summary 店铺所有消息
   * @tags 店铺通知
   * @response `200` `JsonResultListMallNotifyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/allMsg_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/allMsg`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallNotifyVo>,
  /**
   * No description
   * @name DELETE /admin/mallStore/notify/delete
   * @summary 删除通知
   * @tags 店铺通知
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallStore/notify/delete_DELETE": (
    query: AdminMallStoreNotifyDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallStore/notify/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallStore/notify/queryList
   * @summary 分页查询全部
   * @tags 店铺通知
   * @response `200` `JsonResultPageInfoMallNotifyMsgVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/queryList_POST": (data: MallNotifyMsgQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/queryList`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallNotifyMsgVo>,
  /**
   * No description
   * @name GET /admin/mallStore/notify/queryList
   * @summary 分页查询全部
   * @tags 店铺通知
   * @response `200` `JsonResultPageInfoMallNotifyMsgVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/queryList_GET": (
    query: AdminMallStoreNotifyQueryListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallStore/notify/queryList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallNotifyMsgVo>,
  /**
   * No description
   * @name GET /admin/mallStore/notify/read
   * @summary 读取消息（标记为已读）,不传id则读取全部消息
   * @tags 店铺通知
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/read_GET": (query: AdminMallStoreNotifyReadGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/read`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallStore/notify/unReadNum
   * @summary 店铺未读消息数
   * @tags 店铺通知
   * @response `200` `JsonResultInt` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/unReadNum_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/unReadNum`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name POST /admin/mallStore/notify/uploadStore
   * @summary 上传店铺名单 返回店铺编码数组
   * @tags 店铺通知
   * @response `200` `JsonResultListMallStoreUploadPoiDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/uploadStore_POST": (data: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/uploadStore`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreUploadPoiDto>,
  /**
   * No description
   * @name DELETE /admin/mallconfthrowlink
   * @summary 根据id删除
   * @tags 投放链接
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallconfthrowlink_DELETE": (query: AdminMallconfthrowlinkDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallconfthrowlink`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallconfthrowlink/add
   * @summary 新增投放链接
   * @tags 投放链接
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallconfthrowlink/add_POST": (data: MallConfThrowLinkParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallconfthrowlink/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallconfthrowlink/listPage
   * @summary 查询投放链接信息
   * @tags 投放链接
   * @response `200` `JsonResultPageInfoMallConfThrowLinkVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallconfthrowlink/listPage_GET": (
    query: AdminMallconfthrowlinkListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallconfthrowlink/listPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfThrowLinkVo>,
  /**
   * No description
   * @name GET /admin/mallconfthrowlink/queryAll
   * @summary 查询所有
   * @tags 投放链接
   * @response `200` `JsonResultListMallConfThrowLinkVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallconfthrowlink/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallconfthrowlink/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfThrowLinkVo>,
  /**
   * No description
   * @name PUT /admin/mallstore
   * @summary 店铺端-简单店铺信息编辑
   * @tags 店铺
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore_PUT": (data: MallStoreDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallstore/apply
   * @summary 店铺端-店铺提交资料,申请开店
   * @tags 店铺
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/apply_POST": (data: MallStoreApplyParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/apply`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallstore/batch
   * @summary 批量根据店铺编号 获得简单店铺对象
   * @tags 店铺
   * @response `200` `JsonResultListMallStoreDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/batch_GET": (query: AdminMallstoreBatchGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/batch`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreDto>,
  /**
   * No description
   * @name PUT /admin/mallstore/bind/privilege
   * @summary A平台-B+权益绑定店铺
   * @tags 店铺
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/bind/privilege_PUT": (query: AdminMallstoreBindPrivilegePutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/bind/privilege`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstore/company
   * @summary 店铺端-简单企业信息
   * @tags 店铺
   * @response `200` `JsonResultMallStoreCompanyInfoDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/company_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/company`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreCompanyInfoDto>,
  /**
   * No description
   * @name PUT /admin/mallstore/cutStoreStatus
   * @summary A店铺端-营业状态切换
   * @tags 店铺
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/cutStoreStatus_PUT": (data: MallStoreBusinessCutParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/cutStoreStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstore/deblock/{storeNo}
   * @summary A平台-手动解封店铺
   * @tags 店铺
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/deblock/{storeNo}_POST": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/deblock/${storeNo}`,
      method: "POST",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstore/detail/{storeNo}
   * @summary A平台-店铺详情
   * @tags 店铺
   * @response `200` `JsonResultApplyAuditCategoryDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminMallstoreDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultApplyAuditCategoryDetailVo>,
  /**
   * No description
   * @name GET /admin/mallstore/export
   * @summary 店铺导出
   * @tags 店铺
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/export_GET": (query: AdminMallstoreExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/mallstore/forbid
   * @summary A平台-封禁店铺
   * @tags 店铺
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/forbid_POST": (data: MallStoreForbidParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/forbid`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstore/freeAudit
   * @summary A平台-免审状态切换
   * @tags 店铺
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/freeAudit_POST": (data: MallStoreFreeAuditParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/freeAudit`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallstore/info
   * @summary 店铺端-简单店铺信息
   * @tags 店铺
   * @response `200` `JsonResultMallStoreDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/info_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/info`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreDto>,
  /**
   * No description
   * @name GET /admin/mallstore/page
   * @summary A平台-店铺列表分页
   * @tags 店铺
   * @response `200` `JsonResultPageInfoMallStorePageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/page_GET": (query: AdminMallstorePageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallStorePageVo>,
  /**
   * No description
   * @name GET /admin/mallstore/payStatusDetail
   * @summary A平台-商家管理-店铺状态
   * @tags 店铺
   * @response `200` `JsonResultStorePayStatusDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/payStatusDetail_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/payStatusDetail`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultStorePayStatusDetailVo>,
  /**
   * No description
   * @name GET /admin/mallstore/queryAll
   * @summary A平台-店铺列表
   * @tags 店铺
   * @response `200` `JsonResultListMallStorePageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/queryAll_GET": (query: AdminMallstoreQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStorePageVo>,
  /**
   * No description
   * @name GET /admin/mallstore/valid
   * @summary 店铺端-当前登录账户的店铺是否可用
   * @tags 店铺
   * @response `200` `JsonResultBoolean` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/valid_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/valid`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/apply/notPassRecord
   * @summary 驳回申请数据返显
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultMallStoreApplyNotPassVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/apply/notPassRecord_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/audit/apply/notPassRecord`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreApplyNotPassVo>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/brandApply/detail/{storeNo}
   * @summary A平台-店铺品牌审核详情
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultBrandApplyAuditDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/brandApply/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminMallstoreAuditBrandApplyDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/audit/brandApply/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBrandApplyAuditDetailVo>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/detail/{storeNo}
   * @summary A平台-店铺入驻审核详情
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultApplyAuditCategoryDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminMallstoreAuditDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/audit/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultApplyAuditCategoryDetailVo>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/enter/agreement
   * @summary 店铺端-入驻协议
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/enter/agreement_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/audit/enter/agreement`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/enter/page
   * @summary A平台-入驻审核/续约审核列表
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultPageInfoEnterAuditPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/enter/page_GET": (
    query: AdminMallstoreAuditEnterPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/audit/enter/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoEnterAuditPageVo>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/enter/schedule
   * @summary 当前入驻审核状态
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultMallStoreEnterAuditScheduleVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/enter/schedule_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/audit/enter/schedule`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreEnterAuditScheduleVo>,
  /**
   * No description
   * @name POST /admin/mallstore/audit/pass
   * @summary A平台-店铺入驻审核通过/驳回
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/pass_POST": (data: EnterAuditPassAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/audit/pass`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallstore/audit/renewal/detail/{storeNo}
   * @summary A平台-店铺续约审核详情
   * @tags 店铺入驻审核相关
   * @response `200` `JsonResultApplyAuditCategoryDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/audit/renewal/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminMallstoreAuditRenewalDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/audit/renewal/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultApplyAuditCategoryDetailVo>,
  /**
   * No description
   * @name POST /admin/mallstore/privilege/add
   * @summary A平台-B+权益新增
   * @tags B+权益表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/privilege/add_POST": (data: MallStorePrivilegeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/privilege/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstore/privilege/page
   * @summary A平台-B+权益表分页查询
   * @tags B+权益表
   * @response `200` `JsonResultObject` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/privilege/page_GET": (query: AdminMallstorePrivilegePageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/privilege/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/mallstore/privilege/queryAll
   * @summary A平台-权益表列表全部查询
   * @tags B+权益表
   * @response `200` `JsonResultListMallStorePrivilegeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/privilege/queryAll_GET": (
    query: AdminMallstorePrivilegeQueryAllGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/privilege/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStorePrivilegeDto>,
  /**
   * No description
   * @name PUT /admin/mallstore/privilege/update
   * @summary A平台-B+权益编辑
   * @tags B+权益表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/privilege/update_PUT": (data: MallStorePrivilegeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/privilege/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallstore/privilege/{id}
   * @summary A平台-B+权益删除
   * @tags B+权益表
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/mallstore/privilege/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/privilege/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallstoreclose/apply
   * @summary 店铺端-关店申请
   * @tags 关店审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/apply_POST": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoreclose/apply`,
      method: "POST",
      params,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstoreclose/apply/pass/batch
   * @summary A平台-申请关店批量通过
   * @tags 关店审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/apply/pass/batch_POST": (
    query: AdminMallstorecloseApplyPassBatchPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstoreclose/apply/pass/batch`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstoreclose/apply/pass/{closeApplyId}
   * @summary A平台-申请关店通过
   * @tags 关店审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/apply/pass/{closeApplyId}_POST": (
    closeApplyId: number,
    data: StoreCloseParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstoreclose/apply/pass/${closeApplyId}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstoreclose/apply/refuse/batch
   * @summary A平台-申请批量关店驳回
   * @tags 关店审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/apply/refuse/batch_POST": (
    query: AdminMallstorecloseApplyRefuseBatchPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstoreclose/apply/refuse/batch`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/mallstoreclose/apply/refuse/{closeApplyId}
   * @summary A平台-申请关店驳回
   * @tags 关店审核相关
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/apply/refuse/{closeApplyId}_POST": (
    { closeApplyId, ...query }: AdminMallstorecloseApplyRefuseCloseApplyIdPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstoreclose/apply/refuse/${closeApplyId}`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallstoreclose/detail/{storeNo}
   * @summary 店铺端-关店审核详情
   * @tags 关店审核相关
   * @response `200` `JsonResultApplyAuditCategoryDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/detail/{storeNo}_GET": (
    { storeNo, ...query }: AdminMallstorecloseDetailStoreNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstoreclose/detail/${storeNo}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultApplyAuditCategoryDetailVo>,
  /**
   * No description
   * @name GET /admin/mallstoreclose/page
   * @summary A平台-关店审核列表
   * @tags 关店审核相关
   * @response `200` `JsonResultPageInfoCloseAuditPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/page_GET": (query: AdminMallstoreclosePageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoreclose/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCloseAuditPageVo>,
  /**
   * No description
   * @name GET /admin/mallstoreclose/schedule/{storeNo}
   * @summary 店铺端-申请关店状态查询
   * @tags 关店审核相关
   * @response `200` `JsonResultMallStoreCloseAuditDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoreclose/schedule/{storeNo}_GET": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoreclose/schedule/${storeNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMallStoreCloseAuditDto>,
  /**
   * No description
   * @name POST /admin/mallstoretype/add
   * @summary A平台-店铺类型新增
   * @tags 店铺类型维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoretype/add_POST": (data: MallStoreTypeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoretype/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstoretype/page
   * @summary A平台-店铺类型分页
   * @tags 店铺类型维护
   * @response `200` `JsonResultPageInfoMallStoreTypeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoretype/page_GET": (query: AdminMallstoretypePageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoretype/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallStoreTypeDto>,
  /**
   * No description
   * @name GET /admin/mallstoretype/queryAll
   * @summary A平台-店铺类型列表
   * @tags 店铺类型维护
   * @response `200` `JsonResultListMallStoreTypeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoretype/queryAll_GET": (query: AdminMallstoretypeQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoretype/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreTypeDto>,
  /**
   * No description
   * @name PUT /admin/mallstoretype/update
   * @summary A平台-店铺类型编辑
   * @tags 店铺类型维护
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstoretype/update_PUT": (data: MallStoreTypeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstoretype/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/margin/merchant/bond
   * @summary 商家管理-平台保证金账户-金额信息
   * @tags A平台-商家管理-保证金相关
   * @response `200` `JsonResultPlatformBondAdminVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/margin/merchant/bond_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/margin/merchant/bond`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPlatformBondAdminVo>,
  /**
   * No description
   * @name GET /admin/margin/merchant/store
   * @summary 商家管理-店铺相关保证金账户
   * @tags A平台-商家管理-保证金相关
   * @response `200` `JsonResultPageInfoMallStorePageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/margin/merchant/store_GET": (query: AdminMarginMerchantStoreGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/margin/merchant/store`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallStorePageVo>,
  /**
   * No description
   * @name GET /admin/margin/merchant/store/export
   * @summary 商家管理-店铺保证金列表导出
   * @tags A平台-商家管理-保证金相关
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/margin/merchant/store/export_GET": (
    query: AdminMarginMerchantStoreExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/margin/merchant/store/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/marketing/addTurnTable
   * @summary 抽奖活动第4步，创建轮盘
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/addTurnTable_POST": (data: MarketingTurnTableParam[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/marketing/addTurnTable`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/marketing/closeDrawActivity
   * @summary 结束活动
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/closeDrawActivity_GET": (
    query: AdminMarketingCloseDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/closeDrawActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/marketing/delDrawActivity
   * @summary 删除活动
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/delDrawActivity_GET": (
    query: AdminMarketingDelDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/delDrawActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description drawStatus: 0是下架，1是上架,activityNo:活动编号
   * @name GET /admin/marketing/drawActivityStatus
   * @summary 活动上下架
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/drawActivityStatus_GET": (
    query: AdminMarketingDrawActivityStatusGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/drawActivityStatus`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description activityNo:抽奖活动编号，必传 userNo:用户编号，不是必须 regPhone:用户app绑定手机号，不是必须 drawStatus:抽奖结果，0未中奖，1中奖，不是必须
   * @name GET /admin/marketing/exportDrawActivity
   * @summary 抽奖活动参与用户导出
   * @tags 抽奖活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/exportDrawActivity_GET": (
    query: AdminMarketingExportDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/exportDrawActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/marketing/queryAllDrawActivity
   * @summary 查询当前店铺的所有抽奖活动
   * @tags 抽奖活动
   * @response `200` `JsonResultPageInfoMarketingStoreActivityVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryAllDrawActivity_GET": (
    query: AdminMarketingQueryAllDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryAllDrawActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingStoreActivityVo>,
  /**
   * @description 入参说明 activityNo:活动编号 userNo:用户编号 regPhone:注册app关联的手机号 drawStatus:0未中奖，1中奖
   * @name GET /admin/marketing/queryJoinDrawActivity
   * @summary 参与用户查询
   * @tags 抽奖活动
   * @response `200` `JsonResultPageInfoDrawActivityResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryJoinDrawActivity_GET": (
    query: AdminMarketingQueryJoinDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryJoinDrawActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoDrawActivityResultVo>,
  /**
   * No description
   * @name GET /admin/marketing/queryRunningAndSelfActivity
   * @summary 第三步要关联活动的接口
   * @tags 抽奖活动
   * @response `200` `JsonResultPageInfoMarketingActivityVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryRunningAndSelfActivity_GET": (
    query: AdminMarketingQueryRunningAndSelfActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryRunningAndSelfActivity`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityVo>,
  /**
   * No description
   * @name GET /admin/marketing/queryStepFourParam
   * @summary 查询第四步数据
   * @tags 抽奖活动
   * @response `200` `JsonResultListMarketingTurnTableVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryStepFourParam_GET": (
    query: AdminMarketingQueryStepFourParamGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryStepFourParam`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMarketingTurnTableVo>,
  /**
   * No description
   * @name GET /admin/marketing/queryStepOneParam
   * @summary 查询第一步数据
   * @tags 抽奖活动
   * @response `200` `JsonResultMarketingActivityVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryStepOneParam_GET": (
    query: AdminMarketingQueryStepOneParamGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryStepOneParam`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityVo>,
  /**
   * No description
   * @name GET /admin/marketing/queryStepThreeParam
   * @summary 查询第三步数据
   * @tags 抽奖活动
   * @response `200` `JsonResultListMarketingPrizePoolVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryStepThreeParam_GET": (
    query: AdminMarketingQueryStepThreeParamGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryStepThreeParam`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMarketingPrizePoolVo>,
  /**
   * No description
   * @name GET /admin/marketing/queryStepTwoParam
   * @summary 查询第二步数据
   * @tags 抽奖活动
   * @response `200` `JsonResultMarketingActivityVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/queryStepTwoParam_GET": (
    query: AdminMarketingQueryStepTwoParamGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/queryStepTwoParam`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityVo>,
  /**
   * @description 返回参数说明 activityNo: 活动编号
   * @name POST /admin/marketing/save
   * @summary 添加抽奖活动第一步
   * @tags 抽奖活动
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/save_POST": (data: MarketingDrawStep1Param, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/marketing/save`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/marketing/saveDrawStep3
   * @summary 新增抽奖活动第三步
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/saveDrawStep3_POST": (data: MarketingPrizePoolParam[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/marketing/saveDrawStep3`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/marketing/update
   * @summary 添加抽奖活动第二步
   * @tags 抽奖活动
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/update_PUT": (data: MarketingDrawStep2Param, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/marketing/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/marketing/updateStep1
   * @summary 更新抽奖活动第一步
   * @tags 抽奖活动
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/updateStep1_POST": (data: MarketingDrawStep1Param, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/marketing/updateStep1`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/material/temp/form
   * @summary 上传临时素材 返回media_id 根据form
   * @tags 微信素材管理
   * @response `200` `JsonResultWxMediaUploadResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/material/temp/form_PUT": (query: AdminMaterialTempFormPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/material/temp/form`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultWxMediaUploadResult>,
  /**
   * No description
   * @name PUT /admin/material/temp/url
   * @summary 上传临时素材 返回media_id 根据URL
   * @tags 微信素材管理
   * @response `200` `JsonResultWxMediaUploadResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/material/temp/url_PUT": (query: AdminMaterialTempUrlPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/material/temp/url`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultWxMediaUploadResult>,
  /**
   * @description 上传阿里云，返回下载链接
   * @name GET /admin/mem/poi/ali/exportExample
   * @summary 案例:上传阿里云，返回下载链接
   * @tags A-导入导出 以及相关下载列表和案例
   * @response `200` `string` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mem/poi/ali/exportExample_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mem/poi/ali/exportExample`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<string>,
  /**
   * @description 导出直接下载文件  适用与小量数据
   * @name GET /admin/mem/poi/exportExample
   * @summary 案例:导出直接下载文件  适用与小量数据
   * @tags A-导入导出 以及相关下载列表和案例
   * @response `200` `OperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mem/poi/exportExample_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mem/poi/exportExample`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * @description 导出案例: 导出: 统一是Post接口,参数一律为json, 本次比如要导出
   * @name POST /admin/mem/poi/xxJob/export/example
   * @summary 案例: 导出
   * @tags A-导入导出 以及相关下载列表和案例
   * @response `200` `OperationLog` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mem/poi/xxJob/export/example_POST": (data: MemberInfoPageQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mem/poi/xxJob/export/example`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * @description 导入案例: 导出: 统一是Post接口,参数一律为json, 本次比如要导入
   * @name POST /admin/mem/poi/xxJob/import/example
   * @summary 案例: 导入
   * @tags A-导入导出 以及相关下载列表和案例
   * @response `200` `OperationLog` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mem/poi/xxJob/import/example_POST": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mem/poi/xxJob/import/example`,
      method: "POST",
      params,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * No description
   * @name GET /admin/memCoupon/page
   * @summary 会员优惠劵列表分页查询
   * @tags 会员优惠劵
   * @response `200` `JsonResultPageInfoMemCouponVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memCoupon/page_GET": (query: AdminMemCouponPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memCoupon/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemCouponVo>,
  /**
   * No description
   * @name GET /admin/memCoupon/query/rexport
   * @summary 条件筛选导出优惠劵信息(直接返回下载地址)
   * @tags 会员优惠劵
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memCoupon/query/rexport_GET": (query: AdminMemCouponQueryRexportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memCoupon/query/rexport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/memLevel/batch
   * @summary 批量更新或新增等级
   * @tags 会员等级
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memLevel/batch_POST": (data: MemLevelDto[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memLevel/batch`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memLevel/bind/interests/{levelId}
   * @summary 绑定/更新权益
   * @tags 会员等级
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memLevel/bind/interests/{levelId}_POST": (
    levelId: number,
    data: DeleteBatchUsingPOSTIds,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memLevel/bind/interests/${levelId}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * @description 关于【会员卡基础信息】 在商城通用配置目录下 平台端-会员管理-会员卡等级设置-基本信息配置   查询接口 通用设置保存与修改  来做修改
   * @name GET /admin/memLevel/page
   * @summary 会员等级分页列表查询
   * @tags 会员等级
   * @response `200` `JsonResultPageInfoMemLevelPageDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memLevel/page_GET": (query: AdminMemLevelPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memLevel/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemLevelPageDto>,
  /**
   * @description 关于【会员卡基础信息和说明配置】 都在商城通用配置目录下 平台端-会员管理-会员卡等级设置-基本信息配置   查询接口 通用设置保存与修改  来做修改
   * @name GET /admin/memLevel/queryAll
   * @summary 会员等级列表查询
   * @tags 会员等级
   * @response `200` `JsonResultListMemLevelPageDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memLevel/queryAll_GET": (query: AdminMemLevelQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memLevel/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemLevelPageDto>,
  /**
   * No description
   * @name PUT /admin/memLevel/status/{levelId}
   * @summary A平台会员等级-启用/禁用
   * @tags 会员等级
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memLevel/status/{levelId}_PUT": (
    { levelId, ...query }: AdminMemLevelStatusLevelIdPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memLevel/status/${levelId}`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/membaseinfo/account/page
   * @summary A平台-会员卡信息-分页
   * @tags 客户信息
   * @response `200` `JsonResultPageInfoMemAccountPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/account/page_GET": (query: AdminMembaseinfoAccountPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/account/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemAccountPageVo>,
  /**
   * No description
   * @name GET /admin/membaseinfo/account/query/rexport
   * @summary 条件筛选导出会员卡信息(直接返回下载地址)
   * @tags 客户信息
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/account/query/rexport_GET": (
    query: AdminMembaseinfoAccountQueryRexportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/membaseinfo/account/query/rexport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/membaseinfo/account/queryAll
   * @summary A平台-会员卡信息-列表
   * @tags 客户信息
   * @response `200` `JsonResultListMemAccountPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/account/queryAll_POST": (data: MemberAccountPageQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/account/queryAll`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMemAccountPageVo>,
  /**
   * No description
   * @name GET /admin/membaseinfo/account/queryAll/{memberNo}
   * @summary A平台-会员卡信息-基础信息和成长值流水
   * @tags 客户信息
   * @response `200` `JsonResultMemAccountDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/account/queryAll/{memberNo}_GET": (memberNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/account/queryAll/${memberNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMemAccountDetailVo>,
  /**
   * No description
   * @name PUT /admin/membaseinfo/account/status/{memberNo}
   * @summary A平台会员管理-会员卡启用/禁用
   * @tags 客户信息
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/account/status/{memberNo}_PUT": (
    { memberNo, ...query }: AdminMembaseinfoAccountStatusMemberNoPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/membaseinfo/account/status/${memberNo}`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/membaseinfo/detail/{memberNo}
   * @summary A平台-会员信息-用户详情 包含订单|积分等
   * @tags 客户信息
   * @response `200` `JsonResultMemberDetailAdminVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/detail/{memberNo}_GET": (memberNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/detail/${memberNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMemberDetailAdminVo>,
  /**
   * No description
   * @name PUT /admin/membaseinfo/growth
   * @summary A平台-会员信息-平台直接修改用户成长值
   * @tags 客户信息
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/growth_PUT": (data: MemGrowthEditParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/growth`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/membaseinfo/integral
   * @summary A平台-会员信息-直接修改用户积分
   * @tags 客户信息
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/integral_PUT": (data: MemPointEditParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/integral`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/membaseinfo/page
   * @summary A平台会员管理-会员信息-分页查询
   * @tags 客户信息
   * @response `200` `JsonResultPageInfoMemberInfoPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/page_GET": (query: AdminMembaseinfoPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberInfoPageVo>,
  /**
   * No description
   * @name GET /admin/membaseinfo/query/rexport
   * @summary 条件筛选导出客户信息(直接返回下载地址)
   * @tags 客户信息
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/query/rexport_GET": (query: AdminMembaseinfoQueryRexportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/query/rexport`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/membaseinfo/queryAll
   * @summary A平台会员管理-会员信息-全部查询
   * @tags 客户信息
   * @response `200` `JsonResultListMemberInfoPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/queryAll_GET": (data: MemberInfoPageQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/queryAll`,
      method: "GET",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListMemberInfoPageVo>,
  /**
   * No description
   * @name GET /admin/membaseinfo/queryAll/esay/export
   * @summary 条件筛选导出客户信息(简单少量数据)
   * @tags 客户信息
   * @response `200` `OperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/queryAll/esay/export_GET": (
    query: AdminMembaseinfoQueryAllEsayExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/membaseinfo/queryAll/esay/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * No description
   * @name GET /admin/membaseinfo/queryAll/export
   * @summary 条件筛选导出客户信息(大量)
   * @tags 客户信息
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/queryAll/export_GET": (
    query: AdminMembaseinfoQueryAllExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/membaseinfo/queryAll/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/membaseinfo/update
   * @summary A平台-会员信息-用户基础信息编辑
   * @tags 客户信息
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/update_PUT": (data: MemBaseInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/membaseinfo/updateBindLabels/{memberNo}
   * @summary A平台-用户详情-更新用户和用户标签的绑定关系
   * @tags 客户信息
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/updateBindLabels/{memberNo}_POST": (
    memberNo: string,
    data: MemLabelUpdateBindParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/membaseinfo/updateBindLabels/${memberNo}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/membaseinfo/{memberNo}
   * @summary A平台-会员信息-用户基础信息
   * @tags 客户信息
   * @response `200` `JsonResultMemBaseInfoDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/membaseinfo/{memberNo}_GET": (memberNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/membaseinfo/${memberNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMemBaseInfoDto>,
  /**
   * No description
   * @name POST /admin/memlabel/add
   * @summary A平台-标签库-标签新增
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/add_POST": (data: MemLabelDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memlabel/batch
   * @summary 用户标签批量删除 并且清除相关用户的标签
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/batch_POST": (data: DeleteBatchUsingPOSTIds, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/batch`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memlabel/bind/{id}
   * @summary 用户批量绑定
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/bind/{id}_POST": (id: number, data: BindMemberUsingPOSTMembernos, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/bind/${id}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/memlabel/cancelBind
   * @summary 删除用户和用户标签的绑定关系
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/memlabel/cancelBind_DELETE": (query: AdminMemlabelCancelBindDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/cancelBind`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memlabel/incrementBindMember/{id}
   * @summary 用户增量绑定 【只能做增量】
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/incrementBindMember/{id}_POST": (
    id: number,
    data: BindMemberUsingPOSTMembernos,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memlabel/incrementBindMember/${id}`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * @description 【查看用户】时传LabelId 为了后面用于移除 【选择用户】时不传LabelId 为了选择一些新的用户 关于前端【选择用户】根据标签反选,可以根据labelIds.indexOf(labelId) 来判断。
   * @name GET /admin/memlabel/memberBaseInfo/page
   * @summary A平台-标签库-会员信息分页查询
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultPageInfoMemberInfoPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/memberBaseInfo/page_GET": (
    query: AdminMemlabelMemberBaseInfoPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memlabel/memberBaseInfo/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberInfoPageVo>,
  /**
   * @description 根据标签ID 获取所有【未选中】的用户
   * @name GET /admin/memlabel/not/{labelId}
   * @summary 根据标签ID 获取所有【未选中】的用户 主要用于增量绑定【选中新增，标签页面只能增量】
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultPageInfoMemberInfoPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/not/{labelId}_GET": (
    { labelId, ...query }: AdminMemlabelNotLabelIdGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memlabel/not/${labelId}`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberInfoPageVo>,
  /**
   * No description
   * @name GET /admin/memlabel/page
   * @summary A平台-标签库-分页查询
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultPageInfoMemLabelDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/page_GET": (query: AdminMemlabelPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemLabelDto>,
  /**
   * No description
   * @name GET /admin/memlabel/queryAll
   * @summary A平台-标签库-列表查询
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultListMemLabelDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/queryAll_GET": (query: AdminMemlabelQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemLabelDto>,
  /**
   * No description
   * @name PUT /admin/memlabel/update
   * @summary A平台-标签库-标签编辑
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlabel/update_PUT": (data: MemLabelDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/memlabel/{id}
   * @summary 根据Id删除
   * @tags 平台端-标签管理-标签库
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/memlabel/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlabel/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memlevelinterests/add
   * @summary A平台-会员信息-权益新增
   * @tags 会员权益
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlevelinterests/add_POST": (data: MemLevelInterestsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlevelinterests/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/memlevelinterests/page
   * @summary A平台-会员信息-权益列表分页查询
   * @tags 会员权益
   * @response `200` `JsonResultPageInfoMemLevelInterestsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlevelinterests/page_GET": (query: AdminMemlevelinterestsPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlevelinterests/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemLevelInterestsDto>,
  /**
   * No description
   * @name GET /admin/memlevelinterests/queryAll
   * @summary A平台-会员信息-权益列表查询
   * @tags 会员权益
   * @response `200` `JsonResultListMemLevelInterestsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlevelinterests/queryAll_GET": (
    query: AdminMemlevelinterestsQueryAllGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memlevelinterests/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemLevelInterestsDto>,
  /**
   * No description
   * @name PUT /admin/memlevelinterests/update
   * @summary A平台-会员信息-权益编辑
   * @tags 会员权益
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memlevelinterests/update_PUT": (data: MemLevelInterestsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlevelinterests/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/memlevelinterests/{id}
   * @summary A平台-会员信息-权益删除根据ID
   * @tags 会员权益
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/memlevelinterests/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memlevelinterests/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/memsignrule/add
   * @summary 签到规则保存
   * @tags 签到规则
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memsignrule/add_POST": (data: MemSignRuleDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memsignrule/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/memsignrule/info
   * @summary 签到规则信息
   * @tags 签到规则
   * @response `200` `JsonResultMemSignRuleDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memsignrule/info_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memsignrule/info`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMemSignRuleDto>,
  /**
   * No description
   * @name PUT /admin/memsignrule/update
   * @summary 签到规则編輯
   * @tags 签到规则
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memsignrule/update_PUT": (data: MemSignRuleDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memsignrule/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/memsignrule/using/{id}
   * @summary 是否启用 status （0：否，1：是）
   * @tags 签到规则
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memsignrule/using/{id}_PUT": (
    { id, ...query }: AdminMemsignruleUsingIdPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memsignrule/using/${id}`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/oss/info
   * @summary 阿里云存储信息
   * @tags 阿里云存储
   * @response `200` `JsonResultMapStringObject` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/oss/info_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/oss/info`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMapStringObject>,
  /**
   * No description
   * @name GET /admin/pay/account/marginCount
   * @summary 保证金账户统计
   * @tags P-支付-店铺账户管理
   * @response `200` `JsonResultMarginAccountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/account/marginCount_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/account/marginCount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMarginAccountVo>,
  /**
   * No description
   * @name POST /admin/pay/account/queryMargin
   * @summary 保证金账户查询
   * @tags P-支付-店铺账户管理
   * @response `200` `JsonResultListPayAccountVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/account/queryMargin_POST": (data: PayAccountMarginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/account/queryMargin`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListPayAccountVo>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getAccountCapital
   * @summary 账户资金查询
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResultPayAccountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getAccountCapital_GET": (
    query: AdminPayAccountInfoGetAccountCapitalGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getAccountCapital`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPayAccountVo>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getMarginAccountInfo
   * @summary 店铺保证金账户详情
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResultPayAccountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getMarginAccountInfo_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getMarginAccountInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayAccountVo>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getMasterAccountInfo
   * @summary 店铺总账户详情
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResultPayAccountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getMasterAccountInfo_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getMasterAccountInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayAccountVo>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getSubsidyAccountInfo
   * @summary 店铺营销账户详情
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResultPayAccountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getSubsidyAccountInfo_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getSubsidyAccountInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayAccountVo>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/queryAccountPower
   * @summary 账户权限查询
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/queryAccountPower_GET": (
    query: AdminPayAccountInfoQueryAccountPowerGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/accountInfo/queryAccountPower`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/apply/applyInfo
   * @summary 开户信息查询
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultPayAccountApplyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/applyInfo_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/applyInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayAccountApplyVo>,
  /**
   * No description
   * @name GET /admin/pay/apply/bindingCardMargin
   * @summary 绑卡申请-保证金账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/bindingCardMargin_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/bindingCardMargin`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/bindingCardMaster
   * @summary 2-绑卡申请-主账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/bindingCardMaster_POST": (data: AccountApplyBindingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/bindingCardMaster`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name GET /admin/pay/apply/bindingCardSubsidy
   * @summary 绑卡申请-营销账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/bindingCardSubsidy_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/bindingCardSubsidy`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/complementCert
   * @summary 3-补充影音件
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/complementCert_POST": (data: ComplementCertificateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/complementCert`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name GET /admin/pay/apply/openAccountMargin
   * @summary 开户-保证金账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/openAccountMargin_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/openAccountMargin`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/apply/openAccountMaster
   * @summary 开户-主账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/openAccountMaster_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/openAccountMaster`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/apply/openAccountSubsidy
   * @summary 开户-营销账户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/openAccountSubsidy_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/openAccountSubsidy`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/pay/apply/saveAccountApply
   * @summary 2-开户-企业
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/saveAccountApply_POST": (data: PayAccountApplyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/saveAccountApply`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/saveAccountApplyPerson
   * @summary 2-开户-个体户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/saveAccountApplyPerson_POST": (data: PayAccountApplyPersonDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/saveAccountApplyPerson`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/signInNetMasterAccount
   * @summary 6-个体户签约入网
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultSignInNetVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/signInNetMasterAccount_POST": (data: SignInNetDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/signInNetMasterAccount`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultSignInNetVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/sumbitBindingCard
   * @summary 绑卡确认
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/sumbitBindingCard_POST": (data: PaySubmitBindingCardDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/sumbitBindingCard`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/pay/apply/sumbitBindingCardMaster
   * @summary 5-绑卡确认-个体户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/sumbitBindingCardMaster_POST": (data: PaySubmitBindingCardDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/sumbitBindingCardMaster`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/pay/apply/uploadImg
   * @summary 1-上传影音件
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/uploadImg_POST": (data: AccountApplyUploadImgDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/uploadImg`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name GET /admin/pay/banks/queryBanks
   * @summary 银行查询
   * @tags P-支付-网银充值银行
   * @response `200` `JsonResultListPayBanksVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/banks/queryBanks_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/banks/queryBanks`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayBanksVo>,
  /**
   * No description
   * @name POST /admin/pay/channel/applyment
   * @summary 微信进件申请
   * @tags P支付-微信入驻
   * @response `200` `JsonResultPayChannelApplymentVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/applyment_POST": (data: ChannelApplymentDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/channel/applyment`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPayChannelApplymentVo>,
  /**
   * No description
   * @name GET /admin/pay/channel/queryApplymentByNo
   * @summary 根据申请编号查询微信进件状态
   * @tags P支付-微信入驻
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/queryApplymentByNo_GET": (
    query: AdminPayChannelQueryApplymentByNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/channel/queryApplymentByNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/channel/queryApplymentStatus
   * @summary 微信进件状态查询
   * @tags P支付-微信入驻
   * @response `200` `JsonResultPayChannelApplymentVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/queryApplymentStatus_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/channel/queryApplymentStatus`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayChannelApplymentVo>,
  /**
   * No description
   * @name GET /admin/pay/channel/queryRealnameByNo
   * @summary 根据账户编号与申请编号查询微信实名认证状态
   * @tags P支付-微信入驻
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/queryRealnameByNo_GET": (
    query: AdminPayChannelQueryRealnameByNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/channel/queryRealnameByNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/channel/queryRealnameStatus
   * @summary 微信实名认证状态查询
   * @tags P支付-微信入驻
   * @response `200` `JsonResultPayChannelApplymentVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/queryRealnameStatus_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/channel/queryRealnameStatus`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayChannelApplymentVo>,
  /**
   * No description
   * @name POST /admin/pay/channel/realName
   * @summary 微信实名认证申请
   * @tags P支付-微信入驻
   * @response `200` `JsonResultPayChannelApplymentVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/realName_POST": (data: RealNameDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/channel/realName`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPayChannelApplymentVo>,
  /**
   * No description
   * @name GET /admin/pay/channel/revokeRealName
   * @summary 撤销微信实名认证申请
   * @tags P支付-微信入驻
   * @response `200` `JsonResultPayChannelApplymentVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/channel/revokeRealName_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/channel/revokeRealName`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayChannelApplymentVo>,
  /**
   * No description
   * @name GET /admin/pay/dic/getBankList
   * @summary 银行查询
   * @tags P-支付-字典查询
   * @response `200` `JsonResultListPayDictionaryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/dic/getBankList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/dic/getBankList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayDictionaryVo>,
  /**
   * No description
   * @name GET /admin/pay/dic/getCategoryList
   * @summary 分类查询
   * @tags P-支付-字典查询
   * @response `200` `JsonResultListPayDictionaryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/dic/getCategoryList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/dic/getCategoryList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayDictionaryVo>,
  /**
   * No description
   * @name GET /admin/pay/dic/getCommonIndustryList
   * @summary 通用行业查询
   * @tags P-支付-字典查询
   * @response `200` `JsonResultListPayDictionaryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/dic/getCommonIndustryList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/dic/getCommonIndustryList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayDictionaryVo>,
  /**
   * No description
   * @name GET /admin/pay/dic/getIndustryList
   * @summary 行业查询
   * @tags P-支付-字典查询
   * @response `200` `JsonResultListPayDictionaryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/dic/getIndustryList_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/dic/getIndustryList`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayDictionaryVo>,
  /**
   * No description
   * @name GET /admin/pay/dic/getWeipayCategory
   * @summary 微信进件行业分类
   * @tags P-支付-字典查询
   * @response `200` `JsonResultListPayDictionaryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/dic/getWeipayCategory_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/dic/getWeipayCategory`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListPayDictionaryVo>,
  /**
   * No description
   * @name GET /admin/pay/order/getPayOrder
   * @summary 查询支付订单
   * @tags P-支付-支付订单
   * @response `200` `JsonResultPayOrderVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/order/getPayOrder_GET": (query: AdminPayOrderGetPayOrderGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/order/getPayOrder`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPayOrderVo>,
  /**
   * No description
   * @name POST /admin/pay/payment/rechargeB2B
   * @summary B2B网银充值
   * @tags P-支付-后端支付
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/payment/rechargeB2B_POST": (data: RechargeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/payment/rechargeB2B`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/pay/payment/rechargeOnline
   * @summary 网银充值
   * @tags P-支付-后端支付
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/payment/rechargeOnline_POST": (data: RechargeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/payment/rechargeOnline`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/pay/payment/refundMargin
   * @summary 退保证金
   * @tags P-支付-后端支付
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/payment/refundMargin_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/payment/refundMargin`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/pay/payment/transfer
   * @summary 账户转账
   * @tags P-支付-后端支付
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/payment/transfer_POST": (data: TransferDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/payment/transfer`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/pay/payment/withdraw
   * @summary 提现
   * @tags P-支付-后端支付
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/payment/withdraw_POST": (data: WithdrawDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/payment/withdraw`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/exportMargin
   * @summary 保证金账户交易明细导出
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/exportMargin_GET": (
    query: AdminPayPlatfromLedgerExportMarginGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/exportMargin`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/exportMaster
   * @summary 主账户交易明细导出
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/exportMaster_GET": (
    query: AdminPayPlatfromLedgerExportMasterGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/exportMaster`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/exportSubsidy
   * @summary 营销账户交易明细导出
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/exportSubsidy_GET": (
    query: AdminPayPlatfromLedgerExportSubsidyGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/exportSubsidy`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/marginAccountAmount
   * @summary 保证金账户收支总额
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/marginAccountAmount_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/marginAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/masterAccountAmount
   * @summary 主账户收支总额
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/masterAccountAmount_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/masterAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/queryMarginLedgers
   * @summary 保证金账户交易查询
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/queryMarginLedgers_GET": (
    query: AdminPayPlatfromLedgerQueryMarginLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/queryMarginLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/queryMasterLedgers
   * @summary 主账户交易查询
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/queryMasterLedgers_GET": (
    query: AdminPayPlatfromLedgerQueryMasterLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/queryMasterLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/querySubsidyLedgers
   * @summary 营销账户交易查询
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/querySubsidyLedgers_GET": (
    query: AdminPayPlatfromLedgerQuerySubsidyLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/querySubsidyLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/platfromLedger/subsidyAccountAmount
   * @summary 营销账户收支总额
   * @tags P-支付-所有账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/platfromLedger/subsidyAccountAmount_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/platfromLedger/subsidyAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportMarginPlatform
   * @summary 平台保证金账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportMarginPlatform_GET": (
    query: AdminPayStoreLedgerExportMarginPlatformGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportMarginPlatform`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportMarginStore
   * @summary 店铺保证金账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportMarginStore_GET": (
    query: AdminPayStoreLedgerExportMarginStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportMarginStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportMasterPlatform
   * @summary 平台主账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportMasterPlatform_GET": (
    query: AdminPayStoreLedgerExportMasterPlatformGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportMasterPlatform`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportMasterStore
   * @summary 店铺主账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportMasterStore_GET": (
    query: AdminPayStoreLedgerExportMasterStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportMasterStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportSubsidyPlatform
   * @summary 平台营销账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportSubsidyPlatform_GET": (
    query: AdminPayStoreLedgerExportSubsidyPlatformGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportSubsidyPlatform`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/exportSubsidyStore
   * @summary 店铺营销账户交易明细导出
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/exportSubsidyStore_GET": (
    query: AdminPayStoreLedgerExportSubsidyStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/exportSubsidyStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/marginAccountAmount
   * @summary 保证金账户收支总额
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/marginAccountAmount_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/storeLedger/marginAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/masterAccountAmount
   * @summary 主账户收支总额
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/masterAccountAmount_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/storeLedger/masterAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/queryMarginLedgers
   * @summary 保证金账户交易查询
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/queryMarginLedgers_GET": (
    query: AdminPayStoreLedgerQueryMarginLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/queryMarginLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/queryMasterLedgers
   * @summary 主账户交易查询
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/queryMasterLedgers_GET": (
    query: AdminPayStoreLedgerQueryMasterLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/queryMasterLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/querySubsidyLedgers
   * @summary 营销账户交易查询
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultLedgerResultVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/querySubsidyLedgers_GET": (
    query: AdminPayStoreLedgerQuerySubsidyLedgersGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/storeLedger/querySubsidyLedgers`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLedgerResultVo>,
  /**
   * No description
   * @name GET /admin/pay/storeLedger/subsidyAccountAmount
   * @summary 营销账户收支总额
   * @tags P-支付-店铺账户交易查询
   * @response `200` `JsonResultAccountAmountVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/storeLedger/subsidyAccountAmount_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/storeLedger/subsidyAccountAmount`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultAccountAmountVo>,
  /**
   * @description STORE_REG: 后台商户注册、STORE_FORGOT：后台商户忘记密码
   * @name GET /admin/sms/verifyCode
   * @summary 发送短信验证码
   * @tags 短信
   * @response `200` `JsonResultVoid` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/sms/verifyCode_GET": (query: AdminSmsVerifyCodeGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/sms/verifyCode`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/store/MallBrandApply/getOne
   * @summary 获得一个品牌申请 /具体数据需要协商
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultMallBrandApplyDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallBrandApply/getOne_GET": (
    query: AdminStoreMallBrandApplyGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallBrandApplyDetailVo>,
  /**
   * No description
   * @name GET /admin/store/MallBrandApply/getOneByBrandNo
   * @summary 查询一个品牌 根据编号
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultMallBrandVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallBrandApply/getOneByBrandNo_GET": (
    query: AdminStoreMallBrandApplyGetOneByBrandNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/getOneByBrandNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallBrandVo>,
  /**
   * No description
   * @name GET /admin/store/MallBrandApply/query
   * @summary 品牌审核分页查询
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultPageInfoMallBrandApplyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallBrandApply/query_GET": (
    query: AdminStoreMallBrandApplyQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallBrandApplyVo>,
  /**
   * No description
   * @name DELETE /admin/store/MallBrandApply/removeById
   * @summary 删除 品牌
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/store/MallBrandApply/removeById_DELETE": (
    query: AdminStoreMallBrandApplyRemoveByIdDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/removeById`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallBrandApply/saveMallBrandApplyAddParam
   * @summary 认领、新增 品牌
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallBrandApply/saveMallBrandApplyAddParam_POST": (
    data: MallBrandApplyAddParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/saveMallBrandApplyAddParam`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallBrandApply/updateParam
   * @summary 修改 品牌
   * @tags C店铺-品牌申请表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallBrandApply/updateParam_POST": (data: MallBrandApplyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallBrandApply/updateParam`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/store/MallGoods/delGoods
   * @summary 商品删除
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/store/MallGoods/delGoods_DELETE": (
    query: AdminStoreMallGoodsDelGoodsDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/delGoods`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/store/MallGoods/delGoodsList
   * @summary 批量商品删除
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/store/MallGoods/delGoodsList_DELETE": (
    data: GoodsAdminOffShelfListUsingPOSTGoodsnolist,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/delGoodsList`,
      method: "DELETE",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/store/MallGoods/getGoodsNameImg
   * @summary 查询商品的名称和图片
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultMallGoodsDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/getGoodsNameImg_GET": (
    query: AdminStoreMallGoodsGetGoodsNameImgGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/getGoodsNameImg`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsDetailVo>,
  /**
   * No description
   * @name POST /admin/store/MallGoods/getGoodsPage
   * @summary 商品列表分页
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultPageInfoMallGoodsPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/getGoodsPage_POST": (
    query: AdminStoreMallGoodsGetGoodsPagePostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/getGoodsPage`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsPageVo>,
  /**
   * No description
   * @name POST /admin/store/MallGoods/getGoodsPageExcel
   * @summary 商品列表分页-excel
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/getGoodsPageExcel_POST": (
    query: AdminStoreMallGoodsGetGoodsPageExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/getGoodsPageExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/store/MallGoods/getMallGoodsDetail
   * @summary 商品详情
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultMallGoodsDetailVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/getMallGoodsDetail_GET": (
    query: AdminStoreMallGoodsGetMallGoodsDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/getMallGoodsDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsDetailVo>,
  /**
   * No description
   * @name GET /admin/store/MallGoods/getRemark
   * @summary 查询最新的拒绝审核数据
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/getRemark_GET": (query: AdminStoreMallGoodsGetRemarkGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/getRemark`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/goodsOffShelf
   * @summary 商品下架
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/goodsOffShelf_PUT": (
    query: AdminStoreMallGoodsGoodsOffShelfPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/goodsOffShelf`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/goodsOffShelfList
   * @summary 批量商品下架
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/goodsOffShelfList_PUT": (
    data: GoodsAdminOffShelfListUsingPOSTGoodsnolist,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/goodsOffShelfList`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/goodsPutShelf
   * @summary 商品上架
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/goodsPutShelf_PUT": (
    query: AdminStoreMallGoodsGoodsPutShelfPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/goodsPutShelf`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/goodsPutShelfList
   * @summary 批量商品上架
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/goodsPutShelfList_PUT": (
    data: GoodsAdminOffShelfListUsingPOSTGoodsnolist,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/goodsPutShelfList`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallGoods/saveOrdinaryGoods
   * @summary 新增普通商品
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/saveOrdinaryGoods_POST": (data: MallGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/saveOrdinaryGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallGoods/saveVirtualGoods
   * @summary 新增虚拟商品
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/saveVirtualGoods_POST": (data: MallVirtualGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/saveVirtualGoods`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/updateGoods
   * @summary 修改普通商品
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/updateGoods_PUT": (data: MallGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/updateGoods`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * @description 显示状态（0：不显示，1：显示）
   * @name PUT /admin/store/MallGoods/updateGoodsStatus
   * @summary 修改显示状态 显示状态（0：不显示，1：显示）
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/updateGoodsStatus_PUT": (
    query: AdminStoreMallGoodsUpdateGoodsStatusPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/updateGoodsStatus`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallGoods/updateSaleAttributes
   * @summary 修改销售属性
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/updateSaleAttributes_POST": (
    data: MallGoodsSalesAttributeParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoods/updateSaleAttributes`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/updateVirtualGoods
   * @summary 修改虚拟商品
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/updateVirtualGoods_PUT": (data: MallVirtualGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/updateVirtualGoods`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/store/MallGoodsCommentStore/list
   * @summary 商品评论表分页查询
   * @tags 商品评论表
   * @response `200` `JsonResultPageInfoMallGoodsCommentVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsCommentStore/list_GET": (
    query: AdminStoreMallGoodsCommentStoreListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsCommentStore/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsCommentVo>,
  /**
   * No description
   * @name POST /admin/store/MallGoodsCommentStore/queryGoodsCommentExcel
   * @summary 商品评论表分页查询-excel
   * @tags 商品评论表
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsCommentStore/queryGoodsCommentExcel_POST": (
    query: AdminStoreMallGoodsCommentStoreQueryGoodsCommentExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsCommentStore/queryGoodsCommentExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name PUT /admin/store/MallGoodsCommentStore/saveStoreAppendComment
   * @summary 商家回复追评
   * @tags 商品评论表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsCommentStore/saveStoreAppendComment_PUT": (
    query: AdminStoreMallGoodsCommentStoreSaveStoreAppendCommentPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsCommentStore/saveStoreAppendComment`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallGoodsCommentStore/saveStoreComment
   * @summary 商家回复评论
   * @tags 商品评论表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsCommentStore/saveStoreComment_PUT": (
    query: AdminStoreMallGoodsCommentStoreSaveStoreCommentPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsCommentStore/saveStoreComment`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/store/MallGoodsSKu/getMallGoodsVo
   * @summary 商品编号获取 SKU集合
   * @tags E-店铺-商品SKU模块
   * @response `200` `JsonResultListMallGoodsSkuVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsSKu/getMallGoodsVo_GET": (
    query: AdminStoreMallGoodsSKuGetMallGoodsVoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsSKu/getMallGoodsVo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsSkuVo>,
  /**
   * @description skuNo SKU编号,startTime 开始时间,entTime 结束时间
   * @name GET /admin/store/MallGoodsSKu/getSkuInventoryExcel
   * @summary SKU出入库-Excel
   * @tags E-店铺-商品SKU模块
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsSKu/getSkuInventoryExcel_GET": (
    query: AdminStoreMallGoodsSKuGetSkuInventoryExcelGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsSKu/getSkuInventoryExcel`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description skuNo SKU编号,startTime 开始时间,entTime 结束时间
   * @name GET /admin/store/MallGoodsSKu/getSkuInventoryPage
   * @summary SKU 出入库 查询
   * @tags E-店铺-商品SKU模块
   * @response `200` `JsonResultPageInfoMallGoodsSkuInventoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsSKu/getSkuInventoryPage_GET": (
    query: AdminStoreMallGoodsSKuGetSkuInventoryPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsSKu/getSkuInventoryPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsSkuInventoryVo>,
  /**
   * @description skuNo SKU编号,inventory 改动量,add 是否增加 否则减少
   * @name POST /admin/store/MallGoodsSKu/updateSkuInventory
   * @summary SKU 改动库存
   * @tags E-店铺-商品SKU模块
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoodsSKu/updateSkuInventory_POST": (
    query: AdminStoreMallGoodsSKuUpdateSkuInventoryPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallGoodsSKu/updateSkuInventory`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/store/MallStoreCategory/getMallStoreCategoryListVos
   * @summary 店铺类目管理查询 根据品牌
   * @tags B-店铺-经营类目
   * @response `200` `JsonResultListMallStoreCategoryListVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallStoreCategory/getMallStoreCategoryListVos_GET": (
    query: AdminStoreMallStoreCategoryGetMallStoreCategoryListVosGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallStoreCategory/getMallStoreCategoryListVos`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreCategoryListVo>,
  /**
   * No description
   * @name GET /admin/store/MallStoreCategory/getMallStoreCategoryVo
   * @summary 店铺类目管理查询
   * @tags B-店铺-经营类目
   * @response `200` `JsonResultMallStoreCategoryVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallStoreCategory/getMallStoreCategoryVo_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallStoreCategory/getMallStoreCategoryVo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStoreCategoryVo>,
  /**
   * No description
   * @name GET /admin/store/MallStorePay/getStorePayDetail
   * @summary 商家管理-店铺状态-账户数据
   * @tags B-商家管理-店铺状态-账户数据
   * @response `200` `JsonResultMallStorePayVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallStorePay/getStorePayDetail_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallStorePay/getStorePayDetail`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultMallStorePayVo>,
  /**
   * No description
   * @name PUT /admin/store/MallVerifyStore/cancel
   * @summary 作废核销码
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/cancel_PUT": (
    query: AdminStoreMallVerifyStoreCancelPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/cancel`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallVerifyStore/del
   * @summary 删除核销码
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/del_PUT": (query: AdminStoreMallVerifyStoreDelPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/del`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/store/MallVerifyStore/delList
   * @summary 批量-删除核销码
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/delList_PUT": (data: DeleteBatchUsingPOSTIds, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/delList`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallVerifyStore/importGenerateWriteCode
   * @summary 导入-生成核销码
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/importGenerateWriteCode_POST": (
    query: AdminStoreMallVerifyStoreImportGenerateWriteCodePostParams,
    data: object,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/importGenerateWriteCode`,
      method: "POST",
      params: query,
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallVerifyStore/query
   * @summary 核销码表分页查询
   * @tags 核销码表
   * @response `200` `JsonResultPageInfoMallVerifyVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/query_POST": (
    query: AdminStoreMallVerifyStoreQueryPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/query`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallVerifyVo>,
  /**
   * No description
   * @name POST /admin/store/MallVerifyStore/saveCarMy
   * @summary 导入-生成卡密
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/saveCarMy_POST": (
    query: AdminStoreMallVerifyStoreSaveCarMyPostParams,
    data: object,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/saveCarMy`,
      method: "POST",
      params: query,
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name POST /admin/store/MallVerifyStore/saveGenerateWriteCode
   * @summary 生成核销码
   * @tags 核销码表
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallVerifyStore/saveGenerateWriteCode_POST": (
    data: MallVerifyWriteCodeParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MallVerifyStore/saveGenerateWriteCode`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * @description 活动删除
   * @name PUT /admin/store/MarketingActivity/delete
   * @summary 活动删除
   * @tags 限时抢购活动功能
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivity/delete_PUT": (data: ActivityOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MarketingActivity/delete`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 活动详情
   * @name GET /admin/store/MarketingActivity/info
   * @summary 活动详情
   * @tags 限时抢购活动功能
   * @response `200` `JsonResultMarketingActivityDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivity/info_GET": (
    query: AdminStoreMarketingActivityInfoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivity/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityDto>,
  /**
   * @description 活动启用和停用
   * @name PUT /admin/store/MarketingActivity/updateStatus
   * @summary 活动启用和停用
   * @tags 限时抢购活动功能
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivity/updateStatus_PUT": (data: ActivityOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MarketingActivity/updateStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityApply/activityNo
   * @summary 查询 审核失败的原因
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/activityNo_GET": (
    query: AdminStoreMarketingActivityApplyActivityNoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/activityNo`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityApply/getDetailByStore
   * @summary 获取活动店铺详情信息
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultMarketingActivityApplyDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/getDetailByStore_GET": (
    query: AdminStoreMarketingActivityApplyGetDetailByStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/getDetailByStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityApplyDto>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityApply/getFullDiscountDetail
   * @summary 查询满减满折详情
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultMarketingActivityFullDiscountApplyDetail` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/getFullDiscountDetail_GET": (
    query: AdminStoreMarketingActivityApplyGetFullDiscountDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/getFullDiscountDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityFullDiscountApplyDetail>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityApply/getPanicBuyDetail
   * @summary 查询限时购买详情
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultMarketingActivityPanicBuyApplyDetail` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/getPanicBuyDetail_GET": (
    query: AdminStoreMarketingActivityApplyGetPanicBuyDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/getPanicBuyDetail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityPanicBuyApplyDetail>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityApply/joinMarketingApplyActivity
   * @summary 参加 满折活动 / 限时抢购活动
   * @tags 店铺参加平台活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/joinMarketingApplyActivity_POST": (
    data: MarketingActivityStoreApplyParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/joinMarketingApplyActivity`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityApply/queryFullDiscountExcel
   * @summary 查询平台活动-excel
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/queryFullDiscountExcel_POST": (
    query: AdminStoreMarketingActivityApplyQueryFullDiscountExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/queryFullDiscountExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityApply/queryFullDiscountPage
   * @summary 查询平台活动
   * @tags 店铺参加平台活动
   * @response `200` `JsonResultPageInfoMarketingActivityApplyStoreVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityApply/queryFullDiscountPage_GET": (
    query: AdminStoreMarketingActivityApplyQueryFullDiscountPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityApply/queryFullDiscountPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityApplyStoreVo>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityGroup/activityGroup/export
   * @summary 拼团活动列表导出(直接返回下载地址)
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/activityGroup/export_GET": (
    query: AdminStoreMarketingActivityGroupActivityGroupExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/activityGroup/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 拼团活动新增
   * @name POST /admin/store/MarketingActivityGroup/addMarketingGroup
   * @summary 拼团活动新增
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/addMarketingGroup_POST": (
    data: MarketingActivityGroupSaveParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/addMarketingGroup`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 拼团活动详情
   * @name GET /admin/store/MarketingActivityGroup/detail
   * @summary 拼团活动详情
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultMarketingActivityDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/detail_GET": (
    query: AdminStoreMarketingActivityGroupDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityDto>,
  /**
   * @description 拼团活动编辑
   * @name POST /admin/store/MarketingActivityGroup/editMarketingGroup
   * @summary 拼团活动编辑
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/editMarketingGroup_POST": (
    data: MarketingActivityGroupSaveParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/editMarketingGroup`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityGroup/export
   * @summary 拼团管理导出(直接返回下载地址)
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/export_POST": (
    data: MarketingActivityGroupRecordsQuery,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/export`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityGroup/group/records
   * @summary 拼团管理-团列表
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultPageInfoMarketingActivityGroupRecordsVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/group/records_POST": (
    data: MarketingActivityGroupRecordsQuery,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/group/records`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityGroupRecordsVo>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityGroup/group/recordsItems
   * @summary 参团成员、订单、购买时规格信息
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultPageInfoGroupItemsVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/group/recordsItems_POST": (
    data: MarketingActivityGroupRecordsQuery,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/group/recordsItems`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGroupItemsVo>,
  /**
   * @description 拼团活动分页查询
   * @name GET /admin/store/MarketingActivityGroup/page
   * @summary 拼团活动分页查询
   * @tags M-店铺-拼团活动
   * @response `200` `JsonResultPageInfoMarketingActivityGroupPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityGroup/page_GET": (
    query: AdminStoreMarketingActivityGroupPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityGroup/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityGroupPageVo>,
  /**
   * No description
   * @name GET /admin/store/MarketingActivityTimeBuy/queryList
   * @summary 查询限时抢购分页
   * @tags 店铺 -限时抢购活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityTimeBuy/queryList_GET": (
    query: AdminStoreMarketingActivityTimeBuyQueryListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityTimeBuy/queryList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityTimeBuy/queryPanicBuyingExcel
   * @summary 限时抢购-excel
   * @tags 店铺 -限时抢购活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityTimeBuy/queryPanicBuyingExcel_POST": (
    query: AdminStoreMarketingActivityTimeBuyQueryPanicBuyingExcelPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityTimeBuy/queryPanicBuyingExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/store/MarketingActivityTimeBuy/saveOrUpdateMarketingTimeBuy
   * @summary 创建限时抢购
   * @tags 店铺 -限时抢购活动
   * @response `200` `JsonResultBoolean` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivityTimeBuy/saveOrUpdateMarketingTimeBuy_POST": (
    data: MarketingActivityTimeBuyParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivityTimeBuy/saveOrUpdateMarketingTimeBuy`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name DELETE /admin/store/MarketingWatermark/del
   * @summary 删除水印
   * @tags M-店铺-主图水印
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/store/MarketingWatermark/del_DELETE": (
    query: AdminStoreMarketingWatermarkDelDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/del`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/MarketingWatermark/endMarket
   * @summary 结束活动
   * @tags M-店铺-主图水印
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/endMarket_POST": (
    query: AdminStoreMarketingWatermarkEndMarketPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/endMarket`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/MarketingWatermark/getOne
   * @summary 获取单个数据水印活动
   * @tags M-店铺-主图水印
   * @response `200` `JsonResultMarketingWatermarkPram` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/getOne_GET": (
    query: AdminStoreMarketingWatermarkGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingWatermarkPram>,
  /**
   * No description
   * @name GET /admin/store/MarketingWatermark/queryList
   * @summary 分页查询 水印活动
   * @tags M-店铺-主图水印
   * @response `200` `JsonResultListMarketingWatermarkDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/queryList_GET": (
    query: AdminStoreMarketingWatermarkQueryListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/queryList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMarketingWatermarkDto>,
  /**
   * No description
   * @name POST /admin/store/MarketingWatermark/saveOrUpdate
   * @summary 新增编辑 水印活动
   * @tags M-店铺-主图水印
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/saveOrUpdate_POST": (data: MarketingWatermarkPram, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/saveOrUpdate`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/MarketingWatermark/saveOrUpdateRel
   * @summary 新增修改水印中的商品
   * @tags M-店铺-主图水印
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/saveOrUpdateRel_POST": (
    data: MarketingWatermarkGoodsRelParam[],
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/saveOrUpdateRel`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/MarketingWatermark/startMarket
   * @summary 开始活动
   * @tags M-店铺-主图水印
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingWatermark/startMarket_POST": (
    query: AdminStoreMarketingWatermarkStartMarketPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingWatermark/startMarket`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 预售活动新增
   * @name POST /admin/store/advance/add
   * @summary 预售活动新增
   * @tags M-店铺-预售活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/add_POST": (data: AdvanceActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 预售活动删除
   * @name PUT /admin/store/advance/delete
   * @summary 预售活动删除
   * @tags M-店铺-预售活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/delete_PUT": (data: ActivityOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/delete`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 预售活动详情
   * @name GET /admin/store/advance/info
   * @summary 预售活动详情
   * @tags M-店铺-预售活动
   * @response `200` `JsonResultMarketingActivityDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/info_GET": (query: AdminStoreAdvanceInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMarketingActivityDto>,
  /**
   * @description 预售活动分页查询
   * @name GET /admin/store/advance/list
   * @summary 预售活动分页查询
   * @tags M-店铺-预售活动
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/list_GET": (query: AdminStoreAdvanceListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * @description 预售活动修改
   * @name PUT /admin/store/advance/update
   * @summary 预售活动修改
   * @tags M-店铺-预售活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/update_PUT": (data: AdvanceActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 预售活动启用和停用
   * @name PUT /admin/store/advance/updateStatus
   * @summary 预售活动启用和停用
   * @tags M-店铺-预售活动
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/updateStatus_PUT": (data: ActivityOpenDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/updateStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/advance/order/export
   * @summary 预售订单导出,导出全部数据需要指定pageSize=0
   * @tags 预售订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/order/export_GET": (
    query: AdminStoreAdvanceOrderExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/advance/order/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 预售订单分页查询
   * @name GET /admin/store/advance/order/list
   * @summary 预售订单分页查询
   * @tags 预售订单管理
   * @response `200` `JsonResultPageInfoAdvanceOrderPoiDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/order/list_GET": (query: AdminStoreAdvanceOrderListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/order/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoAdvanceOrderPoiDto>,
  /**
   * @description 退回订金
   * @name POST /admin/store/advance/order/refund
   * @summary 退回订金
   * @tags 预售订单管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/order/refund_POST": (data: RefundDepositParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/order/refund`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 提交仲裁资料
   * @name PUT /admin/store/arb/add
   * @summary 提交仲裁资料
   * @tags 店铺仲裁管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/arb/add_PUT": (data: ArbitrationOrderParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/arb/add`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/arb/export
   * @summary 店铺仲裁单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺仲裁管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/arb/export_GET": (query: AdminStoreArbExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/arb/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 仲裁单信息
   * @name GET /admin/store/arb/info
   * @summary 仲裁单信息
   * @tags 店铺仲裁管理
   * @response `200` `JsonResultArbitrationOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/arb/info_GET": (query: AdminStoreArbInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/arb/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultArbitrationOrderDto>,
  /**
   * @description 店铺待仲裁单分页查询
   * @name GET /admin/store/arb/list
   * @summary 店铺待仲裁单分页查询
   * @tags 店铺仲裁管理
   * @response `200` `JsonResultPageInfoArbitrationOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/arb/list_GET": (query: AdminStoreArbListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/arb/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoArbitrationOrderDto>,
  /**
   * No description
   * @name GET /admin/store/flash/order/export
   * @summary 限时抢购订单导出,导出全部数据需要指定pageSize=0
   * @tags 限时抢购订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/flash/order/export_GET": (query: AdminStoreFlashOrderExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/flash/order/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 限时抢购订单分页查询
   * @name GET /admin/store/flash/order/list
   * @summary 限时抢购订单分页查询
   * @tags 限时抢购订单管理
   * @response `200` `JsonResultPageInfoFlashOrderPoiDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/flash/order/list_GET": (query: AdminStoreFlashOrderListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/flash/order/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoFlashOrderPoiDto>,
  /**
   * No description
   * @name PUT /admin/store/invoice/config
   * @summary 确认开票
   * @tags 店铺发票管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/invoice/config_PUT": (data: OrderConfirmInvoiceDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/invoice/config`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/invoice/excel
   * @summary 发票查询-excel
   * @tags 店铺发票管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/invoice/excel_GET": (query: AdminStoreInvoiceExcelGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/invoice/excel`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/store/invoice/info
   * @summary 发票详情
   * @tags 店铺发票管理
   * @response `200` `JsonResultOrderInvoiceDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/invoice/info_GET": (query: AdminStoreInvoiceInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/invoice/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderInvoiceDto>,
  /**
   * No description
   * @name GET /admin/store/invoice/list
   * @summary 发票查询
   * @tags 店铺发票管理
   * @response `200` `JsonResultPageInfoOrderInvoiceDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/invoice/list_GET": (query: AdminStoreInvoiceListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/invoice/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderInvoiceDto>,
  /**
   * No description
   * @name POST /admin/store/mallGoodsCategory/getMallCategoryVo
   * @summary 根据类目集合 获取 保证金 + 扣点
   * @tags D 店铺-商品类目表
   * @response `200` `JsonResultMallCategoryVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/mallGoodsCategory/getMallCategoryVo_POST": (data: MallCategoryParam[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/mallGoodsCategory/getMallCategoryVo`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultMallCategoryVo>,
  /**
   * No description
   * @name GET /admin/store/mallGoodsCategory/getOne
   * @summary 三级类目 得到规格和属性
   * @tags D 店铺-商品类目表
   * @response `200` `JsonResultMallCategoryPropVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/mallGoodsCategory/getOne_GET": (
    query: AdminStoreMallGoodsCategoryGetOneGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/mallGoodsCategory/getOne`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallCategoryPropVo>,
  /**
   * No description
   * @name GET /admin/store/order/export
   * @summary 店铺订单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/export_GET": (query: AdminStoreOrderExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/store/order/info
   * @summary 订单信息
   * @tags 店铺订单管理
   * @response `200` `JsonResultOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/info_GET": (query: AdminStoreOrderInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderDto>,
  /**
   * @description 店铺订单分页查询
   * @name GET /admin/store/order/list
   * @summary 店铺订单分页查询
   * @tags 店铺订单管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/list_GET": (query: AdminStoreOrderListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name POST /admin/store/order/tag
   * @summary 订单标记
   * @tags 店铺订单管理
   * @response `200` `JsonResultOrderDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/tag_POST": (data: OrderTagParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/tag`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultOrderDto>,
  /**
   * @description 同意退款,仅退款时填写退款金额和备注，退货退款时填写退货地址
   * @name PUT /admin/store/refund/agree
   * @summary 同意退款
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/agree_PUT": (data: RefundAgreeParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/agree`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 验货通过
   * @name PUT /admin/store/refund/checkAgree
   * @summary 验货通过
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/checkAgree_PUT": (data: RefundCheckParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/checkAgree`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 验货不通过
   * @name PUT /admin/store/refund/checkRefuse
   * @summary 验货不通过
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/checkRefuse_PUT": (data: RefundCheckParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/checkRefuse`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/store/refund/export
   * @summary 店铺售后订单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺售后管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/export_GET": (query: AdminStoreRefundExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 售后单信息
   * @name GET /admin/store/refund/info
   * @summary 售后单信息
   * @tags 店铺售后管理
   * @response `200` `JsonResultRefundMasterDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/info_GET": (query: AdminStoreRefundInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundMasterDto>,
  /**
   * @description 店铺售后订单分页查询
   * @name GET /admin/store/refund/list
   * @summary 店铺售后订单分页查询
   * @tags 店铺售后管理
   * @response `200` `JsonResultPageInfoRefundMasterDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/list_GET": (query: AdminStoreRefundListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRefundMasterDto>,
  /**
   * @description 线下处理
   * @name PUT /admin/store/refund/offline
   * @summary 线下处理
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/offline_PUT": (query: AdminStoreRefundOfflinePutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/offline`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 重新退款
   * @name PUT /admin/store/refund/reRefund
   * @summary 重新退款
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/reRefund_PUT": (query: AdminStoreRefundReRefundPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/reRefund`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 拒绝退款
   * @name PUT /admin/store/refund/refuse
   * @summary 拒绝退款
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/refuse_PUT": (data: RefundRefuseParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/refuse`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 退货物流填写
   * @name PUT /admin/store/refund/shipping
   * @summary 退货物流填写
   * @tags 店铺售后管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/refund/shipping_PUT": (data: RefundShippingParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/refund/shipping`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺实收订单分页查询
   * @name GET /admin/store/settlement/orders
   * @summary 店铺实收订单分页查询
   * @tags 店铺结算管理
   * @response `200` `JsonResultOrderLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/orders_GET": (query: AdminStoreSettlementOrdersGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/settlement/orders`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/store/settlement/orders/export
   * @summary 店铺实收订单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/orders/export_GET": (
    query: AdminStoreSettlementOrdersExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/orders/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 店铺实收订单统计
   * @name GET /admin/store/settlement/orders/stat
   * @summary 店铺实收订单统计
   * @tags 店铺结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/orders/stat_GET": (
    query: AdminStoreSettlementOrdersStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/orders/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 店铺退款单分页查询
   * @name GET /admin/store/settlement/refund
   * @summary 店铺退款单分页查询
   * @tags 店铺结算管理
   * @response `200` `JsonResultRefundLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/refund_GET": (query: AdminStoreSettlementRefundGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/settlement/refund`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/store/settlement/refund/export
   * @summary 店铺退款单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/refund/export_GET": (
    query: AdminStoreSettlementRefundExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/refund/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 手工处理未完成扣款的保证金，处理状态FineMargin
   * @name PUT /admin/store/settlement/refund/margin
   * @summary 手工罚保证金
   * @tags 店铺结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/refund/margin_PUT": (
    query: AdminStoreSettlementRefundMarginPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/refund/margin`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 手工处理未完成退款的退款单，处理状态ReturnDeductionPoint、RefundAmount
   * @name PUT /admin/store/settlement/refund/return
   * @summary 手工退款
   * @tags 店铺结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/refund/return_PUT": (
    query: AdminStoreSettlementRefundReturnPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/refund/return`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺退款单统计
   * @name GET /admin/store/settlement/refund/stat
   * @summary 店铺退款单统计
   * @tags 店铺结算管理
   * @response `200` `JsonResultRefundStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/refund/stat_GET": (
    query: AdminStoreSettlementRefundStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/refund/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundStatisticsDto>,
  /**
   * @description 店铺补贴单分页查询
   * @name GET /admin/store/settlement/subsidy
   * @summary 店铺补贴单分页查询
   * @tags 店铺结算管理
   * @response `200` `JsonResultSubsidyItemLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/subsidy_GET": (query: AdminStoreSettlementSubsidyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/settlement/subsidy`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSubsidyItemLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/store/settlement/subsidy/export
   * @summary 店铺补贴单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/subsidy/export_GET": (
    query: AdminStoreSettlementSubsidyExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/subsidy/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 店铺补贴单统计
   * @name GET /admin/store/settlement/subsidy/stat
   * @summary 店铺补贴单统计
   * @tags 店铺结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/subsidy/stat_GET": (
    query: AdminStoreSettlementSubsidyStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/subsidy/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 店铺应收订单分页查询
   * @name GET /admin/store/settlement/ysorders
   * @summary 店铺应收订单分页查询
   * @tags 店铺结算管理
   * @response `200` `JsonResultOrderLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/ysorders_GET": (query: AdminStoreSettlementYsordersGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/settlement/ysorders`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/store/settlement/ysorders/export
   * @summary 店铺应收订单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/ysorders/export_GET": (
    query: AdminStoreSettlementYsordersExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/ysorders/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 店铺应收订单统计
   * @name GET /admin/store/settlement/ysorders/stat
   * @summary 店铺应收订单统计
   * @tags 店铺结算管理
   * @response `200` `JsonResultStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/settlement/ysorders/stat_GET": (
    query: AdminStoreSettlementYsordersStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/settlement/ysorders/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultStatisticsDto>,
  /**
   * @description 订单快递单信息
   * @name GET /admin/store/shipping/express/info
   * @summary 订单快递单信息
   * @tags 店铺发货管理
   * @response `200` `JsonResultListOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/express/info_GET": (
    query: AdminStoreShippingExpressInfoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/shipping/express/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListOrderDto>,
  /**
   * @description 店铺待发货订单分页查询
   * @name GET /admin/store/shipping/list
   * @summary 店铺待发货订单分页查询
   * @tags 店铺发货管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/list_GET": (query: AdminStoreShippingListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/shipping/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name POST /admin/store/shipping/shipping
   * @summary 订单发货
   * @tags 店铺发货管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/shipping_POST": (data: ShipInfoParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/shipping/shipping`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/shipping/shipping/batch
   * @summary 订单批量发货
   * @tags 店铺发货管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/shipping/batch_POST": (data: BatchShipInfoParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/shipping/shipping/batch`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 订单配送单信息
   * @name GET /admin/store/shipping/shipping/info
   * @summary 订单配送单信息
   * @tags 店铺发货管理
   * @response `200` `JsonResultListOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/shipping/info_GET": (
    query: AdminStoreShippingShippingInfoGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/shipping/shipping/info`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListOrderDto>,
  /**
   * No description
   * @name PUT /admin/store/shipping/updateShipping
   * @summary 订单物流修改
   * @tags 店铺发货管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/updateShipping_PUT": (data: ShipInfoParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/shipping/updateShipping`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/shipping/uploadShipping
   * @summary 上传发货列表 返回成功列表
   * @tags 店铺发货管理
   * @response `200` `JsonResultListOrderShippingUploadPoiDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/shipping/uploadShipping_POST": (data: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/shipping/uploadShipping`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListOrderShippingUploadPoiDto>,
  /**
   * No description
   * @name POST /admin/transaction/auditResult
   * @summary 查看审核结果
   * @tags 自定义组件相关
   * @response `200` `JsonResultAuditResultVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/auditResult_POST": (query: AdminTransactionAuditResultPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/auditResult`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultAuditResultVo>,
  /**
   * No description
   * @name POST /admin/transaction/uploadImg
   * @summary 上传图片
   * @tags 自定义组件相关
   * @response `200` `JsonResultAuditResultVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/uploadImg_POST": (query: AdminTransactionUploadImgPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/uploadImg`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultAuditResultVo>,
  /**
   * No description
   * @name POST /admin/transaction/brandAudit/apply
   * @summary 上传品牌信息
   * @tags 自定义组件-品牌核相关
   * @response `200` `JsonResultAuditResultVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/brandAudit/apply_POST": (
    query: AdminTransactionBrandAuditApplyPostParams,
    data: TransactionBrandUploadParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/brandAudit/apply`,
      method: "POST",
      params: query,
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAuditResultVo>,
  /**
   * No description
   * @name POST /admin/transaction/brandAudit/auditDetail
   * @summary 自定义组件品牌审核详情
   * @tags 自定义组件-品牌核相关
   * @response `200` `JsonResultTransactionComponentBrandAuditDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/brandAudit/auditDetail_POST": (
    query: AdminTransactionBrandAuditAuditDetailPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/brandAudit/auditDetail`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultTransactionComponentBrandAuditDto>,
  /**
   * No description
   * @name POST /admin/transaction/brandAudit/query
   * @summary 品牌列表
   * @tags 自定义组件-品牌核相关
   * @response `200` `JsonResultPageInfoTransactionComponentBrandAuditDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/brandAudit/query_POST": (data: TransactionComponentBrandAuditDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/brandAudit/query`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoTransactionComponentBrandAuditDto>,
  /**
   * No description
   * @name POST /admin/transaction/category/audit/apply
   * @summary 上传类目信息
   * @tags 自定义组件-类目审核相关
   * @response `200` `JsonResultAuditResultVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/category/audit/apply_POST": (
    data: TransactionCategoryUploadPlatformParam,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/category/audit/apply`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultAuditResultVo>,
  /**
   * No description
   * @name POST /admin/transaction/category/audit/query
   * @summary 类目申请信息分页
   * @tags 自定义组件-类目审核相关
   * @response `200` `JsonResultPageInfoTransactionComponentCategoryVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/category/audit/query_POST": (
    data: TransactionComponencategoryAuditDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/category/audit/query`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoTransactionComponentCategoryVo>,
  /**
   * No description
   * @name POST /admin/transaction/category/audit/queryByAuditId
   * @summary 根据审核id查询类目详情
   * @tags 自定义组件-类目审核相关
   * @response `200` `JsonResultTransactionComponentCategoryVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/category/audit/queryByAuditId_POST": (
    query: AdminTransactionCategoryAuditQueryByAuditIdPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/category/audit/queryByAuditId`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultTransactionComponentCategoryVo>,
  /**
   * No description
   * @name POST /admin/transaction/category/audit/wechatCacheQuery
   * @summary 微信类目信息大全 如果不想不分页PageSize = 0
   * @tags 自定义组件-类目审核相关
   * @response `200` `JsonResultPageInfoTransactionComponentCategoryDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/category/audit/wechatCacheQuery_POST": (
    data: TransactionComponentCategoryDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/category/audit/wechatCacheQuery`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoTransactionComponentCategoryDto>,
  /**
   * No description
   * @name POST /admin/transaction/shop/spu/addSpu
   * @summary 添加商品信息
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultShopSpuUpdateRes` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/addSpu_POST": (data: SpuInfoParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/addSpu`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultShopSpuUpdateRes>,
  /**
   * No description
   * @name DELETE /admin/transaction/shop/spu/delAudit/{goodsNo}
   * @summary 撤回商品审核
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultBaseVo` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/transaction/shop/spu/delAudit/{goodsNo}_DELETE": (goodsNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/delAudit/${goodsNo}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name POST /admin/transaction/shop/spu/delSpu
   * @summary 删除商品
   * @tags 自定义组件商品相关
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/delSpu_POST": (data: BindMemberUsingPOSTMembernos, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/delSpu`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name PUT /admin/transaction/shop/spu/delisting/{goodsNo}
   * @summary 下架商品
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultShopSpuRes` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/delisting/{goodsNo}_PUT": (goodsNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/delisting/${goodsNo}`,
      method: "PUT",
      ...options
    }) as unknown as Promise<JsonResultShopSpuRes>,
  /**
   * No description
   * @name GET /admin/transaction/shop/spu/getSpu
   * @summary 商品信息
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultGetSpuRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/getSpu_GET": (
    query: AdminTransactionShopSpuGetSpuGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/getSpu`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultGetSpuRes>,
  /**
   * No description
   * @name PUT /admin/transaction/shop/spu/listing/{goodsNo}
   * @summary 上架商品
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultShopSpuRes` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/listing/{goodsNo}_PUT": (goodsNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/listing/${goodsNo}`,
      method: "PUT",
      ...options
    }) as unknown as Promise<JsonResultShopSpuRes>,
  /**
   * No description
   * @name POST /admin/transaction/shop/spu/querySpuList
   * @summary 商品列表
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultPageInfoTransactionComponenskuWechatRelDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/querySpuList_POST": (
    query: AdminTransactionShopSpuQuerySpuListPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/querySpuList`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoTransactionComponenskuWechatRelDto>,
  /**
   * No description
   * @name PUT /admin/transaction/shop/spu/updateWithoutAudit
   * @summary 更新商品（免审核更新商品字段）
   * @tags 自定义组件商品相关
   * @response `200` `JsonResultShopSpuUpdateRes` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/shop/spu/updateWithoutAudit_PUT": (
    query: AdminTransactionShopSpuUpdateWithoutAuditPutParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/shop/spu/updateWithoutAudit`,
      method: "PUT",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultShopSpuUpdateRes>,
  /**
   * @description 店铺核销员删除
   * @name DELETE /admin/verify/employee/del
   * @summary 店铺核销员删除
   * @tags 核销员工白名单
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/verify/employee/del_DELETE": (query: AdminVerifyEmployeeDelDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/employee/del`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销员分页查询
   * @name GET /admin/verify/employee/list
   * @summary 店铺核销员分页查询
   * @tags 核销员工白名单
   * @response `200` `JsonResultPageInfoMallVerifyEmployeeVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/employee/list_GET": (query: AdminVerifyEmployeeListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/employee/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallVerifyEmployeeVo>,
  /**
   * @description 店铺核销员新增
   * @name POST /admin/verify/employee/save
   * @summary 店铺核销员新增
   * @tags 核销员工白名单
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/employee/save_POST": (query: AdminVerifyEmployeeSavePostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/employee/save`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销员新增
   * @name POST /admin/verify/employee/sendCode
   * @summary 店铺核销员新增
   * @tags 核销员工白名单
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/employee/sendCode_POST": (query: AdminVerifyEmployeeSendCodePostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/employee/sendCode`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销组删除
   * @name DELETE /admin/verify/group/del/{id}
   * @summary 店铺核销组删除
   * @tags 核销组管理
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/verify/group/del/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/del/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销组员工删除
   * @name DELETE /admin/verify/group/employee/del/{id}
   * @summary 店铺核销组员工删除
   * @tags 核销组管理
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/verify/group/employee/del/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/employee/del/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销组商品分页查询
   * @name GET /admin/verify/group/goods
   * @summary 店铺核销组商品分页查询
   * @tags 核销组管理
   * @response `200` `JsonResultPageInfoMallGoodsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/group/goods_GET": (query: AdminVerifyGroupGoodsGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/goods`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallGoodsDto>,
  /**
   * @description 店铺核销组商品删除
   * @name DELETE /admin/verify/group/goods/del/{id}
   * @summary 店铺核销组商品删除
   * @tags 核销组管理
   * @response `200` `JsonResult` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/verify/group/goods/del/{id}_DELETE": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/goods/del/${id}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销组详情
   * @name GET /admin/verify/group/info/{id}
   * @summary 店铺核销组详情
   * @tags 核销组管理
   * @response `200` `JsonResultMallVerifyGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/group/info/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/info/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMallVerifyGroupVo>,
  /**
   * @description 店铺核销组分页查询
   * @name GET /admin/verify/group/list
   * @summary 店铺核销组分页查询
   * @tags 核销组管理
   * @response `200` `JsonResultPageInfoMallVerifyGroupVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/group/list_GET": (query: AdminVerifyGroupListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallVerifyGroupVo>,
  /**
   * @description 店铺核销组新增
   * @name POST /admin/verify/group/save
   * @summary 店铺核销组新增
   * @tags 核销组管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/group/save_POST": (data: MallVerifyGroupVoReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/save`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 店铺核销组修改
   * @name PUT /admin/verify/group/update
   * @summary 店铺核销组修改
   * @tags 核销组管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/verify/group/update_PUT": (data: MallVerifyGroupVoReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/verify/group/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/admin/aftermarket/userRefund
   * @summary 用户手工退款
   * @tags P-支付-售后
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/aftermarket/userRefund_POST": (data: RefundUserDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/aftermarket/userRefund`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/store/advance/listExcel
   * @summary 预售活动-excel
   * @tags M-店铺-预售活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/advance/listExcel_POST": (query: AdminStoreAdvanceListExcelPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/advance/listExcel`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/deleteXiaoneng
   * @summary 删除小能客服
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/sysUser/deleteXiaoneng_DELETE": (
    query: AdminApiSysUserDeleteXiaonengDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysUser/deleteXiaoneng`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallStore/notify/pageMsg
   * @summary pageMsg
   * @tags 店铺通知
   * @response `200` `JsonResultPageInfoMallNotifyVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallStore/notify/pageMsg_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallStore/notify/pageMsg`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallNotifyVo>,
  /**
   * No description
   * @name GET /admin/admin/order/verify/export
   * @summary 平台虚拟订单导出
   * @tags 平台订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/verify/export_GET": (query: AdminAdminOrderVerifyExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/verify/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 平台虚拟订单分页查询
   * @name GET /admin/admin/order/verify/list
   * @summary 平台虚拟订单分页查询
   * @tags 平台订单管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/order/verify/list_GET": (query: AdminAdminOrderVerifyListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/order/verify/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name GET /admin/store/order/verify/export
   * @summary 店铺虚拟订单导出,导出全部数据需要指定pageSize=0
   * @tags 店铺订单管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/verify/export_GET": (query: AdminStoreOrderVerifyExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/verify/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 店铺虚拟订单分页查询
   * @name GET /admin/store/order/verify/list
   * @summary 店铺虚拟订单分页查询
   * @tags 店铺订单管理
   * @response `200` `JsonResultPageInfoOrderDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/order/verify/list_GET": (query: AdminStoreOrderVerifyListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/order/verify/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDto>,
  /**
   * No description
   * @name PUT /admin/mallCouponTemplate/clickIsPublic
   * @summary 切换是否公开状态
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/clickIsPublic_PUT": (data: MallCouponTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/clickIsPublic`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallCouponTemplate/syncVideoNumber
   * @summary 同步优惠券数据到视频号中
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/syncVideoNumber_POST": (
    data: MallCouponTemplateSynVideoNum,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/syncVideoNumber`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/transaction/coupon/add
   * @summary 优惠劵--添加
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/add_POST": (data: CouponAddOrUpdateReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name POST /admin/transaction/coupon/addUserCoupon
   * @summary 优惠劵--添加用户优惠券
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/addUserCoupon_POST": (data: CouponAddOrUpdateUserCouponReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/addUserCoupon`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name GET /admin/transaction/coupon/get
   * @summary 优惠劵--获取优惠券信息
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultCouponGetRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/get_GET": (query: AdminTransactionCouponGetGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/get`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultCouponGetRes>,
  /**
   * No description
   * @name GET /admin/transaction/coupon/getList
   * @summary 优惠劵--获取优惠券列表
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultCouponGetListRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/getList_GET": (
    query: AdminTransactionCouponGetListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/coupon/getList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultCouponGetListRes>,
  /**
   * No description
   * @name GET /admin/transaction/coupon/getPromotersList
   * @summary 优惠劵--获取推广员列表
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultPromotersGetListRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/getPromotersList_GET": (
    query: AdminTransactionCouponGetPromotersListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/coupon/getPromotersList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPromotersGetListRes>,
  /**
   * No description
   * @name GET /admin/transaction/coupon/getUserCouponList
   * @summary 优惠劵--获取用户优惠券列表
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultCouponGetUserCouponListRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/getUserCouponList_GET": (data: CouponGetUserCouponListReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/getUserCouponList`,
      method: "GET",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultCouponGetUserCouponListRes>,
  /**
   * No description
   * @name PUT /admin/transaction/coupon/update
   * @summary 优惠劵--修改
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/update_PUT": (data: CouponAddOrUpdateReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name PUT /admin/transaction/coupon/updateStatus
   * @summary 优惠劵--修改状态
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/updateStatus_PUT": (data: CouponUpdateStatusReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/updateStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name PUT /admin/transaction/coupon/updateStock
   * @summary 优惠劵--修改库存
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/updateStock_PUT": (data: CouponUpdateStockReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/transaction/coupon/updateStock`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name PUT /admin/transaction/coupon/updateUserCoupon
   * @summary 优惠劵--更新用户优惠券
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/updateUserCoupon_PUT": (
    data: CouponAddOrUpdateUserCouponReq,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/coupon/updateUserCoupon`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name PUT /admin/transaction/coupon/updateUserCouponStatus
   * @summary 优惠劵--更新用户优惠券状态
   * @tags 自定义组件优惠券相关
   * @response `200` `JsonResultBaseVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/transaction/coupon/updateUserCouponStatus_PUT": (
    data: CouponUpdateUserCouponStatusReq,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/transaction/coupon/updateUserCouponStatus`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBaseVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryFullActivityDetailsExcels
   * @summary 查询满减满折该活动下店铺以及商品详情的Excels导出
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryFullActivityDetailsExcels_GET": (
    query: AdminAdminMarketingActivityApplyQueryFullActivityDetailsExcelsGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryFullActivityDetailsExcels`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/api/log/query
   * @summary 日志表分页查询
   * @tags 系统-日志管理
   * @response `200` `JsonResultPageInfoOperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/log/query_GET": (query: AdminApiLogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/log/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOperationLog>,
  /**
   * No description
   * @name POST /admin/api/mallConfPage/add
   * @summary 自定义页面新增
   * @tags 自定义页面
   * @response `200` `JsonResultLong` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/add_POST": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/add`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfPage/delete
   * @summary 自定义页面删除
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `204` `OperationLog` No Content |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden
   */
  "/admin/api/mallConfPage/delete_DELETE": (
    query: AdminApiMallConfPageDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/mallConfPage/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/detail
   * @summary 自定义页面内容详情
   * @tags 自定义页面
   * @response `200` `JsonResultMallConfPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/detail_GET": (query: AdminApiMallConfPageDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfPageVo>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/issue/{confPageId}
   * @summary 页面根据ID立即发布
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/issue/{confPageId}_PUT": (confPageId: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/issue/${confPageId}`,
      method: "PUT",
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/promptlyIssue
   * @summary 根据草稿立即发布
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/promptlyIssue_PUT": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/promptlyIssue`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/pushSchedule
   * @summary 设定本自定义页面定时发布计划
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/pushSchedule_PUT": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/pushSchedule`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/query
   * @summary 自定义页面分页查询
   * @tags 自定义页面
   * @response `200` `JsonResultPageInfoMallConfPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/query_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/query`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfPageVo>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/queryAll
   * @summary 自定义页面不分页查询
   * @tags 自定义页面
   * @response `200` `JsonResultListMallConfPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfPageVo>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/update
   * @summary 自定义页面修改
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/update_PUT": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/update`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/update/draft
   * @summary 保存/更新草稿
   * @tags 自定义页面
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/update/draft_PUT": (data: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/update/draft`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/queryTimeBuyPendingDetailsExcels
   * @summary 查询限时抢购分页该活动下店铺以及商品详情的Excels导出
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/queryTimeBuyPendingDetailsExcels_GET": (
    query: AdminAdminMarketingActivityApplyQueryTimeBuyPendingDetailsExcelsGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/queryTimeBuyPendingDetailsExcels`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/marketing/drawActivity/platform
   * @summary 查询所有店铺的所有抽奖活动
   * @tags 抽奖活动
   * @response `200` `JsonResultPageInfoMarketingStoreActivityVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/marketing/drawActivity/platform_GET": (
    query: AdminMarketingDrawActivityPlatformGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/marketing/drawActivity/platform`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingStoreActivityVo>,
  /**
   * No description
   * @name GET /admin/api/admin/log/query
   * @summary 日志表分页查询
   * @tags 系统-日志管理
   * @response `200` `JsonResultPageInfoOperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/admin/log/query_GET": (query: AdminApiAdminLogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/admin/log/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOperationLog>,
  /**
   * No description
   * @name GET /admin/api/store/log/query
   * @summary 日志表分页查询
   * @tags 系统-店铺日志管理
   * @response `200` `JsonResultPageInfoOperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/store/log/query_GET": (query: AdminApiStoreLogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/store/log/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOperationLog>,
  /**
   * No description
   * @name POST /admin/admin/checkaccount/platform
   * @summary 平台对账单
   * @tags P-支付-对账单
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/checkaccount/platform_POST": (data: CheckAccountPlatformDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/checkaccount/platform`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/admin/checkaccount/store
   * @summary 店铺对账单
   * @tags P-支付-对账单
   * @response `200` `JsonResultString` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/checkaccount/store_POST": (data: CheckAccountStoreDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/checkaccount/store`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * @description 平台补贴单/抽佣数据组装
   * @name PUT /admin/admin/settlement/platformSubsidyCommission
   * @summary 平台补贴单/抽佣数据组装
   * @tags 平台结算管理
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/platformSubsidyCommission_PUT": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/platformSubsidyCommission`,
      method: "PUT",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description 平台补贴单/抽佣分页查询
   * @name GET /admin/admin/settlement/subsidyAndCommission
   * @summary 平台补贴单/抽佣分页查询
   * @tags 平台结算管理
   * @response `200` `JsonResultSubsidyAndCommissionLedgerResultDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidyAndCommission_GET": (
    query: AdminAdminSettlementSubsidyAndCommissionGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidyAndCommission`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSubsidyAndCommissionLedgerResultDto>,
  /**
   * No description
   * @name GET /admin/admin/settlement/subsidyAndCommission/export
   * @summary 平台补贴单/抽佣导出,导出全部数据需要指定pageSize=0
   * @tags 平台结算管理
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidyAndCommission/export_GET": (
    query: AdminAdminSettlementSubsidyAndCommissionExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidyAndCommission/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 平台补贴单/抽佣统计
   * @name GET /admin/admin/settlement/subsidyAndCommission/stat
   * @summary 平台补贴单/抽佣统计
   * @tags 平台结算管理
   * @response `200` `JsonResultSubsidyAndCommissionStatisticsDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/settlement/subsidyAndCommission/stat_GET": (
    query: AdminAdminSettlementSubsidyAndCommissionStatGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/settlement/subsidyAndCommission/stat`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSubsidyAndCommissionStatisticsDto>,
  /**
   * No description
   * @name GET /admin/pay/balance/initAccountBalance
   * @summary 初始化同步所有资金账号的交易明细
   * @tags P-支付-账户资金明细
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/balance/initAccountBalance_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/balance/initAccountBalance`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/balance/queryPayAccountBalance
   * @summary 账户资金查询
   * @tags P-支付-账户资金明细
   * @response `200` `JsonResultListPayAccountBalanceVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/balance/queryPayAccountBalance_GET": (
    query: AdminPayBalanceQueryPayAccountBalanceGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/balance/queryPayAccountBalance`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListPayAccountBalanceVo>,
  /**
   * No description
   * @name GET /admin/pay/balance/syncYesterdayBalance
   * @summary 同步昨天所有资金账号的交易明细
   * @tags P-支付-账户资金明细
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/balance/syncYesterdayBalance_GET": (
    query: AdminPayBalanceSyncYesterdayBalanceGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/balance/syncYesterdayBalance`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/balance/initBill
   * @summary 初始化账单数据
   * @tags P-支付-账户资金明细
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/balance/initBill_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/balance/initBill`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getAllMarginAccountInfo
   * @summary 所有店铺账户冻结资金和可提现资金
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getAllMarginAccountInfo_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getAllMarginAccountInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/pay/accountInfo/getAllSubsidyAccountInfo
   * @summary 所有店铺营销账户冻结资金和可提现资金
   * @tags P-支付-店铺账户资金查询
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/accountInfo/getAllSubsidyAccountInfo_GET": (
    params: Record<string, any> = {},
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/pay/accountInfo/getAllSubsidyAccountInfo`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/admin/aftermarket/findRefundResult
   * @summary 退款状态查询
   * @tags P-支付-售后
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/aftermarket/findRefundResult_GET": (
    query: AdminAdminAftermarketFindRefundResultGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/aftermarket/findRefundResult`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/job/sysdatacube/detail
   * @summary 导出数据详情
   * @tags 数据中心表
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/job/sysdatacube/detail_GET": (query: AdminJobSysdatacubeDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/job/sysdatacube/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * @description 店铺活动列表
   * @name GET /admin/store/MarketingActivity/list
   * @summary 店铺活动列表
   * @tags 限时抢购活动功能
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivity/list_GET": (
    query: AdminStoreMarketingActivityListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivity/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * @description 满减满折活动列表
   * @name GET /admin/store/MarketingActivity/disCount/list
   * @summary 满减满折活动列表
   * @tags 限时抢购活动功能
   * @response `200` `JsonResultPageInfoMarketingActivityPageVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MarketingActivity/disCount/list_GET": (
    query: AdminStoreMarketingActivityDisCountListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/store/MarketingActivity/disCount/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMarketingActivityPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/getDetailByStore/export
   * @summary 获取审核店铺详情信息导出
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/getDetailByStore/export_GET": (
    query: AdminAdminMarketingActivityApplyGetDetailByStoreExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/getDetailByStore/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/api/mallConfPage/queryByCondition
   * @summary 自定义页面分页查询通过店铺
   * @tags 自定义页面
   * @response `200` `JsonResultPageInfoMallConfPageVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/mallConfPage/queryByCondition_POST": (data: MallConfPageQuery, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/queryByCondition`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfPageVo>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/discountSale/audit/export
   * @summary 满减满折审核导出
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/discountSale/audit/export_GET": (
    query: AdminAdminMarketingActivityApplyDiscountSaleAuditExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/discountSale/audit/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/admin/MarketingActivityApply/timeBuy/audit/export
   * @summary 限时抢购审核导出
   * @tags 平台配置活动
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MarketingActivityApply/timeBuy/audit/export_GET": (
    query: AdminAdminMarketingActivityApplyTimeBuyAuditExportGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/admin/MarketingActivityApply/timeBuy/audit/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/api/admin/log/query/modular
   * @summary 获得所有模块
   * @tags 系统-日志管理
   * @response `200` `JsonResultListString` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/admin/log/query/modular_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/admin/log/query/modular`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListString>,
  /**
   * No description
   * @name GET /admin/api/yihuitong/openYihuitongStore
   * @summary 开通Yihuitong客服商铺
   * @tags Y-yihuitong客服
   * @response `200` `JsonResultInsertTenantAndWaiterRes` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/yihuitong/openYihuitongStore_GET": (
    query: AdminApiYihuitongOpenYihuitongStoreGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/yihuitong/openYihuitongStore`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInsertTenantAndWaiterRes>,
  /**
   * No description
   * @name GET /admin/api/yihuitong/openYihuitongUser
   * @summary 开通Yihuitong客服账号
   * @tags Y-yihuitong客服
   * @response `200` `JsonResult` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/yihuitong/openYihuitongUser_GET": (
    query: AdminApiYihuitongOpenYihuitongUserGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/yihuitong/openYihuitongUser`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/event
   * @summary acceptHandler
   * @tags event-source-controller
   * @response `200` `OperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/event_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/event`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * No description
   * @name POST /admin/event
   * @summary receiveHandler
   * @tags event-source-controller
   * @response `200` `string` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/event_POST": (data: MallNotifyVo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/event`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<string>,
  /**
   * No description
   * @name GET /admin/nacos/status
   * @summary status
   * @tags nacos-check-controller
   * @response `200` `OperationLog` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/nacos/status_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/nacos/status`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * No description
   * @name POST /admin/extracted
   * @summary extracted
   * @tags async-excel-util
   * @response `200` `OperationLog` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/extracted_POST": (query: AdminExtractedPostParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/extracted`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<OperationLog>,
  /**
   * No description
   * @name POST /admin/pay/apply/bindingCardAndSign
   * @summary 4-绑卡并签约申请
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultSignInNetVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/bindingCardAndSign_POST": (data: BindingCardAndSignDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/bindingCardAndSign`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultSignInNetVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/bindingCardPerson
   * @summary 4-绑卡申请-个体户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResultBindCardVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/bindingCardPerson_POST": (data: AccountApplyBindingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/bindingCardPerson`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultBindCardVo>,
  /**
   * No description
   * @name POST /admin/pay/apply/openChildAccount
   * @summary 开户-保证金与营销账户开户
   * @tags P-支付-资金账户开户
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/pay/apply/openChildAccount_POST": (data: OpenChildAccountDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/pay/apply/openChildAccount`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/yihuitong/batchOpenYihuitongUser
   * @summary 批量开通客服,用于迁移数据
   * @tags Y-yihuitong客服
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/api/yihuitong/batchOpenYihuitongUser_POST": (
    data: BindMemberUsingPOSTMembernos,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/yihuitong/batchOpenYihuitongUser`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name PUT /admin/admin/sysdatacube
   * @summary 修改文件记录
   * @tags 数据中心表
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysdatacube_PUT": (data: SysDataCubeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysdatacube`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/admin/sysdatacube/myStore
   * @summary 本店铺的数据任务下载列表
   * @tags 数据中心表
   * @response `200` `JsonResultListSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysdatacube/myStore_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysdatacube/myStore`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDataCubeDto>,
  /**
   * No description
   * @name GET /admin/admin/sysdatacube/detail
   * @summary 导出数据详情
   * @tags 数据中心表
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysdatacube/detail_GET": (query: AdminAdminSysdatacubeDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysdatacube/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/sysdatacube/query
   * @summary 数据中心表分页查询
   * @tags 数据中心表
   * @response `200` `JsonResultPageInfoSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysdatacube/query_POST": (data: SysDataCubeQueryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysdatacube/query`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/sysdatacube/queryAll
   * @summary 数据列表查询
   * @tags 数据中心表
   * @response `200` `JsonResultListSysDataCubeDto` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/sysdatacube/queryAll_POST": (data: SysDataCubeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/sysdatacube/queryAll`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultListSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/admin/apply/company/bindingCardAndSign
   * @summary 绑卡并签约申请
   * @tags 资金账户开户-企业
   * @response `200` `JsonResultSignInNetVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/apply/company/bindingCardAndSign_POST": (data: BindingCardAndSignDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/apply/company/bindingCardAndSign`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultSignInNetVo>,
  /**
   * No description
   * @name POST /admin/admin/apply/company/openChildAccount
   * @summary 开户-保证金与营销账户开户
   * @tags 资金账户开户-企业
   * @response `200` `JsonResult` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/apply/company/openChildAccount_POST": (data: OpenChildAccountDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/apply/company/openChildAccount`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/admin/apply/company/verifyUpload
   * @summary 校对当前店铺的影印件审核状态
   * @tags 资金账户开户-企业
   * @response `200` `JsonResultPayAccountApplyVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/apply/company/verifyUpload_POST": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/apply/company/verifyUpload`,
      method: "POST",
      params,
      ...options
    }) as unknown as Promise<JsonResultPayAccountApplyVo>,
  /**
   * No description
   * @name POST /admin/mallCouponTemplate/uploadDistributeCoupon
   * @summary 后台导入excel  发放优惠券
   * @tags 优惠劵
   * @response `200` `JsonResultDistributeCouponVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/uploadDistributeCoupon_POST": (data: object, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/uploadDistributeCoupon`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultDistributeCouponVo>,
  /**
   * No description
   * @name PUT /admin/mallCouponTemplate/selectBestvCard
   * @summary 保存百视TV卡片
   * @tags 优惠劵
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/selectBestvCard_PUT": (data: MallCouponTemplateAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/selectBestvCard`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstore/getStoreSpecialNum/{storeNo}
   * @summary 获取店铺特殊功能数量
   * @tags 店铺
   * @response `200` `JsonResultInt` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/getStoreSpecialNum/{storeNo}_GET": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/getStoreSpecialNum/${storeNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/mallstore/info/{storeNo}
   * @summary 简单店铺信息  平台端可使用
   * @tags 店铺
   * @response `200` `JsonResultMallStoreDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/info/{storeNo}_GET": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/info/${storeNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultMallStoreDto>,
  /**
   * No description
   * @name GET /admin/mallstore/listMallStoreSpecial
   * @summary 获取店铺特殊功能列表
   * @tags 店铺
   * @response `200` `JsonResultListMallStoreSpecialVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/listMallStoreSpecial_GET": (
    query: AdminMallstoreListMallStoreSpecialGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallstore/listMallStoreSpecial`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallStoreSpecialVo>,
  /**
   * No description
   * @name PUT /admin/mallstore/selectSpecialType
   * @summary 保存特殊功能
   * @tags 店铺
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/selectSpecialType_PUT": (data: MallStoreDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/selectSpecialType`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallstore/listMallStoreSpecial/{storeNo}
   * @summary 获取店铺特殊功能列表
   * @tags 店铺
   * @response `200` `JsonResultListMallStoreSpecialVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallstore/listMallStoreSpecial/{storeNo}_GET": (storeNo: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallstore/listMallStoreSpecial/${storeNo}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultListMallStoreSpecialVo>,
  /**
   * No description
   * @name GET /admin/mallCouponTemplate/getBestvCardList
   * @summary 获取百视TV卡片列表
   * @tags 优惠劵
   * @response `200` `JsonResultResultDTO` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/mallCouponTemplate/getBestvCardList_GET": (
    query: AdminMallCouponTemplateGetBestvCardListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/mallCouponTemplate/getBestvCardList`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultResultDTO>,
  /**
   * No description
   * @name GET /admin/admin/MallGoods/ceshi
   * @summary 测试金龙卡
   * @tags E-平台-商品模块
   * @response `200` `JsonResultResultDTO` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/ceshi_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoods/ceshi`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultResultDTO>,
  /**
   * No description
   * @name PUT /admin/store/MallGoods/updateDragonDeduct
   * @summary 修改抵扣金龙卡状态
   * @tags E-店铺-商品模块
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/store/MallGoods/updateDragonDeduct_PUT": (data: MallGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/store/MallGoods/updateDragonDeduct`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/memDragonCard/export
   * @summary 金龙卡明细列表导出(直接返回下载地址)
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultSysDataCubeDto` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/export_GET": (query: AdminMemDragonCardExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memDragonCard/export`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysDataCubeDto>,
  /**
   * No description
   * @name POST /admin/memDragonCard/page
   * @summary 金龙卡明细列表-分页
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultPageInfoMemDragonCardRecordVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/page_POST": (data: MemDragonCardRecordDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memDragonCard/page`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemDragonCardRecordVo>,
  /**
   * No description
   * @name GET /admin/memDragonCard/page
   * @summary 金龙卡明细列表-分页
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultPageInfoMemDragonCardRecordVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/page_GET": (query: AdminMemDragonCardPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memDragonCard/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemDragonCardRecordVo>,
  /**
   * No description
   * @name POST /admin/memDragonCard/refundReviewDetail
   * @summary 金龙卡退款审核详情
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultRefundReviewVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/refundReviewDetail_POST": (
    query: AdminMemDragonCardRefundReviewDetailPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memDragonCard/refundReviewDetail`,
      method: "POST",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundReviewVo>,
  /**
   * No description
   * @name POST /admin/memDragonCard/refundReviewPage
   * @summary 金龙卡退款审核列表-分页
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultPageInfoRefundReviewVo` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/refundReviewPage_POST": (data: RefundReviewDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memDragonCard/refundReviewPage`,
      method: "POST",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRefundReviewVo>,
  /**
   * No description
   * @name GET /admin/memDragonCard/refundReviewPage
   * @summary 金龙卡退款审核列表-分页
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultPageInfoRefundReviewVo` OK |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/refundReviewPage_GET": (
    query: AdminMemDragonCardRefundReviewPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/memDragonCard/refundReviewPage`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRefundReviewVo>,
  /**
   * No description
   * @name PUT /admin/memDragonCard/verifyRefund
   * @summary 退款审核
   * @tags 后台金龙卡明细
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/memDragonCard/verifyRefund_PUT": (data: RefundReviewDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/memDragonCard/verifyRefund`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/admin/MallGoods/updateDragonDeduct
   * @summary 修改抵扣金龙卡状态
   * @tags E-平台-商品模块
   * @response `200` `JsonResultVoid` OK |  `201` `OperationLog` Created |  `401` `OperationLog` Unauthorized |  `403` `OperationLog` Forbidden |  `404` `OperationLog` Not Found
   */
  "/admin/admin/MallGoods/updateDragonDeduct_PUT": (data: MallGoodsParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/admin/MallGoods/updateDragonDeduct`,
      method: "PUT",
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>
};
