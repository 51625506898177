import { Form } from 'antd'
import { FC, memo, useMemo } from 'react'
import { EFormProItem } from '../../../../enums/EFormProItem'
import { isEmail, isMobilePhone } from '../../../../utils/validator'
import { FormProItemProps } from './const'
import useRules from './hooks/useRules'
import FormProItemItem from './Item'

const { Item } = Form

const Component: FC<FormProItemProps & { index?: number }> = (item) => {
  const {
    type,
    index = 1,
    rules,
    // 以下参数不要传递给Item
    componentProp,
    customRender,
    suffixText,
    prefixText,
    valueFormat,
    renderChildren,
    name,
    shouldUpdate = true,
    ...rest
  } = item as any

  const valuePropName =
    {
      [EFormProItem.Switch]: 'checked'
    }[item.type] || undefined

  const itemProps = {
    key: `${item.label}-${item.name}-${index}`,
    valuePropName,
    ...rest
  }

  const itemRules = useRules(rules, type)

  return type === EFormProItem.RenderChildren ? (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {renderChildren}
    </Form.Item>
  ) : (
    <Item {...itemProps} name={name} rules={itemRules} validateFirst>
      <FormProItemItem {...item} />
    </Item>
  )
}

Component.displayName = 'FormProItem'

const FormProItem = memo(Component)
export default FormProItem
