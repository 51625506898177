export enum EColorPickerType {
  /**
   * 推特风格
   */
  Twitter,
  /**
   * Sketch风格
   */
  Sketch,
}
