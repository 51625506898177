import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.managementGroup,
    component: () => import('../../pages/management/managementGroup'),
    meta: {
      title: '直播间组管理',
      code: [EAuthCode.MANAGEMENTGROUP_LIST]
    }
  },
  {
    path: routeNames.managementGroupDetails,
    component: () => import('../../pages/management/managementGroupDetails'),
    meta: {
      title: '直播详情', hideInMenu: true,
      code: [EAuthCode.MANAGEMENTGROUP_LIST_ADD,EAuthCode.MANAGEMENTGROUP_LIST_UPDATE]
    }
  },
  {
    path: routeNames.broadcastingRoom,
    component: () => import('../../pages/management/broadcastingRoom'),
    meta: {
      title: '直播间管理',
      code: [EAuthCode.BROADCASTINGROOM_LIST]
    }
  },
  {
    path: routeNames.managementAnchor,
    component: () => import('../../pages/management/managementAnchor'),
    meta: {
      title: '主播管理',
      code: [EAuthCode.MANAGEMENTANCHOR_LIST]
    }
  }
] as TRoutes[]
