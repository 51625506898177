import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

const routes: TRoutes[] = [
  {
    path: routeNames.memberManagementCustomerList,
    component: () => import('../../pages/memberManagement/customer/list'),
    meta: {
      title: '客户信息',
      code: EAuthCode.MEMBER_INFO
    }
  },
  {
    path: routeNames.memberManagementCustomerDetail,
    component: () => import('../../pages/memberManagement/customer/detail'),
    meta: {
      title: '客户详情',
      hideInMenu: true,
      code: [
        EAuthCode.MEMBER_INFO_DETAIL,
        EAuthCode.FINANCE_RECEIVABLEORDER_MEMBERDETAIL,
        EAuthCode.FINANCE_RECEIVEDORDER_MEMBERDETAIL,
        EAuthCode.FINANCE_REFUNDORDER_MEMBERDETAIL,
        EAuthCode.FINANCE_PLATFORMSUBSIDY_MEMBERDETAIL
      ]
    }
  },
  {
    path: routeNames.memberManagementMemberCardList,
    component: () => import('../../pages/memberManagement/memberCard/list'),
    meta: {
      title: '会员卡信息',
      code: EAuthCode.MEMBER_CARDINFO
    }
  },
  {
    path: routeNames.memberManagementMemberCardDetail,
    component: () => import('../../pages/memberManagement/memberCard/detail'),
    meta: {
      title: '会员卡详情',
      hideInMenu: true,
      code: EAuthCode.MEMBER_CARDINFO_DETAIL
    }
  },
  {
    path: routeNames.memberManagementLevelSettingList,
    component: () => import('../../pages/memberManagement/mevelSetting/list'),
    meta: {
      title: '会员卡等级设置',
      code: EAuthCode.MEMBER_CARDLEVELSET

    }
  },
  {
    path: routeNames.memberManagementmevelSettingDetail,
    component: () => import('../../pages/memberManagement/mevelSetting/detail'),
    meta: {
      title: '基本信息',
      hideInMenu: true,
      code: EAuthCode.MEMBER_CARDLEVELSET_UPDATE
    }
  },
  {
    path: routeNames.memberManagementmevelRightsAndinterests,
    component: () => import('../../pages/memberManagement/rightsAndinterests'),
    meta: {
      title: '权益列表',
      code: EAuthCode.MEMBER_RIGHTSLIST
    }
  },
  {
    path: routeNames.memberManagementmevelLabelManagement,
    component: () => import('../../pages/memberManagement/labelManagement'),
    meta: {
      title: '标签管理',
      code: EAuthCode.MEMBER_TAGS
    }
  }
]

export default routes;