import { FC, memo, useEffect, useRef, useState } from 'react'
import { FormInstance, Card, Button, Image, Modal, message, Input } from 'antd'
import CryptoJS from 'crypto-js'
import { RouteComponentProps } from 'react-router'
import FormPro from '~/modules/@wmeimob/backend-pro/src/components/formPro'
import { EFormProItem } from '~/modules/@wmeimob/backend-pro/src/enums/EFormProItem'
import useFormPro from '~/modules/@wmeimob/backend-pro/src/hooks/useFormPro'
import styles from './index.module.less'
import { api } from '~/request'
import images from '~/assets/images/suo.png'
import { useSuperLock } from '~/hooks/useSuperLock'
import Item from 'antd/lib/descriptions/Item'
import Countdown from 'antd/lib/statistic/Countdown'

export interface IPageProps extends RouteComponentProps { }

const Component: FC<IPageProps> = (props) => {
  const { history } = props

  const [visible, setVisible] = useState(false)
  const [phone, setMobile] = useState('')
  const formRef = useRef<FormInstance>(null)
  const [showCountDown, setShowCountDown] = useState(false)
  const getmobile= async()=>{
    const {code,data} = await api['/admin/auth/query_GET']()
    console.log(data.mobile);
    
    if(data.mobile){
      setMobile(data.mobile)
    }
  }
  useEffect(() => {
    getmobile()
  }, [])
  const handleCodeClick = async () => {
    const value = await formRef.current?.getFieldsValue().mobile
    
    console.log(value);
    
    try {
      setShowCountDown(true)
      await api['/admin/sms/verifyCode_GET']({ mobile:value, scene: 'BACKGROUND_CHANGE_PASSWORD' })
      message.success('验证码已经发送')
    } catch (error) {
      message.error('验证码发送失败')
      setShowCountDown(false)
    }
  }
  const { option: formPro } = useFormPro({
    items: [
      // {
      //   label: '手机号',
      //   type: EFormProItem.Input,
      //   name: 'mobile',
      //   rules: [{ required: true, max: 20 }],
      //   addonAfter: <div>{
      //     showCountDown ?
      //     <Countdown value={Date.now() + 60000} valueStyle={{ fontSize: 14, color: '#cccccc' }} format="ss" onFinish={() => setShowCountDown(false)} />
      //       :
      //       <div onClick={handleCodeClick}>获取验证码</div>

      //   }</div>,
      //   componentProp: {
      //     placeholder: '请输入手机号',
      //     type: 'number',
      //     disabled:true,
      //     allowClear:true
      //   },
      // },
      {
        label: '手机号',
        type: EFormProItem.Input,
        name: 'mobile',
        rules: [{ required: true, max: 20 }],
        addonAfter: <div>{
        showCountDown ?
          <Countdown value={Date.now() + 60000} valueStyle={{ fontSize: 14, color: '#cccccc' }} format="ss" onFinish={() => setShowCountDown(false)} />
            :
            <div onClick={handleCodeClick}>获取验证码</div>

        }</div>,
        componentProp: {
          placeholder: '请输入手机号',
          type: 'number',
          disabled:true,
          allowClear:true
        }
      },
      {
        label: '验证码',
        type: EFormProItem.Input,
        name: 'code',
        rules: [{ required: true, max: 20 }],
        componentProp: {
          placeholder: '请输入验证码',
          type: 'number',
          allowClear:true
        }
      },
      {
        label: '新的密码',
        type: EFormProItem.Input,
        name: 'newPassword',
        hasFeedback: true,
        rules: [
          { required: true, max: 20, min: 9 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value.length >= 9 && /^(?=.*[0-9])(?=.*[a-z])(?=.*["_+=!@#$%&*--"])(?=.*[A-Z]).{9,20}$/.test(value)) {
                return Promise.resolve()
              }
              return Promise.reject('9~20位密码，须包含大小写，特殊字符')
            }
          })
        ],
        componentProp: {
          placeholder: '请输入新的密码',
          type: 'password',
          allowClear:true
        }
      },
      {
        label: '再次输入',
        type: EFormProItem.Input,
        name: 'newPassword2',
        dependencies: ['password'],
        hasFeedback: true,
        rules: [
          { required: true, max: 20, min: 6 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('两次输入密码不一致'))
            }
          })
        ],
        componentProp: {
          placeholder: '请再次输入密码',
          type: 'password',
          allowClear:true
        }
      }
    ]
  })

  const [handleClick, handleLoading] = useSuperLock(async () => {
    if (formRef.current) {
      const values = await formRef.current.validateFields()
      const encNewPassword = CryptoJS.enc.Utf8.parse(values.newPassword)
      const encPassword = CryptoJS.enc.Utf8.parse(values.password)
      const key = CryptoJS.enc.Utf8.parse('sdidASE5F41S5Dm7')
      const newPassword = CryptoJS.AES.encrypt(encNewPassword, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
      const password = CryptoJS.AES.encrypt(encPassword, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()
      const code=values.code
      const mobile=values.mobile
      
      await api['/admin/auth/resetPwd_PUT']({ mobile,code,newPassword })
      setVisible(false)
      message.success('密码修改成功，下次登录生效。')
      history.goBack()
    }
  })

  return (
    <Card className={styles.component}>
      <div className={styles.head}>您当前的帐号 ：{phone}</div>
      <div className={styles.text}>
        <Image className={styles.Image} src={images} />
        <div className={styles.div}>
          <h2>登录密码</h2>
          <p>建议您定期更换密码，设置安全性高的密码可以使帐号更安全</p>
        </div>
        <Button onClick={() => {
          setVisible(true)
          setTimeout(() => {
            formRef.current?.setFieldsValue({
              mobile: phone
            })
          }, 100)
        }}>修改</Button>
      </div>
      <div className={styles.footer}>
        <h2>安全服务提示</h2>
        <div>• 建议您安装杀毒软件，并定期更新操作系统等软件补丁，确保帐号及交易安全</div>
      </div>
      <Modal title="修改密码" confirmLoading={handleLoading} visible={visible} onOk={handleClick} onCancel={() => setVisible(false)} maskClosable={false}>

        <FormPro ref={formRef} {...formPro} />
      </Modal>
    </Card>
  )
}

Component.defaultProps = {}
Component.displayName = 'ChangePassword'

const ChangePassword = memo(Component)
export default ChangePassword
