const { REACT_APP_ENV } = process.env
export const isDevelopment = REACT_APP_ENV === 'development'
export const isUat = REACT_APP_ENV === 'uat'

export const apiUrl = isDevelopment ? '' : isUat ? 'https://dfl2.wmeimob.cn' : 'https://planet.orientalmall.com.cn'
export const h5Url = isDevelopment ? 'https://dfl1.wmeimob.cn' : isUat ? 'https://dfl1.wmeimob.cn' : 'https://m.orientalmall.com.cn'

export const h5Path = {
  home: '/pages/home/index', // 首页
  shopHome: '/pages/shop/shopDetail/index', // 店铺详情首页
  custom: '/pages/custom/index', // 装修页面
  preview: '/pages/preview/index' // 预览页面
}

export const aliyunOssUrl = 'https://ocj-uat.oss-cn-shanghai.aliyuncs.com'

export const aliyunOssTokenUrl = `/admin/oss/info`

export const defaultPwd = 'Ocj_Dfl_2024' // 默认密码

export const publicPath = '/'

/**
 * 是否跳过权限验证
 * @warning 允许在接口无法调用时继续本地开发.只允许在本地开发时使用
 */
export const skipAuth = process.env.NODE_ENV === 'development' && false

/**
 * 配置栏配置属性
 *
 * @description 请从配置栏目中拷贝替换
 */
export const systemConfig: SystemInfo = {
  layoutSetting: {
    menuTheme: 'dark',
    headerTheme: 'light'
  },
  breadcrumb: {
    type: 'simple'
  }
}
