import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.platformAccount,
    component: () => import('../../pages/financialManagement/platformAccount'),
    meta: {
      title: '平台账户',
      code: [EAuthCode.FINANCE_PLATFORMACCOUNT]
    }
  },
  {
    path: routeNames.platformMarketingAccount,
    component: () => import('../../pages/financialManagement/platformMarketingAccount'),
    meta: {
      title: '平台营销账户',
      code: [EAuthCode.FINANCE_PLATFORMMARKETINGACCOUNT]
    }
  },
  {
    path: routeNames.platformDepositAccount,
    component: () => import('../../pages/financialManagement/platformDepositAccount'),
    meta: {
      title: '平台保证金账户',
      code: [EAuthCode.FINANCE_PLATFORMEARNESTMONEYACCOUNT]
    }
  },
  {
    path: routeNames.storeAccount,
    component: () => import('../../pages/financialManagement/storeAccount'),
    meta: {
      title: '所有店铺账户',
      code: [EAuthCode.FINANCE_SHOPACCOUNT]
    }
  },
  {
    path: routeNames.storeMarketingAccount,
    component: () => import('../../pages/financialManagement/storeMarketingAccount'),
    meta: {
      title: '所有店铺营销账户',
      code: [EAuthCode.FINANCE_SHOPMARKETINGACCOUNT]
    }
  },
  {
    path: routeNames.storeDepositAccount,
    component: () => import('../../pages/financialManagement/storeDepositAccount'),
    meta: {
      title: '所有店铺保证金',
      code: [EAuthCode.FINANCE_SHOPEARNESTMONEYACCOUNT]
    }
  },
  {
    path: routeNames.accountsReceivableOrders,
    component: () => import('../../pages/financialManagement/accountsReceivableOrders'),
    meta: {
      title: '应收订单',
      code: [EAuthCode.FINANCE_RECEIVABLEORDER]
    }
  },
  {
    path: routeNames.paidOrder,
    component: () => import('../../pages/financialManagement/paidOrder'),
    meta: {
      title: '实收订单',
      code: [EAuthCode.FINANCE_RECEIVEDORDER]
    }
  },
  {
    path: routeNames.refundOrders,
    component: () => import('../../pages/financialManagement/refundOrders'),
    meta: {
      title: '退款订单',
      code: [EAuthCode.FINANCE_REFUNDORDER]
    }
  },
  {
    path: routeNames.platformSubsidies,
    component: () => import('../../pages/financialManagement/platformSubsidies'),
    meta: {
      title: '平台补贴',
      code: [EAuthCode.FINANCE_PLATFORMSUBSIDY]
    }
  },
  {
    path: routeNames.platformRecharge,
    component: () => import('../../pages/financialManagement/platformRecharge'),
    meta: {
      title: '账户充值',
      hideInMenu: true,
      code: [EAuthCode.FINANCE_PLATFORMMARKETINGACCOUNT_RECHARGE]
    }
  },
  {
    path: routeNames.financialTopTp,
    component: () => import('../../pages/financialManagement/financialTopTp'),
    meta: {
      title: '店铺充值',
      hideInMenu: true
      // code: [EAuthCode.BUSINESS_SHOPSTATE_WITHDRAW, EAuthCode.BUSINESS_SHOPSTATE_RECHARGE]
    }
  },
  {
    path: routeNames.platformCommission,
    component: () => import('../../pages/financialManagement/platformCommission'),
    meta: {
      title: '平台补贴/抽拥',
      code: [EAuthCode.FINANCE_PLATFORMCOMMISSION]
    }
  },
  {
    path: routeNames.statementOfAccount,
    component: () => import('../../pages/financialManagement/statementOfAccount'),
    meta: {
      title: '对账单',
      code: [EAuthCode.FINANCE_STATEMENTOFACCOUNT]
    }
  },
  {
    path: routeNames.refundReview,
    component: () => import('../../pages/financialManagement/refundReview'),
    meta: {
      title: '退款审核',
      code: [EAuthCode.FINANCE_REFUNDREVIEW]
    }
  },
  {
    path: routeNames.cardDetails,
    component: () => import('../../pages/financialManagement/cardDetails'),
    meta: {
      title: '金龙卡明细',
      code: [EAuthCode.FINANCE_CARDDETAILS]
    }
  },

  {
    path: routeNames.refundReviewDetail,
    component: () => import('../../pages/financialManagement/refundReviewDetail'),
    meta: {
      title: '审核详情',
      hideInMenu: true,
      code: [EAuthCode.CLASSIFY_REFUNDREVIEW_AUDIT, EAuthCode.CLASSIFY_REFUNDREVIEW_REPULSE, EAuthCode.CLASSIFY_REFUNDREVIEW_SUCCESS]
    }
  }
] as TRoutes[]
