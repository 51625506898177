import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.auditedGoodsDetail,
    component: () => import('../../pages/goodsLibrary/goodsDetail'),
    meta: {
      title: '待审核商品详情',
      hideInMenu: true,
      code: [EAuthCode.GOOD_PENDING_DETAIL]
    }
  },
  {
    path: routeNames.approvedGoodsDetail,
    component: () => import('../../pages/goodsLibrary/goodsDetail'),
    meta: {
      title: '已审核商品详情',
      hideInMenu: true,
      code: [EAuthCode.GOOD_REVIEWED_DETAIL, EAuthCode.FINANCE_PLATFORMSUBSIDY_GOODSDETAIL]
    }
  },
  {
    path: routeNames.auditedGoods,
    component: () => import('../../pages/goodsLibrary/auditedGoods'),
    meta: {
      title: '待审核商品',
      code: [EAuthCode.GOOD_PENDING]
    }
  },
  {
    path: routeNames.approvedGoods,
    component: () => import('../../pages/goodsLibrary/approvedGoods'),
    meta: {
      title: '已审核商品',
      code: [EAuthCode.GOOD_REVIEWED]
    }
  },
  {
    path: routeNames.unapprovedGoods,
    component: () => import('../../pages/goodsLibrary/unapprovedGoods'),
    meta: {
      title: '未通过商品',
      code: [EAuthCode.GOOD_FAIL]
    }
  },
  {
    path: routeNames.goodsComment,
    component: () => import('../../pages/goodsLibrary/goodsComment'),
    meta: {
      title: '商品评论',
      code: [EAuthCode.GOOD_EVALUATE]
    }
  }
] as TRoutes[]
