import { EFormProItem } from './../../../../../enums/EFormProItem'
import { Rule } from 'rc-field-form/lib/interface'
import { useMemo } from 'react'
import { isMobilePhone, isEmail } from '../../../../../utils/validator'
import { UploadFile } from 'antd/lib/upload/interface'

/**
 * 表单规则hook
 *
 * 为部分表单项内置默认的校验规则
 * @export
 * @param {Rule[]} rules
 * @param {EFormProItem} type
 * @return {*}
 */
export default function useRules(rules: Rule[], type: EFormProItem) {
  const itemRules = useMemo(() => {
    if (type === EFormProItem.Mobile) {
      return (rules || []).concat({
        validator: (rule, value) => {
          const valid = isMobilePhone(value)
          return !valid ? Promise.reject(new Error('请输入正确的手机号')) : Promise.resolve(value)
        }
      })
    } else if (type === EFormProItem.Email) {
      return (rules || []).concat({
        validator: (rule, value) => {
          const valid = isEmail(value)
          return !valid ? Promise.reject(new Error('请输入正确的邮箱')) : Promise.resolve(value)
        }
      })
    } else if (type === EFormProItem.ImageUpload) {
      return (rules || []).concat({
        validator(rule, value?: UploadFile[]) {
          if (Array.isArray(value)) {
            if (value.some((item) => item.status === 'error')) {
              return Promise.reject(new Error('图片上传失败，请重试'))
            } else if (value.some((item) => item.status === 'uploading')) {
              return Promise.reject(new Error('图片上传中，请稍后'))
            }
          }
          return Promise.resolve()
        }
      })
    }

    return rules
  }, [rules, type])

  return itemRules
}
