import { FormInstance } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { useRef } from 'react'
import omitObjKeys from '~/utils/omitObjKey'
import { IFormProProps } from '../components/formPro/const'
import { EFormProItem } from '../enums/EFormProItem'

/**
 * 增强表格配套的hook
 */
export default function useFormPro<T extends IFormProProps = IFormProProps>(option: T) {
  const { items } = option
  /** 表单ref */
  const formRef = useRef<FormInstance>(null)

  /**
   * 获取表单数据
   *
   * 验证并格式化数据
   * @description 首先验证数据格式、获取表单数据
   * 如果表单中字段以_(下划线)为开头.则会被忽略
   * 如果表单数据是数组类型.则会被JSON.stringify
   *
   */
  const getFormData = async () => {
    if (formRef.current) {
      try {
        const values = await formRef.current.validateFields()
        const data = omitObjKeys(values, (key) => /^_/.test(key))
        return Object.keys(data).reduce((obj, key) => {
          const value = data[key]

          if (!value) {
            obj[key] = value
            return obj
          }

          const item = items.find((item) => item.name === key)
          if (/,/.test(key)) {
            key.split(',').forEach((kk, index) => {
              obj[kk] = value[index]
            })
          } else if (item && [EFormProItem.ImageUpload, EFormProItem.VideoUpload].includes(item.type as any)) {
            obj[key] = value && value.length ? value.map((item) => item.url).join(',') : ''
          } else if (value instanceof Array) {
            obj[key] = JSON.stringify(value)
          } else {
            obj[key] = value
          }
          return obj
        }, values)
      } catch (error) {
        throw error
      }
    }
    throw new Error('formRef is null')
  }

  /**
   * 设置表单数据
   * 如果数据是数组字符串.会被JSON.parse
   * @param data
   */
  const setFormData = (data: Record<string, any>) => {
    const formData = items.reduce((obj, item) => {
      const { type, name } = item
      if (name) {
        if (typeof name === 'string') {
          const value = data[name]
          if (value === undefined || value === null) {
            obj[name] = value
          } else if (/,/.test(name)) {
            obj[name] = name.split(',').map((key) => data[key])
          } else if ([EFormProItem.ImageUpload, EFormProItem.VideoUpload].includes(item.type as any)) {
            obj[name] = value ? value.split(',').map((url) => ({ status: 'done', url, uid: url } as UploadFile)) : []
          } else if (typeof value === 'string' && /(\[|\])/.test(value)) {
            obj[name] = JSON.parse(value)
          } else {
            obj[name] = value
          }
        }
      }
      return obj
    }, {} as Record<string, any>)
    formRef.current?.setFieldsValue(formData)
  }

  return {
    formRef,
    option,
    getFormData,
    setFormData
  }
}
