import React from 'react'
import GlobalContext, { IGlobalContext } from './global.context'
import { systemConfig } from '~/config'

/**
 * 全局context的Provider。
 *
 * @export
 * @class GlobalProvider
 * @extends {React.Component<any, IGlobalContext>}
 */
export default class GlobalProvider extends React.Component<any, IGlobalContext> {
  static contextType = GlobalContext

  dispatch: (key: any, value?: any) => void

  constructor(props: any) {
    super(props)

    this.dispatch = (key: any, value?: any) => {
      if (typeof key === 'string') {
        const state: any = { [key]: value }
        this.setState(state)
      } else {
        this.setState(key)
      }
    }

    this.state = {
      layoutSetting: { ...systemConfig.layoutSetting },
      breadcrumb: { ...systemConfig.breadcrumb },
      breadcrumbs: [],
      name: '',
      applicationName: 'B+商城',
      menus: [],
      authCodes: [],
      dispatch: this.dispatch,
      dispatchAction: async (key: string, payload?: any) => {
        return this[key] && this[key].apply(this, [payload])
      }
    }
  }

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>
  }
}
