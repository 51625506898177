import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.orderManagementList,
    component: () => import('../../pages/orderManagement/orderList'),
    meta: {
      title: '订单列表',
      code: [EAuthCode.ORDER_LIST]
    }
  },
  {
    path: routeNames.orderManagementVirtualList,
    component: () => import('../../pages/orderManagement/orderList/virtual'),
    meta: {
      title: '虚拟商品订单',
      code: EAuthCode.ORDER_VIRTUALLIST
    }
  },
  {
    path: routeNames.orderManagementShipping,
    component: () => import('../../pages/orderManagement/orderShipping'),
    meta: {
      title: '发货管理',
      code: [EAuthCode.ORDER_DELIVERYMANAGEMENT]
    }
  },
  {
    path: routeNames.orderManagementAftersale,
    component: () => import('../../pages/orderManagement/orderAftersale'),
    meta: {
      title: '售后管理',
      code: [EAuthCode.ORDER_AFTERSALELIST]
    }
  },
  {
    path: routeNames.orderManagementArbitration,
    component: () => import('../../pages/orderManagement/orderArbitration'),
    meta: {
      title: '仲裁列表',
      code: [EAuthCode.ORDER_ARBITRATIONLIST]
    }
  },
  {
    path: routeNames.orderManagementDetail,
    component: () => import('../../pages/orderManagement/orderDetail'),
    meta: {
      title: '订单详情',
      hideInMenu: true,
      code: [
        EAuthCode.ORDER_LIST_DETAIL,
        EAuthCode.ORDER_VIRTUALLIST_DETAIL,
        EAuthCode.ORDER_DELIVERYMANAGEMENT_ORDERDETAIL,
        EAuthCode.ORDER_AFTERSALELIST_ORDERDETAIL,
        EAuthCode.ORDER_ARBITRATIONLIST_ORDERDETAIL,
        EAuthCode.FINANCE_PLATFORMACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_PLATFORMEARNESTMONEYACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_PLATFORMMARKETINGACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_SHOPACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_SHOPMARKETINGACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_SHOPEARNESTMONEYACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_RECEIVABLEORDER_MEMBERDETAIL,
        EAuthCode.FINANCE_RECEIVABLEORDER_ORDERDETAIL,
        EAuthCode.FINANCE_RECEIVEDORDER_ORDERDETAIL,
        EAuthCode.FINANCE_REFUNDORDER_ORDERDETAIL,
        EAuthCode.FINANCE_PLATFORMSUBSIDY_ORDERDETAIL
      ]
    }
  },
  {
    path: routeNames.orderManagementAfterDetail,
    component: () => import('../../pages/orderManagement/afterDetail'),
    meta: {
      title: '售后详情',
      hideInMenu: true,
      code: [
        EAuthCode.ORDER_AFTERSALELIST_DETAIL,
        EAuthCode.ORDER_ARBITRATIONLIST_AFTERSALEDETAIL,
        EAuthCode.FINANCE_REFUNDORDER_AFTERSALEDETAIL,
        EAuthCode.FINANCE_SHOPACCOUNT_AFTERSALEDETAIL
      ]
    }
  },
  {
    path: routeNames.orderManagementArbitrationDetail,
    component: () => import('../../pages/orderManagement/arbitrationDetail'),
    meta: {
      title: '仲裁详情',
      hideInMenu: true,
      code: [EAuthCode.ORDER_ARBITRATIONLIST_DETAIL, EAuthCode.FINANCE_REFUNDORDER_ARBITRATIONDETAIL]
    }
  }
] as TRoutes[]
