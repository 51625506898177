
/** pro 表单组件类型 */
export enum EFormProItem {
  /** 自定义 */
  Custom = 'Custom',
  /** 输入框 */
  Input = 'Input',
  /** 数值输入 */
  InputNumber = 'InputNumber',
  /** 选择框 */
  Select = 'Select',
  /** 开关 */
  Switch = 'Switch',
  /** 多选框 */
  Checkbox = 'Checkbox',
  /** 单选 */
  Radio = 'Radio',
  /** 日期选择 */
  DatePicker = 'DatePicker',
  /** 多行文本 */
  TextArea = 'TextArea',
  /** 日期范围 */
  DatePickerRange = 'DatePickerRange',
  /** 富文本 */
  RichText = 'RichText',
  /** 图片上传 */
  ImageUpload = 'ImageUpload',
  /** 表单函数式渲染 */
  RenderChildren = 'RenderChildren',
  /** 长度限制输入框 */
  LimitInput = 'LimitInput',
  /** 颜色选择器 */
  ColorPicker = 'ColorPicker',
  /** 图标选择 */
  IconSelect = 'IconSelect',
  /** 树选择 */
  TreeSelect = 'TreeSelect',
  /** 城市选择器 */
  CityPicker = 'CityPicker',
  /** 手机号 */
  Mobile = 'Mobile',
  /** 邮箱 */
  Email = 'Email',
  /** 视频上传 */
  VideoUpload = 'VideoUpload'
}

export type EFormProItemType = EFormProItem | keyof typeof EFormProItem