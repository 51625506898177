import { FC, memo, useContext, useEffect, useState } from 'react'
import { Button, Card, Form, Input } from 'antd'
import styles from './index.module.less'
import Logo from '~/assets/images/logo.png'
import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import CryptoJS from 'crypto-js'
import globalContext from '~/contexts/global.context'
import { useForm } from 'antd/lib/form/Form'
import { useLocation, useHistory } from 'react-router-dom'
import { defaultPwd, isDevelopment } from '~/config'
import { api } from '~/request'
import { routeNames } from '~/routes/const'
import { isMobilePhone } from '~/modules/@wmeimob/backend-pro/src/utils/validator'

const { Item } = Form
const Component: FC = () => {
  const { applicationName } = useContext(globalContext)
  const { state } = useLocation<{ from?: string }>()
  const history = useHistory()

  const [form] = useForm()
  const [passwordType, setPasswordType] = useState('password')

  function handleFinish() {
    form.validateFields().then(() => {
      const { mobile, password } = form.getFieldsValue()

      const srcs = CryptoJS.enc.Utf8.parse(password)
      const key = CryptoJS.enc.Utf8.parse('sdidASE5F41S5Dm7')
      const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString()

      // console.log(password, encrypted, 'mh8el08fV54wqQH3roDr4Q==')

      api['/admin/auth/login_POST']({ mobile, password: encrypted, isStore: false }).then(({ data }) => {
        window.localStorage.setItem('Authorization', 'Bearer ' + (data || ''))
        if (state && state.from) {
          history.replace(state.from)
        } else {
          history.replace('/homeIndex')
        }
      })
    })
  }

  useEffect(() => {
    if (isDevelopment) {
      form.setFieldsValue({ mobile: '19999999997' })
    }
  }, [])

  return (
    <div className={styles.component}>
      <Card className={styles.card}>
        <div style={{ textAlign: 'center' }}>
          <img src={Logo} className={styles.logo} />
        </div>
        <div className={styles.desc}>{applicationName}</div>
        <Form form={form} onFinish={handleFinish}>
          <Item
            name="mobile"
            validateFirst
            rules={[
              { required: true, message: '请输入手机号码' },
              { validator: (_, value) => (isMobilePhone(value) ? Promise.resolve() : Promise.reject(new Error('请输入正确的手机号码'))) }
            ]}
          >
            <Input placeholder="请输入手机号码" size="large" prefix={<UserOutlined style={{ color: '#bfbfbf' }} />} />
          </Item>
          <Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input
              placeholder="请输入密码"
              size="large"
              prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
              type={passwordType}
              suffix={
                passwordType === 'password' ? (
                  <EyeInvisibleOutlined onClick={() => setPasswordType('text')} />
                ) : (
                  <EyeOutlined onClick={() => setPasswordType('password')} />
                )
              }
            />
          </Item>
          <Item>
            <Button type="primary" block size="large" htmlType="submit">
              {' '}
              登录
            </Button>
          </Item>
        </Form>
      </Card>
    </div>
  )
}

Component.defaultProps = {}
Component.displayName = 'MMPLogin'

const MMPLogin = memo(Component)
export default MMPLogin
