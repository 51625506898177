import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'
export default [
  {
    path: routeNames.ministoreCategory,
    component: () => import('../../pages/ministore/category'),
    meta: { title: '类目管理', code: EAuthCode.MINISTORE_CATEGORY }
  },
  {
    path: routeNames.ministoreBrand,
    component: () => import('../../pages/ministore/brand'),
    meta: { title: '品牌管理', code: [EAuthCode.MINISTORE_BRAND] }
  },
  {
    path: routeNames.ministoreBrandDetail,
    component: () => import('../../pages/ministore/brandDetail'),
    meta: { title: '品牌详情', hideInMenu: true, code: [EAuthCode.MINISTORE_BRAND_ADD, EAuthCode.MINISTORE_BRAND_UPDATE] }
  },
  {
    path: routeNames.ministoreGoodsList,
    component: () => import('../../pages/ministore/goodsList'),
    meta: { title: '商品管理', code: EAuthCode.MINISTORE_GOODSLIST }
  }
] as TRoutes[]
