import { IMenus, ISubMenu, TMenu } from "~/data-model/common";

/** 判断是否是菜单 */
export function isMenu(item: TMenu): item is IMenus {
  return !!(item as IMenus).path && !isSubMenu(item)
}

/** 判断是否是二级菜单 */
export function isSubMenu(data: TMenu): data is ISubMenu {
  return !!(data as ISubMenu).children
}

export function getMenuCrumbs(menus: TMenu[], pathname: string) {
  let crumbs: { title: string, path: string }[] = [];
  let flag = true;
  menus.forEach(item => {
    if (!flag) {
      return;
    } else if (isSubMenu(item)) {
      const res = getMenuCrumbs(item.children || [], pathname)
      if (res.length) {
        flag = false;
        crumbs.push({ title: item.title, path: '' })
        crumbs = crumbs.concat(res)
      }
    } else if (isMenu(item)) {
      const { path, title } = item
      if (path === pathname) {
        crumbs.push({ title, path })
        flag = false;
      }
    }
  })

  return crumbs;
}

function flatMenu(menus: TMenu[], parentKeys: string[] = []) {
  return menus.reduce((result, item) => {
    if (isSubMenu(item)) {
      const parentKey = parentKeys.join('') + item.title
      const res = flatMenu(item.children || [], parentKeys.concat(parentKey))
      result = result.concat(res)
    } else if (isMenu(item)) {
      result.push({ ...item, parentKeys })
    }
    return result;
  }, [] as (IMenus & { parentKeys: string[] })[])

}

/**
 * 根据路径获取选中的菜单和打开的菜单
 * 支持长路匹配
 *
 * @export
 * @param {TMenu[]} menus
 * @param {string} pathname
 * @return {*} 
 */
export function getMenuSelectedAndOpenKey(menus: TMenu[], pathname: string) {
  const fMenus = flatMenu(menus);
  const menu = fMenus.find(item => (pathname + '/').indexOf(item.path + '/') !== -1);
  return {
    selectedKeys: menu ? [menu.path] : [],
    openKeys: menu ? menu.parentKeys : []
  }
}

/**
 * 根据路径获取面包屑导航
 * @param menus 
 * @param pathname 
 * @returns 
 */
export function getMenuBreadcrumbs(menus: TMenu[], pathname: string) {
  function reversalMenu(data: TMenu[], parents: TMenu[] = []) {
    return data.reduce((result, item) => {
      if (isSubMenu(item)) {
        const res = reversalMenu(item.children || [], parents.concat(item))
        result = result.concat(res)
      } else if (isMenu(item)) {
        result.push({ ...item, parents })
      }
      return result;
    }, [] as (IMenus & { parents: TMenu[] })[])

  }

  const rMenus = reversalMenu(menus)

  const menu = rMenus.find(item => (pathname + '/').indexOf(item.path + '/') !== -1);

  // console.log(menu)
  return menu
}


/**
 * 从菜单中获取第一个路径
 * @param menus 菜单数据
 * @returns 
 */
export function getFirstPathFromMenus(menus: TMenu[]) {
  let firstPath = ''
  for (let index = 0; index < menus.length; index++) {
    const element = menus[index]
    const { path, children = [] } = element as any
    if (path) {
      firstPath = path
      break
    } else if (children.length) {
      const fp = getFirstPathFromMenus(children)
      if (fp) {
        firstPath = fp
        break
      }
    }
  }
  return firstPath
}