export enum EAuthCode {
  /** 基础设置 */
  SETTING = 'setting',
  /** 基础设置-基础设置 */
  SETTING_BASE = 'setting-base',
  /** 基础设置-基础设置-预览 */
  SETTING_BASE_QUERY = 'setting-base-query',
  /** 基础设置-基础设置-编辑 */
  SETTING_BASE_UPDATE = 'setting-base-update',

  /** 基础设置-渠道维护 */
  SETTING_CHANNELMAINTAIN = 'setting-channelMaintain',
  /** 基础设置-渠道维护-预览 */
  SETTING_CHANNELMAINTAIN_QUERY = 'setting-channelMaintain-query',
  /** 基础设置-渠道维护-编辑 */
  SETTING_CHANNELMAINTAIN_UPDATE = 'setting-channelMaintain-update',
  /** 基础设置-渠道维护-新增 */
  SETTING_CHANNELMAINTAIN_ADD = 'setting-channelMaintain-add',
  /** 基础设置-渠道维护-删除 */
  SETTING_CHANNELMAINTAIN_DELETE = 'setting-channelMaintain-delete',

  /** 基础设置-投放标签维护 */
  SETTING_LABEL = 'setting-label',
  /** 基础设置-投放标签维护-预览 */
  SETTING_LABEL_QUERY = 'setting-label-query',
  /** 基础设置-投放标签维护-编辑 */
  SETTING_LABEL_UPDATE = 'setting-label-update',
  /** 基础设置-投放标签维护-新增 */
  SETTING_LABEL_ADD = 'setting-label-add',

  /** 基础设置-投放链接维护 */
  SETTING_LINK = 'setting-link',
  /** 基础设置-投放链接维护-预览 */
  SETTING_LINK_QUERY = 'setting-link-query',
  /** 基础设置-投放链接维护-新增 */
  SETTING_LINK_ADD = 'setting-link-add',
  /** 基础设置-投放链接维护-删除 */
  SETTING_LINK_DELETE = 'setting-link-delete',
  /** 基础设置-投放链接维护-开关切换 */
  SETTING_LINK_SWITCHEXEMPTION = 'setting-link-switchExemption',

  /** 基础设置-热门搜索词 */
  SETTING_HOTKEY = 'setting-hotKey',
  /** 基础设置-热门搜索词-预览 */
  SETTING_HOTKEY_QUERY = 'setting-hotKey-query',
  /** 基础设置-热门搜索词-编辑 */
  SETTING_HOTKEY_UPDATE = 'setting-hotKey-update',
  /** 基础设置-热门搜索词-新增 */
  SETTING_HOTKEY_ADD = 'setting-hotKey-add',
  /** 基础设置-热门搜索词-删除 */
  SETTING_HOTKEY_DELETE = 'setting-hotKey-delete',
  /** 基础设置-热门搜索词-状态切换 */
  SETTING_HOTKEY_SHOWSTATUS = 'setting-hotKey-showStatus',

  /** 基础设置-默认搜索词 */
  SETTING_DEFAULTKEY = 'setting-defaultKey',
  /** 基础设置-默认搜索词-预览 */
  SETTING_DEFAULTKEY_QUERY = 'setting-defaultKey-query',
  /** 基础设置-默认搜索词-编辑 */
  SETTING_DEFAULTKEY_UPDATE = 'setting-defaultKey-update',
  /** 基础设置-默认搜索词-新增 */
  SETTING_DEFAULTKEY_ADD = 'setting-defaultKey-add',
  /** 基础设置-默认搜索词-删除 */
  SETTING_DEFAULTKEY_DELETE = 'setting-defaultKey-delete',
  /** 基础设置-默认搜索词-状态切换 */
  SETTING_DEFAULTKEY_SHOWSTATUS = 'setting-defaultKey-showStatus',

  /** 基础设置-广告位 */
  SETTING_ADVERTISINGSPACE = 'setting-advertisingSpace',
  /** 基础设置-广告位-预览 */
  SETTING_ADVERTISINGSPACE_QUERY = 'setting-advertisingSpace-query',
  /** 基础设置-广告位-编辑 */
  SETTING_ADVERTISINGSPACE_UPDATE = 'setting-advertisingSpace-update',
  /** 基础设置-广告位-新增 */
  SETTING_ADVERTISINGSPACE_ADD = 'setting-advertisingSpace-add',
  /** 基础设置-广告位-删除 */
  SETTING_ADVERTISINGSPACE_DELETE = 'setting-advertisingSpace-delete',
  /** 基础设置-广告位-状态切换 */
  SETTING_ADVERTISINGSPACE_SHOWSTATUS = 'setting-advertisingSpace-showStatus',

  /** 基础设置-广告详情 */
  SETTING_ADVERTISINGSPACEDETAIL = 'setting-advertisingSpaceDetail',
  /** 基础设置-广告详情-预览 */
  SETTING_ADVERTISINGSPACEDETAIL_QUERY = 'setting-advertisingSpaceDetail-query',
  /** 基础设置-广告详情-编辑 */
  SETTING_ADVERTISINGSPACEDETAIL_UPDATE = 'setting-advertisingSpaceDetail-update',

  /** 基础设置-首页弹框 */
  SETTING_POPUP = 'setting-popUp',
  /** 基础设置-首页弹框-预览 */
  SETTING_POPUP_QUERY = 'setting-popUp-query',
  /** 基础设置-首页弹框-导出 */
  SETTING_POPUP_EXPORT = 'setting-popUp-export',
  /** 基础设置-首页弹框-新增 */
  SETTING_POPUP_ADD = 'setting-popUp-add',
  /** 基础设置-首页弹框-状态切换 */
  SETTING_POPUP_SHOW = 'setting-popUp-show',
  /** 基础设置-首页弹框-编辑 */
  SETTING_POPUP_UPDATE = 'setting-popUp-update',
  /** 基础设置-首页弹框-删除 */
  SETTING_POPUP_DELETE = 'setting-popUp-delete',

  /** 基础设置-平台主题 */
  SETTING_PLATFORMTHEME = 'setting-platformTheme',
  /** 基础设置-平台主题-预览 */
  SETTING_PLATFORMTHEME_QUERY = 'setting-platformTheme-query',
  /** 基础设置-平台主题-编辑 */
  SETTING_PLATFORMTHEME_UPDATE = 'setting-platformTheme-update',
  /** 基础设置-平台主题-状态切换 */
  SETTING_PLATFORMTHEME_SHOW = 'setting-platformTheme-show',

  /** 基础设置-店铺轮播图 */
  SETTING_SHOPBANNER = 'setting-shopBanner',
  /** 基础设置-店铺轮播图-预览 */
  SETTING_SHOPBANNER_QUERY = 'setting-shopBanner-query',
  /** 基础设置-店铺轮播图-编辑 */
  SETTING_SHOPBANNER_UPDATE = 'setting-shopBanner-update',
  /** 基础设置-店铺轮播图-新增 */
  SETTING_SHOPBANNER_ADD = 'setting-shopBanner-add',
  /** 基础设置-店铺轮播图-删除 */
  SETTING_SHOPBANNER_DELETE = 'setting-shopBanner-delete',
  /** 基础设置-店铺轮播图-状态切换 */
  SETTING_SHOPBANNER_SHOWSTATUS = 'setting-shopBanner-showStatus',

  /** 基础设置-协议管理 */
  SETTING_PROTOCOLMANAGEMENT = 'setting-protocolManagement',
  /** 基础设置-协议管理-预览 */
  SETTING_PROTOCOLMANAGEMENT_QUERY = 'setting-protocolManagement-query',
  /** 基础设置-协议管理-编辑 */
  SETTING_PROTOCOLMANAGEMENT_UPDATE = 'setting-protocolManagement-update',

  /** 基础设置-帮助中心分类 */
  SETTING_HELPCENTERCATEGORY = 'setting-helpCenterCategory',
  /** 基础设置-帮助中心分类-预览 */
  SETTING_HELPCENTERCATEGORY_QUERY = 'setting-helpCenterCategory-query',
  /** 基础设置-帮助中心分类-编辑 */
  SETTING_HELPCENTERCATEGORY_UPDATE = 'setting-helpCenterCategory-update',
  /** 基础设置-帮助中心分类-新增 */
  SETTING_HELPCENTERCATEGORY_ADD = 'setting-helpCenterCategory-add',
  /** 基础设置-帮助中心分类-删除 */
  SETTING_HELPCENTERCATEGORY_DELETE = 'setting-helpCenterCategory-delete',
  /** 基础设置-帮助中心分类-状态切换 */
  SETTING_HELPCENTERCATEGORY_SHOWSTATUS = 'setting-helpCenterCategory-showStatus',

  /** 基础设置-帮助中心文章 */
  SETTING_HELPCENTERARTICAL = 'setting-helpCenterArtical',
  /** 基础设置-帮助中心文章-预览 */
  SETTING_HELPCENTERARTICAL_QUERY = 'setting-helpCenterArtical-query',
  /** 基础设置-帮助中心文章-编辑 */
  SETTING_HELPCENTERARTICAL_UPDATE = 'setting-helpCenterArtical-update',
  /** 基础设置-帮助中心文章-新增 */
  SETTING_HELPCENTERARTICAL_ADD = 'setting-helpCenterArtical-add',
  /** 基础设置-帮助中心文章-删除 */
  SETTING_HELPCENTERARTICAL_DELETE = 'setting-helpCenterArtical-delete',
  /** 基础设置-帮助中心文章-状态切换 */
  SETTING_HELPCENTERARTICAL_SHOWSTATUS = 'setting-helpCenterArtical-showStatus',

  /** 基础设置-意见反馈 */
  SETTING_FEEDBACK = 'setting-feedback',
  /** 基础设置-意见反馈-预览 */
  SETTING_FEEDBACK_QUERY = 'setting-feedback-query',
  /** 基础设置-意见反馈-详情 */
  SETTING_FEEDBACK_DETAIL = 'setting-feedback-detail',
  /** 基础设置-意见反馈-导出 */
  SETTING_FEEDBACK_EXPORT = 'setting-feedback-export',

  /** 基础设置-应用管理 */
  SETTING_CLIENT = 'setting-client',
  /** 基础设置-应用管理-预览 */
  SETTING_CLIENT_QUERY = 'setting-client-query',
  /** 基础设置-应用管理-新增 */
  SETTING_CLIENT_ADD = 'setting-client-add',
  /** 基础设置-应用管理-编辑 */
  SETTING_CLIENT_UPDATE = 'setting-client-update',
  /** 基础设置-应用管理-授权 */
  SETTING_CLIENT_AUTH = 'setting-client-auth',
  /** 基础设置-应用管理-删除 */
  SETTING_CLIENT_DELETE = 'setting-client-delete',

  /** 类目库 */
  CLASSIFY = 'classify',
  /** 类目库-类目管理 */
  CLASSIFY_CLASSIFYMANAGE = 'classify-classifyManage',
  /** 类目库-类目管理-预览 */
  CLASSIFY_CLASSIFYMANAGE_QUERY = 'classify-classifyManage-query',
  /** 类目库-类目管理-编辑 */
  CLASSIFY_CLASSIFYMANAGE_UPDATE = 'classify-classifyManage-update',
  /** 类目库-类目管理-新增 */
  CLASSIFY_CLASSIFYMANAGE_ADD = 'classify-classifyManage-add',
  /** 类目库-类目管理-删除 */
  CLASSIFY_CLASSIFYMANAGE_DELETE = 'classify-classifyManage-delete',
  /** 类目库-类目管理-状态切换 */
  CLASSIFY_CLASSIFYMANAGE_SHOWSTATUS = 'classify-classifyManage-showStatus',
  /** 类目库-类目管理-排序 */
  CLASSIFY_CLASSIFYMANAGE_SORT = 'classify-classifyManage-sort',

  /** 类目库-品牌管理 */
  CLASSIFY_BRNADMANAGE = 'classify-brnadManage',
  /** 类目库-品牌管理-预览 */
  CLASSIFY_BRNADMANAGE_QUERY = 'classify-brnadManage-query',
  /** 类目库-品牌管理-详情 */
  CLASSIFY_BRNADMANAGE_DETAIL = 'classify-brnadManage-detail',

  /** 类目库-品牌库 */
  CLASSIFY_BRANDLIBRARY = 'classify-brandLibrary',
  /** 类目库-品牌库-预览 */
  CLASSIFY_BRANDLIBRARY_QUERY = 'classify-brandLibrary-query',
  /** 类目库-品牌库-编辑 */
  CLASSIFY_BRANDLIBRARY_UPDATE = 'classify-brandLibrary-update',
  /** 类目库-品牌库-新增 */
  CLASSIFY_BRANDLIBRARY_ADD = 'classify-brandLibrary-add',
  /** 类目库-品牌库-删除 */
  CLASSIFY_BRANDLIBRARY_DELETE = 'classify-brandLibrary-delete',
  /** 类目库-品牌库-导入品牌库品牌 */
  CLASSIFY_BRANDLIBRARY_IMPORT = 'classify-brandLibrary-import',
  /** 类目库-品牌库-免审开关切换 */
  CLASSIFY_BRANDLIBRARY_SWITCHEXEMPTION = 'classify-brandLibrary-switchExemption',

  /** 类目库-品牌归属类目 */
  CLASSIFY_BRANDATTRIBUTIONCLASSIFY = 'classify-brandAttributionClassify',
  /** 类目库-品牌归属类目-预览 */
  CLASSIFY_BRANDATTRIBUTIONCLASSIFY_QUERY = 'classify-brandAttributionClassify-query',
  /** 类目库-品牌归属类目-归属类目 */
  CLASSIFY_BRANDATTRIBUTIONCLASSIFY_AFFILIATION = 'classify-brandAttributionClassify-affiliation',
  /** 类目库-品牌归属类目-归属类目编辑 */
  CLASSIFY_BRANDATTRIBUTIONCLASSIFY_AFFILIATIONUPDATE = 'classify-brandAttributionClassify-affiliationUpdate',

  /** 类目库-品牌审核 */
  CLASSIFY_BRANDAUDIT = 'classify-brandAudit',
  /** 类目库-品牌审核-预览 */
  CLASSIFY_BRANDAUDIT_QUERY = 'classify-brandAudit-query',
  /** 类目库-品牌审核-直接通过 */
  CLASSIFY_BRANDAUDIT_SUCCESS = 'classify-brandAudit-success',
  /** 类目库-品牌审核-直接打回 */
  CLASSIFY_BRANDAUDIT_REPULSE = 'classify-brandAudit-repulse',
  /** 类目库-品牌审核-审核 */
  CLASSIFY_BRANDAUDIT_AUDIT = 'classify-brandAudit-audit',

  /** 商品库 */
  GOOD = 'good',
  /** 商品库-待审核商品 */
  GOOD_PENDING = 'good-pending',
  /** 商品库-待审核商品-预览 */
  GOOD_PENDING_QUERY = 'good-pending-query',
  /** 商品库-待审核商品-审核商品 */
  GOOD_PENDING_AUDIT = 'good-pending-audit',
  /** 商品库-待审核商品-审核详情 */
  GOOD_PENDING_DETAIL = 'good-pending-detail',
  /** 商品库-待审核商品-导出 */
  GOOD_PENDING_EXPORT = 'good-pending-export',

  /** 商品库-已审核详情 */
  GOOD_REVIEWED = 'good-reviewed',
  /** 商品库-已审核详情-预览 */
  GOOD_REVIEWED_QUERY = 'good-reviewed-query',
  /** 商品库-已审核详情-下架 */
  GOOD_REVIEWED_OFFSHELF = 'good-reviewed-offShelf',
  /** 商品库-已审核详情-详情 */
  GOOD_REVIEWED_DETAIL = 'good-reviewed-detail',
  /** 商品库-已审核详情-导出 */
  GOOD_REVIEWED_EXPORT = 'good-reviewed-export',
  /** 商品库-已审核详情-金龙卡显示状态 */
  GOOD_REVIEWED_SHOWCARD = 'good-reviewed-showCard',

  /** 商品库-未通过商品 */
  GOOD_FAIL = 'good-fail',
  /** 商品库-未通过商品-预览 */
  GOOD_FAIL_QUERY = 'good-fail-query',
  /** 商品库-未通过商品-删除 */
  GOOD_FAIL_DELETE = 'good-fail-delete',
  /** 商品库-未通过商品-导出 */
  GOOD_FAIL_EXPORT = 'good-fail-export',

  /** 商品库-商品评价 */
  GOOD_EVALUATE = 'good-evaluate',
  /** 商品库-商品评价-预览 */
  GOOD_EVALUATE_QUERY = 'good-evaluate-query',
  /** 商品库-商品评价-显示状态切换 */
  GOOD_EVALUATE_SHOWSTATUS = 'good-evaluate-showStatus',
  /** 商品库-商品评价-导出 */
  GOOD_EVALUATE_EXPORT = 'good-evaluate-export',

  /** 商家管理 */
  BUSINESS = 'business',
  /** 商家管理-入驻审核 */
  BUSINESS_SETTLEDADUIT = 'business-settledAduit',
  /** 商家管理-入驻审核-预览 */
  BUSINESS_SETTLEDADUIT_QUERY = 'business-settledAduit-query',
  /** 商家管理-入驻审核-直接打回 */
  BUSINESS_SETTLEDADUIT_REPULSE = 'business-settledAduit-repulse',
  /** 商家管理-入驻审核-审核详情 */
  BUSINESS_SETTLEDADUIT_AUDIT = 'business-settledAduit-audit',
  /** 商家管理-入驻审核-直接再次打回 */
  BUSINESS_SETTLEDADUIT_AGAINREPULSE = 'business-settledAduit-againRepulse',
  /** 商家管理-入驻审核-审核再次详情 */
  BUSINESS_SETTLEDADUIT_AGAINAUDIT = 'business-settledAduit-againAudit',

  /** 商家管理-店铺列表 */
  BUSINESS_SHOPS = 'business-shops',
  /** 商家管理-店铺列表-预览 */
  BUSINESS_SHOPS_QUERY = 'business-shops-query',
  /** 商家管理-店铺列表-导出 */
  BUSINESS_SHOPS_EXPORT = 'business-shops-export',
  /** 商家管理-店铺列表-编辑 */
  BUSINESS_SHOPS_UPDATE = 'business-shops-update',
  /** 商家管理-店铺列表-详情 */
  BUSINESS_SHOPS_DETAIL = 'business-shops-detail',
  /** 商家管理-店铺列表-开通客服 */
  BUSINESS_SHOPS_SERVICER = 'business-shops-servicer',

  /** 商家管理-商家账户管理 */
  BUSINESS_ACCOUNTS = 'business-accounts',
  /** 商家管理-商家账户管理-预览 */
  BUSINESS_ACCOUNTS_QUERY = 'business-accounts-query',
  /** 商家管理-商家账户管理-免审开关切换 */
  BUSINESS_ACCOUNTS_SWITCHAUDITEXEMPTION = 'business-accounts-switchAuditExemption',
  /** 商家管理-商家账户管理-启用开关切换 */
  BUSINESS_SHOPS_STATE = 'business-shops-state',
  /** 商家管理-商家账户管理-详情 */
  BUSINESS_ACCOUNTS_DETAIL = 'business-accounts-detail',

  /** 商家管理-店铺类型设置 */
  BUSINESS_SHOPTYPESETTING = 'business-shopTypeSetting',
  /** 商家管理-店铺类型设置-预览 */
  BUSINESS_SHOPTYPESETTING_QUERY = 'business-shopTypeSetting-query',
  /** 商家管理-店铺类型设置-显示状态切换 */
  BUSINESS_SHOPTYPESETTING_SHOWSTATUS = 'business-shopTypeSetting-showStatus',
  /** 商家管理-店铺类型设置-编辑 */
  BUSINESS_SHOPTYPESETTING_UPDATE = 'business-shopTypeSetting-update',
  /** 商家管理-店铺类型设置-新增 */
  BUSINESS_SHOPTYPESETTING_ADD = 'business-shopTypeSetting-add',

  /** 商家管理-保证金管理 */
  BUSINESS_DEPOSITMANAGE = 'business-depositManage',
  /** 商家管理-保证金管理-预览 */
  BUSINESS_DEPOSITMANAGE_QUERY = 'business-depositManage-query',
  /** 商家管理-保证金管理-导出 */
  BUSINESS_DEPOSITMANAGE_EXPORT = 'business-depositManage-export',

  /** 商家管理-通知管理 */
  BUSINESS_NOTICEMANAGE = 'business-noticeManage',
  /** 商家管理-通知管理-预览 */
  BUSINESS_NOTICEMANAGE_QUERY = 'business-noticeManage-query',
  /** 商家管理-通知管理-新建通知 */
  BUSINESS_NOTICEMANAGE_ADD = 'business-noticeManage-add',
  /** 商家管理-通知管理-删除通知 */
  BUSINESS_NOTICEMANAGE_DELETE = 'business-noticeManage-delete',

  /** 商家管理-通知管理详情 */
  BUSINESS_NOTICEDETAIL = 'business-noticeDetail',
  /** 商家管理-通知管理详情-预览 */
  BUSINESS_NOTICEDETAIL_QUERY = 'business-noticeDetail-query',
  /** 商家管理-通知管理详情-发送 */
  BUSINESS_NOTICEDETAIL_SEND = 'business-noticeDetail-send',

  /** 商家管理-关店审核 */
  BUSINESS_CLOSESHOPAUDIT = 'business-closeShopAudit',
  /** 商家管理-关店审核-预览 */
  BUSINESS_CLOSESHOPAUDIT_QUERY = 'business-closeShopAudit-query',
  /** 商家管理-关店审核-审核 */
  BUSINESS_CLOSESHOPAUDIT_AUDIT = 'business-closeShopAudit-audit',
  /** 商家管理-关店审核-不同意 */
  BUSINESS_CLOSESHOPAUDIT_DISAGREE = 'business-closeShopAudit-disagree',
  /** 商家管理-关店审核-详情 */
  BUSINESS_CLOSESHOPAUDIT_DETAIL = 'business-closeShopAudit-detail',

  /** 商家管理-b+亮证特权 */
  BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE = 'business-BPlusShowCertificatePrivilege',
  /** 商家管理-b+亮证特权-预览 */
  BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE_QUERY = 'business-BPlusShowCertificatePrivilege-query',
  /** 商家管理-b+亮证特权-编辑 */
  BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE_UPDATE = 'business-BPlusShowCertificatePrivilege-update',
  /** 商家管理-b+亮证特权-新增 */
  BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE_ADD = 'business-BPlusShowCertificatePrivilege-add',
  /** 商家管理-b+亮证特权-删除 */
  BUSINESS_BPLUSSHOWCERTIFICATEPRIVILEGE_DELETE = 'business-BPlusShowCertificatePrivilege-delete',

  /** 优惠券管理 */
  COUPONMANAGEMENT = 'couponManagement',
  /** 优惠券管理-优惠券模版管理 */
  COUPONMANAGEMENT_TEMPLATE = 'couponManagement-template',
  /** 优惠券管理-优惠券模版管理-预览 */
  COUPONMANAGEMENT_TEMPLATE_QUERY = 'couponManagement-template-query',
  /** 优惠券管理-优惠券模版管理-新增 */
  COUPONMANAGEMENT_TEMPLATE_ADD = 'couponManagement-template-add',
  /** 优惠券管理-优惠券模版管理-详情 */
  COUPONMANAGEMENT_TEMPLATE_DETAIL = 'couponManagement-template-detail',
  /** 优惠券管理-优惠券模版管理-作废 */
  COUPONMANAGEMENT_TEMPLATE_CANCELLATION = 'couponManagement-template-cancellation',
  /** 优惠券管理-优惠券模版管理-领取记录 */
  COUPONMANAGEMENT_TEMPLATE_RECORD = 'couponManagement-template-record',
  /** 优惠券管理-优惠券模版管理-同步到微信 */
  COUPONMANAGEMENT_TEMPLATE_WECHAT = 'couponManagement-template-wechat',

  /** 优惠券管理-批量发券 */
  COUPONMANAGEMENT_COUPONGRANT = 'couponManagement-couponGrant',
  /** 优惠券管理-批量发券-上传 */
  COUPONMANAGEMENT_TEMPLATE_BTN = 'couponManagement-template-btn',

  /** 财务管理 */
  FINANCE = 'finance',
  /** 财务管理-平台账户 */
  FINANCE_PLATFORMACCOUNT = 'finance-platformAccount',
  /** 财务管理-平台账户-预览 */
  FINANCE_PLATFORMACCOUNT_QUERY = 'finance-platformAccount-query',
  /** 财务管理-平台账户-导出 */
  FINANCE_PLATFORMACCOUNT_EXPORT = 'finance-platformAccount-export',
  /** 财务管理-平台账户-订单详情 */
  FINANCE_PLATFORMACCOUNT_ORDERDETAIL = 'finance-platformAccount-orderDetail',
  /** 财务管理-平台账户-充值 */
  FINANCE_PLATFORMACCOUNT_TOPUP = 'finance-platformAccount-topUp',
  /** 财务管理-平台账户-提现 */
  FINANCE_PLATFORMACCOUNT_DEPOSIT = 'finance-platformAccount-deposit',

  /** 财务管理-平台营销账户 */
  FINANCE_PLATFORMMARKETINGACCOUNT = 'finance-platformMarketingAccount',
  /** 财务管理-平台营销账户-预览 */
  FINANCE_PLATFORMMARKETINGACCOUNT_QUERY = 'finance-platformMarketingAccount-query',
  /** 财务管理-平台营销账户-导出 */
  FINANCE_PLATFORMMARKETINGACCOUNT_EXPORT = 'finance-platformMarketingAccount-export',
  /** 财务管理-平台营销账户-订单详情 */
  FINANCE_PLATFORMMARKETINGACCOUNT_ORDERDETAIL = 'finance-platformMarketingAccount-orderDetail',
  /** 财务管理-平台营销账户-充值 */
  FINANCE_PLATFORMMARKETINGACCOUNT_TOPUP = 'finance-platformMarketingAccount-topUp',
  /** 财务管理-平台营销账户-提现 */
  FINANCE_PLATFORMMARKETINGACCOUNT_DEPOSIT = 'finance-platformMarketingAccount-deposit',

  /** 财务管理-平台保证金账户 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT = 'finance-platformEarnestMoneyAccount',
  /** 财务管理-平台保证金账户-预览 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT_QUERY = 'finance-platformEarnestMoneyAccount-query',
  /** 财务管理-平台保证金账户-导出 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT_EXPORT = 'finance-platformEarnestMoneyAccount-export',
  /** 财务管理-平台保证金账户-订单详情 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT_ORDERDETAIL = 'finance-platformEarnestMoneyAccount-orderDetail',
  /** 财务管理-平台保证金账户-充值 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT_TOPUP = 'finance-platformEarnestMoneyAccount-topUp',
  /** 财务管理-平台保证金账户-提现 */
  FINANCE_PLATFORMEARNESTMONEYACCOUNT_DEPOSIT = 'finance-platformEarnestMoneyAccount-deposit',

  /** 财务管理-所有店铺账户 */
  FINANCE_SHOPACCOUNT = 'finance-shopAccount',
  /** 财务管理-所有店铺账户-预览 */
  FINANCE_SHOPACCOUNT_QUERY = 'finance-shopAccount-query',
  /** 财务管理-所有店铺账户-导出 */
  FINANCE_SHOPACCOUNT_EXPORT = 'finance-shopAccount-export',
  /** 财务管理-所有店铺账户-订单详情 */
  FINANCE_SHOPACCOUNT_ORDERDETAIL = 'finance-shopAccount-orderDetail',
  /** 财务管理-所有店铺账户-售后详情 */
  FINANCE_SHOPACCOUNT_AFTERSALEDETAIL = 'finance-shopAccount-afterSaleDetail',

  /** 财务管理-所有店铺营销账户 */
  FINANCE_SHOPMARKETINGACCOUNT = 'finance-shopMarketingAccount',
  /** 财务管理-所有店铺营销账户-预览 */
  FINANCE_SHOPMARKETINGACCOUNT_QUERY = 'finance-shopMarketingAccount-query',
  /** 财务管理-所有店铺营销账户-导出 */
  FINANCE_SHOPMARKETINGACCOUNT_EXPORT = 'finance-shopMarketingAccount-export',
  /** 财务管理-所有店铺营销账户-订单详情 */
  FINANCE_SHOPMARKETINGACCOUNT_ORDERDETAIL = 'finance-shopMarketingAccount-orderDetail',

  /** 财务管理-所有店铺保证金账户 */
  FINANCE_SHOPEARNESTMONEYACCOUNT = 'finance-shopEarnestMoneyAccount',
  /** 财务管理-所有店铺保证金账户-预览 */
  FINANCE_SHOPEARNESTMONEYACCOUNT_QUERY = 'finance-shopEarnestMoneyAccount-query',
  /** 财务管理-所有店铺保证金账户-导出 */
  FINANCE_SHOPEARNESTMONEYACCOUNT_EXPORT = 'finance-shopEarnestMoneyAccount-export',
  /** 财务管理-所有店铺保证金账户-订单详情 */
  FINANCE_SHOPEARNESTMONEYACCOUNT_ORDERDETAIL = 'finance-shopEarnestMoneyAccount-orderDetail',

  /** 财务管理-所有应收订单 */
  FINANCE_RECEIVABLEORDER = 'finance-receivableOrder',
  /** 财务管理-所有应收订单-预览 */
  FINANCE_RECEIVABLEORDER_QUERY = 'finance-receivableOrder-query',
  /** 财务管理-所有应收订单-导出 */
  FINANCE_RECEIVABLEORDER_EXPORT = 'finance-receivableOrder-export',
  /** 财务管理-所有应收订单-用户详情 */
  FINANCE_RECEIVABLEORDER_MEMBERDETAIL = 'finance-receivableOrder-memberDetail',
  /** 财务管理-所有应收订单-订单详情 */
  FINANCE_RECEIVABLEORDER_ORDERDETAIL = 'finance-receivableOrder-orderDetail',
  /** 财务管理-所有应收订单-处理异常 */
  FINANCE_RECEIVABLEORDER_RETRY = 'finance-receivableOrder-retry',

  /** 财务管理-所有实收订单 */
  FINANCE_RECEIVEDORDER = 'finance-receivedOrder',
  /** 财务管理-所有实收订单-预览 */
  FINANCE_RECEIVEDORDER_QUERY = 'finance-receivedOrder-query',
  /** 财务管理-所有实收订单-导出 */
  FINANCE_RECEIVEDORDER_EXPORT = 'finance-receivedOrder-export',
  /** 财务管理-所有实收订单-用户详情 */
  FINANCE_RECEIVEDORDER_MEMBERDETAIL = 'finance-receivedOrder-memberDetail',
  /** 财务管理-所有实收订单-订单详情 */
  FINANCE_RECEIVEDORDER_ORDERDETAIL = 'finance-receivedOrder-orderDetail',

  /** 财务管理-所有退款订单 */
  FINANCE_REFUNDORDER = 'finance-refundOrder',
  /** 财务管理-所有退款订单-预览 */
  FINANCE_REFUNDORDER_QUERY = 'finance-refundOrder-query',
  /** 财务管理-所有退款订单-导出 */
  FINANCE_REFUNDORDER_EXPORT = 'finance-refundOrder-export',
  /** 财务管理-所有退款订单-用户详情 */
  FINANCE_REFUNDORDER_MEMBERDETAIL = 'finance-refundOrder-memberDetail',
  /** 财务管理-所有退款订单-订单详情 */
  FINANCE_REFUNDORDER_ORDERDETAIL = 'finance-refundOrder-orderDetail',
  /** 财务管理-所有退款订单-售后详情 */
  FINANCE_REFUNDORDER_AFTERSALEDETAIL = 'finance-refundOrder-afterSaleDetail',
  /** 财务管理-所有退款订单-仲裁详情 */
  FINANCE_REFUNDORDER_ARBITRATIONDETAIL = 'finance-refundOrder-arbitrationDetail',
  /** 财务管理-所有退款订单-手动罚保证金 */
  FINANCE_REFUNDORDER_MARGIN = 'finance-refundOrder-margin',
  /** 财务管理-所有退款订单-手动退款 */
  FINANCE_REFUNDORDER_RETURN = 'finance-refundOrder-return',

  /** 财务管理-所有平台补贴 */
  FINANCE_PLATFORMSUBSIDY = 'finance-platformSubsidy',
  /** 财务管理-所有平台补贴-预览 */
  FINANCE_PLATFORMSUBSIDY_QUERY = 'finance-platformSubsidy-query',
  /** 财务管理-所有平台补贴-导出 */
  FINANCE_PLATFORMSUBSIDY_EXPORT = 'finance-platformSubsidy-export',
  /** 财务管理-所有平台补贴-用户详情 */
  FINANCE_PLATFORMSUBSIDY_MEMBERDETAIL = 'finance-platformSubsidy-memberDetail',
  /** 财务管理-所有平台补贴-商品详情 */
  FINANCE_PLATFORMSUBSIDY_GOODSDETAIL = 'finance-platformSubsidy-goodsDetail',
  /** 财务管理-所有平台补贴-订单详情 */
  FINANCE_PLATFORMSUBSIDY_ORDERDETAIL = 'finance-platformSubsidy-orderDetail',

  /** 财务管理-所有平台补贴/抽拥 */
  FINANCE_PLATFORMCOMMISSION = 'finance-platformCommission',
  /** 财务管理-所有平台补贴/抽拥-预览 */
  FINANCE_PLATFORMCOMMISSION_QUERY = 'finance-platformCommission-query',
  /** 财务管理-所有平台补贴/抽拥-导出 */
  FINANCE_PLATFORMCOMMISSION_EXPORT = 'finance-platformCommission-export',
  /** 财务管理-所有平台补贴/抽拥-用户详情 */
  FINANCE_PLATFORMCOMMISSION_MEMBERDETAIL = 'finance-platformCommission-memberDetail',
  /** 财务管理-所有平台补贴/抽拥-商品详情 */
  FINANCE_PLATFORMCOMMISSION_GOODSDETAIL = 'finance-platformCommission-goodsDetail',
  /** 财务管理-所有平台补贴/抽拥-订单详情 */
  FINANCE_PLATFORMCOMMISSION_ORDERDETAIL = 'finance-platformCommission-orderDetail',

  /** 财务管理-对账单 */
  FINANCE_STATEMENTOFACCOUNT = 'finance-statementOfAccount',
  /** 财务管理-对账单-预览 */
  FINANCE_STATEMENTOFACCOUNT_QUERY = 'finance-statementOfAccount-query',

  /** 财务管理-退款审核 */
  FINANCE_REFUNDREVIEW = 'finance-refundReview',
  /** 财务管理-退款审核-预览 */
  FINANCE_REFUNDREVIEW_QUERY = 'finance-refundReview-query',
  /** 财务管理-退款审核-直接通过 */
  CLASSIFY_REFUNDREVIEW_SUCCESS = 'classify-refundReview-success',
  /** 财务管理-退款审核-直接打回 */
  CLASSIFY_REFUNDREVIEW_REPULSE = 'classify-refundReview-repulse',
  /** 财务管理-退款审核-审核 */
  CLASSIFY_REFUNDREVIEW_AUDIT = 'classify-refundReview-audit',

  /** 财务管理-金龙卡明细 */
  FINANCE_CARDDETAILS = 'finance-cardDetails',
  /** 财务管理-金龙卡明细-预览 */
  FINANCE_CARDDETAILS_QUERY = 'finance-cardDetails-query',
  /** 财务管理-金龙卡明细-导出 */
  CLASSIFY_CARDDETAILS_EXPORT = 'classify-cardDetails-export',

  /** 权限管理 */
  PERMISSIONS = 'permissions',
  /** 权限管理-部门管理 */
  PERMISSIONS_DEPT = 'permissions-dept',
  /** 权限管理-部门管理-预览 */
  PERMISSIONS_DEPT_QUERY = 'permissions-dept-query',
  /** 权限管理-部门管理-新增 */
  PERMISSIONS_DEPT_ADD = 'permissions-dept-add',
  /** 权限管理-部门管理-编辑 */
  PERMISSIONS_DEPT_UPDATE = 'permissions-dept-update',
  /** 权限管理-部门管理-删除 */
  PERMISSIONS_DEPT_DELETE = 'permissions-dept-delete',

  /** 权限管理-员工管理 */
  PERMISSIONS_STAFF = 'permissions-staff',
  /** 权限管理-员工管理-预览 */
  PERMISSIONS_STAFF_QUERY = 'permissions-staff-query',
  /** 权限管理-员工管理-新增 */
  PERMISSIONS_STAFF_ADD = 'permissions-staff-add',
  /** 权限管理-员工管理-编辑 */
  PERMISSIONS_STAFF_UPDATE = 'permissions-staff-update',
  /** 权限管理-员工管理-删除 */
  PERMISSIONS_STAFF_DELETE = 'permissions-staff-delete',
  /** 权限管理-员工管理-重置密码 */
  PERMISSIONS_STAFF_RESETPWD = 'permissions-staff-resetPWD',
  /** 权限管理-员工管理-开通客服 */
  PERMISSIONS_STAFF_SERVICER = 'permissions-staff-servicer',

  /** 权限管理-角色管理 */
  PERMISSIONS_ROLE = 'permissions-role',
  /** 权限管理-角色管理-预览 */
  PERMISSIONS_ROLE_QUERY = 'permissions-role-query',
  /** 权限管理-角色管理-新增 */
  PERMISSIONS_ROLE_ADD = 'permissions-role-add',
  /** 权限管理-角色管理-编辑 */
  PERMISSIONS_ROLE_UPDATE = 'permissions-role-update',
  /** 权限管理-角色管理-删除 */
  PERMISSIONS_ROLE_DELETE = 'permissions-role-delete',

  /** 权限管理-企业资源分组 */
  PERMISSIONS_COMPANYRESOURCESGROUP = 'permissions-companyResourcesGroup',
  /** 权限管理-企业资源分组-预览 */
  PERMISSIONS_COMPANYRESOURCESGROUP_QUERY = 'permissions-companyResourcesGroup-query',
  /** 权限管理-企业资源分组-新增 */
  PERMISSIONS_COMPANYRESOURCESGROUP_ADD = 'permissions-companyResourcesGroup-add',
  /** 权限管理-企业资源分组-编辑 */
  PERMISSIONS_COMPANYRESOURCESGROUP_UPDATE = 'permissions-companyResourcesGroup-update',
  /** 权限管理-企业资源分组-删除 */
  PERMISSIONS_COMPANYRESOURCESGROUP_DELETE = 'permissions-companyResourcesGroup-delete',

  /** 权限管理-操作日志记录 */
  PERMISSIONS_LOGRECORD = 'permissions-logRecord',
  /** 权限管理-操作日志记录-预览 */
  PERMISSIONS_LOGRECORD_QUERY = 'permissions-logRecord-query',

  /** 会员管理 */
  MEMBER = 'member',
  /** 会员管理-客户信息 */
  MEMBER_INFO = 'member-info',
  /** 会员管理-客户信息-预览 */
  MEMBER_INFO_QUERY = 'member-info-query',
  /** 会员管理-客户信息-导出 */
  MEMBER_INFO_EXPORT = 'member-info-export',
  /** 会员管理-客户信息-行为限制 */
  MEMBER_INFO_BEHAVIORLIMIT = 'member-info-behaviorLimit',
  /** 会员管理-客户信息-修改积分 */
  MEMBER_INFO_MODIFYINTEGRATE = 'member-info-modifyIntegrate',
  /** 会员管理-客户信息-详情 */
  MEMBER_INFO_DETAIL = 'member-info-detail',
  /** 会员管理-客户信息-编辑 */
  MEMBER_INFO_UPDATE = 'member-info-update',

  /** 会员管理-会员卡号信息 */
  MEMBER_CARDINFO = 'member-cardInfo',
  /** 会员管理-会员卡号信息-预览 */
  MEMBER_CARDINFO_QUERY = 'member-cardInfo-query',
  /** 会员管理-会员卡号信息-导出 */
  MEMBER_CARDINFO_EXPORT = 'member-cardInfo-export',
  /** 会员管理-会员卡号信息-状态切换 */
  MEMBER_CARDINFO_SHOWSTATUS = 'member-cardInfo-showStatus',
  /** 会员管理-会员卡号信息-修改成长值 */
  MEMBER_CARDINFO_MODIFYGROWTHVALUE = 'member-cardInfo-modifyGrowthValue',
  /** 会员管理-会员卡号信息-详情 */
  MEMBER_CARDINFO_DETAIL = 'member-cardInfo-detail',

  /** 会员管理-会员卡等级设置 */
  MEMBER_CARDLEVELSET = 'member-cardLevelSet',
  /** 会员管理-会员卡等级设置-编辑 */
  MEMBER_CARDLEVELSET_UPDATE = 'member-cardLevelSet-update',
  /** 会员管理-会员卡等级设置-通用成长值设置 */
  MEMBER_CARDLEVELSET_GENERAL = 'member-cardLevelSet-general',
  /** 会员管理-会员卡等级设置-会员说明设置 */
  MEMBER_CARDLEVELSET_MEMBERTXST = 'member-cardLevelSet-memberTxst',
  /** 会员管理-会员卡等级设置-权益设置 */
  MEMBER_CARDLEVELSET_EQUITY = 'member-cardLevelSet-equity',

  /** 会员管理-权益列表 */
  MEMBER_RIGHTSLIST = 'member-rightsList',
  /** 会员管理-权益列表-预览 */
  MEMBER_RIGHTSLIST_QUERY = 'member-rightsList-query',
  /** 会员管理-权益列表-编辑权益 */
  MEMBER_RIGHTSLIST_UPDATE = 'member-rightsList-update',
  /** 会员管理-权益列表-新增权益 */
  MEMBER_RIGHTSLIST_ADD = 'member-rightsList-add',
  /** 会员管理-权益列表-删除权益 */
  MEMBER_RIGHTSLIST_DELETE = 'member-rightsList-delete',

  /** 会员管理-标签库 */
  MEMBER_TAGS = 'member-tags',
  /** 会员管理-标签库-预览 */
  MEMBER_TAGS_QUERY = 'member-tags-query',
  /** 会员管理-标签库-新增 */
  MEMBER_TAGS_ADD = 'member-tags-add',
  /** 会员管理-标签库-查看用户 */
  MEMBER_TAGS_WATCHUSER = 'member-tags-watchUser',
  /** 会员管理-标签库-选择用户 */
  MEMBER_TAGS_CHOOSUSER = 'member-tags-choosUser',

  /** 订单管理 */
  ORDER = 'order',
  /** 订单管理-订单列表 */
  ORDER_LIST = 'order-list',
  /** 订单管理-订单列表-预览 */
  ORDER_LIST_QUERY = 'order-list-query',
  /** 订单管理-订单列表-详情 */
  ORDER_LIST_DETAIL = 'order-list-detail',
  /** 订单管理-订单列表-导出 */
  ORDER_LIST_EXPORT = 'order-list-export',

  /** 订单管理-虚拟商品订单 */
  ORDER_VIRTUALLIST = 'order-virtualList',
  /** 订单管理-虚拟商品订单-预览 */
  ORDER_VIRTUALLIST_QUERY = 'order-virtualList-query',
  /** 订单管理-虚拟商品订单-详情 */
  ORDER_VIRTUALLIST_DETAIL = 'order-virtualList-detail',
  /** 订单管理-虚拟商品订单-导出 */
  ORDER_VIRTUALLIST_EXPORT = 'order-virtualList-export',

  /** 订单管理-发货管理 */
  ORDER_DELIVERYMANAGEMENT = 'order-deliveryManagement',
  /** 订单管理-发货管理-预览 */
  ORDER_DELIVERYMANAGEMENT_QUERY = 'order-deliveryManagement-query',
  /** 订单管理-发货管理-订单详情 */
  ORDER_DELIVERYMANAGEMENT_ORDERDETAIL = 'order-deliveryManagement-orderDetail',

  /** 订单管理-售后管理 */
  ORDER_AFTERSALELIST = 'order-afterSaleList',
  /** 订单管理-售后管理-预览 */
  ORDER_AFTERSALELIST_QUERY = 'order-afterSaleList-query',
  /** 订单管理-售后管理-售后详情 */
  ORDER_AFTERSALELIST_DETAIL = 'order-afterSaleList-detail',
  /** 订单管理-售后管理-订单详情 */
  ORDER_AFTERSALELIST_ORDERDETAIL = 'order-afterSaleList-orderDetail',
  /** 订单管理-售后管理-导出 */
  ORDER_AFTERSALELIST_EXPORT = 'order-afterSaleList-export',

  /** 订单管理-仲裁列表 */
  ORDER_ARBITRATIONLIST = 'order-arbitrationList',
  /** 订单管理-仲裁列表-预览 */
  ORDER_ARBITRATIONLIST_QUERY = 'order-arbitrationList-query',
  /** 订单管理-仲裁列表-导出 */
  ORDER_ARBITRATIONLIST_EXPORT = 'order-arbitrationList-export',
  /** 订单管理-仲裁列表-仲裁详情 */
  ORDER_ARBITRATIONLIST_DETAIL = 'order-arbitrationList-detail',
  /** 订单管理-仲裁列表-售后详情 */
  ORDER_ARBITRATIONLIST_AFTERSALEDETAIL = 'order-arbitrationList-afterSaledetail',
  /** 订单管理-仲裁列表-订单详情 */
  ORDER_ARBITRATIONLIST_ORDERDETAIL = 'order-arbitrationList-orderDetail',
  /** 订单管理-仲裁列表-店铺责任 */
  ORDER_ARBITRATIONLIST_SHOPFAULT = 'order-arbitrationList-shopFault',
  /** 订单管理-仲裁列表-买家责任 */
  ORDER_ARBITRATIONLIST_USERFAULT = 'order-arbitrationList-userFault',

  /** 营销管理 */
  MARKETING = 'marketing',
  /** 营销管理-签到有礼 */
  MARKETING_SIGNINGIFT = 'marketing-signInGift',
  /** 营销管理-签到有礼-预览 */
  MARKETING_SIGNINGIFT_QUERY = 'marketing-signInGift-query',
  /** 营销管理-签到有礼-显示状态 */
  MARKETING_SIGNINGIFT_USESWITCH = 'marketing-signInGift-useSwitch',
  /** 营销管理-签到有礼-编辑 */
  MARKETING_SIGNINGIFT_UPDATE = 'marketing-signInGift-update',

  /** 营销管理-满减满折列表 */
  MARKETING_DISCOUNTSALE = 'marketing-discountSale',
  /** 营销管理-满减满折列表-预览 */
  MARKETING_DISCOUNTSALE_QUERY = 'marketing-discountSale-query',
  /** 营销管理-满减满折列表-切换状态 */
  MARKETING_DISCOUNTSALE_USESWITCH = 'marketing-discountSale-useSwitch',
  /** 营销管理-满减满折列表-导出 */
  MARKETING_DISCOUNTSALE_EXPORT = 'marketing-discountSale-export',
  /** 营销管理-满减满折列表-新增 */
  MARKETING_DISCOUNTSALE_ADD = 'marketing-discountSale-add',
  /** 营销管理-满减满折列表-编辑 */
  MARKETING_DISCOUNTSALE_UPDATE = 'marketing-discountSale-update',
  /** 营销管理-满减满折列表-删除 */
  MARKETING_DISCOUNTSALE_DELETE = 'marketing-discountSale-delete',
  /** 营销管理-满减满折列表-查看 */
  MARKETING_DISCOUNTSALE_DETAIL = 'marketing-discountSale-detail',
  /** 营销管理-满减满折列表-查看店铺 */
  MARKETING_DISCOUNTSALE_STORELIST = 'marketing-discountSale-storeList',
  /** 营销管理-满减满折列表-切换店铺状态 */
  MARKETING_DISCOUNTSALE_STORELIST_USESWITCH = 'marketing-discountSale-storeList-useSwitch',
  /** 营销管理-满减满折列表-查看店铺商品 */
  MARKETING_DISCOUNTSALE_STORELIST_GOODSLIST = 'marketing-discountSale-storeList-goodsList',
  /** 营销管理-满减满折列表-删除店铺商品 */
  MARKETING_DISCOUNTSALE_STORELIST_GOODSLIST_DELETE = 'marketing-discountSale-storeList-goodsList-delete',
  /** 营销管理-满减满折列表-查看店铺商品规格 */
  MARKETING_DISCOUNTSALE_STORELIST_GOODSLIST_DETAIL = 'marketing-discountSale-storeList-goodsList-detail',

  /** 营销管理-限时抢购列表 */
  MARKETING_TIMEBUY = 'marketing-timeBuy',
  /** 营销管理-限时抢购列表-预览 */
  MARKETING_TIMEBUY_QUERY = 'marketing-timeBuy-query',
  /** 营销管理-限时抢购列表-切换状态 */
  MARKETING_TIMEBUY_USESWITCH = 'marketing-timeBuy-useSwitch',
  /** 营销管理-限时抢购列表-导出 */
  MARKETING_TIMEBUY_EXPORT = 'marketing-timeBuy-export',
  /** 营销管理-限时抢购列表-新增 */
  MARKETING_TIMEBUY_ADD = 'marketing-timeBuy-add',
  /** 营销管理-限时抢购列表-编辑 */
  MARKETING_TIMEBUY_UPDATE = 'marketing-timeBuy-update',
  /** 营销管理-限时抢购列表-删除 */
  MARKETING_TIMEBUY_DELETE = 'marketing-timeBuy-delete',
  /** 营销管理-限时抢购列表-查看 */
  MARKETING_TIMEBUY_DETAIL = 'marketing-timeBuy-detail',
  /** 营销管理-限时抢购列表-查看店铺 */
  MARKETING_TIMEBUY_STORELIST = 'marketing-timeBuy-storeList',
  /** 营销管理-限时抢购列表-切换店铺状态 */
  MARKETING_TIMEBUY_STORELIST_USESWITCH = 'marketing-timeBuy-storeList-useSwitch',
  /** 营销管理-限时抢购列表-查看店铺商品 */
  MARKETING_TIMEBUY_STORELIST_GOODSLIST = 'marketing-timeBuy-storeList-goodsList',
  /** 营销管理-限时抢购列表-删除店铺商品 */
  MARKETING_TIMEBUY_STORELIST_GOODSLIST_DELETE = 'marketing-timeBuy-storeList-goodsList-delete',
  /** 营销管理-限时抢购列表-查看店铺商品规格 */
  MARKETING_TIMEBUY_STORELIST_GOODSLIST_DETAIL = 'marketing-timeBuy-storeList-goodsList-detail',

  /** 营销管理-满减满折审核 */
  MARKETING_DISCOUNTSALECHECK = 'marketing-discountSaleCheck',
  /** 营销管理-满减满折审核-预览 */
  MARKETING_DISCOUNTSALECHECK_QUERY = 'marketing-discountSaleCheck-query',
  /** 营销管理-满减满折审核-审核 */
  MARKETING_DISCOUNTSALECHECK_AUDIT = 'marketing-discountSaleCheck-audit',
  /** 营销管理-满减满折审核-查看 */
  MARKETING_DISCOUNTSALECHECK_DETAIL = 'marketing-discountSaleCheck-detail',

  /** 营销管理-限时抢购审核 */
  MARKETING_TIMEBUYCHECK = 'marketing-timeBuyCheck',
  /** 营销管理-限时抢购审核-预览 */
  MARKETING_TIMEBUYCHECK_QUERY = 'marketing-timeBuyCheck-query',
  /** 营销管理-限时抢购审核-审核 */
  MARKETING_TIMEBUYCHECK_AUDIT = 'marketing-timeBuyCheck-audit',
  /** 营销管理-限时抢购审核-查看 */
  MARKETING_TIMEBUYCHECK_DETAIL = 'marketing-timeBuyCheck-detail',

  /** 装修设置 */
  DECORATION = 'decoration',
  /** 装修设置-装修页面管理 */
  DECORATION_LIST = 'decoration-list',
  /** 装修设置-装修页面管理-预览 */
  DECORATION_LIST_QUERY = 'decoration-list-query',
  /** 装修设置-装修页面管理-新增 */
  DECORATION_LIST_ADD = 'decoration-list-add',
  /** 装修设置-装修页面管理-编辑 */
  DECORATION_LIST_UPDATE = 'decoration-list-update',
  /** 装修设置-装修页面管理-删除 */
  DECORATION_LIST_DELETE = 'decoration-list-delete',
  /** 装修设置-装修页面管理-复制 */
  DECORATION_LIST_COPY = 'decoration-list-copy',
  /** 装修设置-装修页面管理-设为首页 */
  DECORATION_LIST_SETHOME = 'decoration-list-setHome',

  /** 装修设置-活动导航 */
  DECORATION_ACTIVITYNAVIGATION = 'decoration-activityNavigation',
  /** 装修设置-活动导航-预览 */
  DECORATION_ACTIVITYNAVIGATION_QUERY = 'decoration-activityNavigation-query',
  /** 装修设置-活动导航-编辑 */
  DECORATION_ACTIVITYNAVIGATION_UPDATE = 'decoration-activityNavigation-update',

  /** 装修设置-素材库 */
  DECORATION_MATERIALLIBRARY = 'decoration-materialLibrary',
  /** 装修设置-素材库-预览 */
  DECORATION_MATERIALLIBRARY_QUERY = 'decoration-materialLibrary-query',
  /** 装修设置-素材库-编辑 */
  DECORATION_MATERIALLIBRARY_UPDATE = 'decoration-materialLibrary-update',
  /** 装修设置-素材库-下载 */
  DECORATION_MATERIALLIBRARY_DOWNLOAD = 'decoration-materialLibrary-download',
  /** 装修设置-素材库-复制链接 */
  DECORATION_MATERIALLIBRARY_LINKCOPY = 'decoration-materialLibrary-linkCopy',
  /** 装修设置-素材库-删除 */
  DECORATION_MATERIALLIBRARY_DELETE = 'decoration-materialLibrary-delete',
  /** 装修设置-素材库-上传 */
  DECORATION_MATERIALLIBRARY_UPLOAD = 'decoration-materialLibrary-upload',
  /** 装修设置-素材库-移动 */
  DECORATION_MATERIALLIBRARY_MOVE = 'decoration-materialLibrary-move',
  /** 装修设置-素材库-添加子分组 */
  DECORATION_MATERIALLIBRARY_GROUPADD = 'decoration-materialLibrary-groupAdd',
  /** 装修设置-素材库-重命名分组 */
  DECORATION_MATERIALLIBRARY_GROUPRENAME = 'decoration-materialLibrary-groupRename',
  /** 装修设置-素材库-删除分组 */
  DECORATION_MATERIALLIBRARY_GROUPDELETE = 'decoration-materialLibrary-groupDelete',

  /** 客服管理 */
  SERVICER = 'servicer',
  /** 客服管理-小能客服 */
  SERVICER_CHAT = 'servicer-chat',
  /** 客服管理-小能客服-聊天 */
  SERVICER_CHAT_QUERY = 'servicer-chat-query',

  /** 风险预警 */
  RISKEARLYWARNING_LIST = 'riskEarlyWarning-list',
  /** 风险预警-预览 */
  RISKEARLYWARNING_LIST_QUERY = 'riskEarlyWarning-list-query',

  /** 风险预警-导出 */
  RISKEARLYWARNING_LIST_EXPORT = 'riskEarlyWarning-list-export',

  /** 风险预警-开关切换 */
  RISKEARLYWARNING_LIST_SWITCHEXEMPTION = 'riskEarlyWarning-list-switchExemption',

  /** 直播管理 */
  MANAGEMENT = 'management',
  /** 直播管理-直播间组管理 */
  MANAGEMENTGROUP_LIST = 'managementGroup-list',
  /** 直播管理-直播间组管理-预览 */
  MANAGEMENTGROUP_LIST_QUERY = 'managementGroup-list-query',
  /** 直播管理-直播间组管理-新增 */
  MANAGEMENTGROUP_LIST_ADD = 'managementGroup-list-add',
  /** 直播管理-直播间组管理-编辑 */
  MANAGEMENTGROUP_LIST_UPDATE = 'managementGroup-list-update',

  /** 直播管理-直播间管理 */
  BROADCASTINGROOM_LIST = 'broadcastingRoom-list',
  /** 直播管理-直播间管理-预览 */
  BROADCASTINGROOM_LIST_QUERY = 'broadcastingRoom-list-query',
  /** 直播管理-直播间管理-同步直播间 */
  BROADCASTINGROOM_LIST_SYNCHRONIZATION = 'broadcastingRoom-list-synchronization',
  /** 直播管理-直播间管理-开播码 */
  BROADCASTINGROOM_LIST_LOOK = 'broadcastingRoom-list-look',

  /** 直播管理-主播管理 */
  MANAGEMENTANCHOR_LIST = 'managementAnchor-list',
  /** 直播管理-主播管理-预览 */
  MANAGEMENTANCHOR_LIST_QUERY = 'managementAnchor-list-query',
  /** 直播管理-主播管理-编辑 */
  MANAGEMENTANCHOR_LIST_UPDATE = 'managementAnchor-list-update',

  /** 微信视频号 */
  MINISTORE = 'ministore',
  /** 微信视频号-类目管理 */
  MINISTORE_CATEGORY = 'ministore-category',
  /** 微信视频号-类目管理-预览 */
  MINISTORE_CATEGORY_QUERY = 'ministore-category-query',
  /** 微信视频号-类目管理-类目信息 */
  MINISTORE_CATEGORY_DETAIL = 'ministore-category-detail',
  /** 微信视频号-类目管理-新增 */
  MINISTORE_CATEGORY_ADD = 'ministore-category-add',
  /** 微信视频号-类目管理-重新审核 */
  MINISTORE_CATEGORY_UPDATE = 'ministore-category-update',

  /** 微信视频号-品牌管理 */
  MINISTORE_BRAND = 'ministore-brand',
  /** 微信视频号-品牌管理-预览 */
  MINISTORE_BRAND_QUERY = 'ministore-brand-query',
  /** 微信视频号-品牌管理-新增 */
  MINISTORE_BRAND_ADD = 'ministore-brand-add',
  /** 微信视频号-品牌管理-编辑 */
  MINISTORE_BRAND_UPDATE = 'ministore-brand-update',

  /** 微信视频号-商品管理 */
  MINISTORE_GOODSLIST = 'ministore-goodsList',
  /** 微信视频号-商品管理-预览 */
  MINISTORE_GOODSLIST_QUERY = 'ministore-goodsList-query',
  /** 微信视频号-商品管理-新增 */
  MINISTORE_GOODSLIST_ADD = 'ministore-goodsList-add',
  /** 微信视频号-商品管理-重新审核/修改资料 */
  MINISTORE_GOODSLIST_UPDATE = 'ministore-goodsList-update',
  /** 微信视频号-商品管理-撤销审核 */
  MINISTORE_GOODSLIST_CANCEL = 'ministore-goodsList-cancel',
  /** 微信视频号-商品管理-删除 */
  MINISTORE_GOODSLIST_DELETE = 'ministore-goodsList-delete',
  /** 微信视频号-商品管理-上架 */
  MINISTORE_GOODSLIST_UP = 'ministore-goodsList-up',
  /** 微信视频号-商品管理-下架 */
  MINISTORE_GOODSLIST_DOWN = 'ministore-goodsList-down',

  /** 数据仪表 */
  DATAANALYSIS = 'dataAnalysis',
  /** 数据仪表-数据概况 */
  DATAANALYSIS_DATAOVERVIEW = 'dataAnalysis-dataOverview',
  /** 数据仪表-数据概况-预览 */
  DATAANALYSIS_DATAOVERVIEW_QUERY = 'dataAnalysis-dataOverview-query',

  /** 数据仪表-行为分析 */
  DATAANALYSIS_BEHAVIORALS = 'dataAnalysis-behaviorals',
  /** 数据仪表-行为分析-预览 */
  DATAANALYSIS_BEHAVIORALS_QUERY = 'dataAnalysis-behaviorals-query',

  /** 数据仪表-基础数据统计 */
  DATAANALYSIS_DATABASICS = 'dataAnalysis-dataBasics',
  /** 数据仪表-基础数据统计-预览 */
  DATAANALYSIS_DATABASICS_QUERY = 'dataAnalysis-dataBasics-query',

}